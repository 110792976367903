<template>
    <page-container>
        <template slot="navigation" />

        <template slot="header">
            <v-toolbar dense color="secondary">
                <v-btn icon @click.stop="closeCallback">
                    <v-icon>close</v-icon>
                </v-btn>
                <v-toolbar-title v-if="!!storageData.isInitialized">
                    <span>{{ shooting.name + ' - 自動選定結果' }}</span>
                </v-toolbar-title>
                <v-spacer />
                <v-btn-toggle
                    dense
                    group
                >
                    <icon-with-text 
                        v-if="!shouldSelectAll"
                        text="全選択解除"
                        :show-text="showText"
                        icon-name="mdi-select-all" 
                        icon
                        :value="false"
                        @click="selectAllPhotos(false)"
                    />
                    <icon-with-text
                        v-else
                        text="全選択"
                        :show-text="showText"
                        icon-name="mdi-select" 
                        icon
                        :value="true"
                        @click="selectAllPhotos(true)"
                    />
                </v-btn-toggle>
                <icon-with-text
                    text="選択写真を選定状態に変更"
                    :show-text="showText"
                    icon-name="mdi-arrow-right-bold" 
                    icon
                    :disabled="shouldSelectAll"
                    @click="bulkSelectPhotosForPageSpread"
                />
            </v-toolbar>
        </template>
        <template slot="top" />
        <template slot="middle">
            <v-container v-if="!!storageData.isInitialized" fluid class="pa-0">
                <v-row class="ma-0 pa-0">
                    <v-col cols="12" class="ma-0 pa-0">
                        <v-container fluid class="pt-0">
                            <v-row class="mx-0 my-2 pa-0" dense>
                                <v-col cols="12">
                                    <!-- 選択写真情報表示部 -->
                                    <v-expansion-panels v-model="storageData.expansionPanels">
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>選定前と選定結果の比較</v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-container class="ma-0 pa-0">
                                                    <v-card class="mx-0 my-1">
                                                        <v-card-title class="text-md-subtitle-2">
                                                            選定前 ※ピンボケ写真除外後
                                                        </v-card-title>
                                                        <v-card-text>
                                                            <v-data-table
                                                                :headers="aggregatetionTopHeaders"
                                                                :items="[aggregationResult.all]"
                                                                class="auto-select-aggregation-table"
                                                                hide-default-footer
                                                                disable-sort
                                                                dense
                                                            />
                                                            <v-data-table
                                                                :headers="aggregatetionBottomHeaders"
                                                                :items="[aggregationResult.all]"
                                                                class="auto-select-aggregation-table"
                                                                hide-default-footer
                                                                disable-sort
                                                                dense
                                                            >
                                                                <template v-slot:header>
                                                                    <thead>
                                                                        <tr>
                                                                            <th colspan="5">
                                                                                個人
                                                                            </th>
                                                                            <th colspan="5">
                                                                                シーン
                                                                            </th>
                                                                            <th colspan="5">
                                                                                サブフォルダ
                                                                            </th>
                                                                        </tr>
                                                                        <tr>
                                                                            <th colspan="1">
                                                                                -
                                                                            </th>
                                                                            <th colspan="2">
                                                                                最大
                                                                            </th>
                                                                            <th colspan="2">
                                                                                最小
                                                                            </th>
                                                                            <th colspan="1">
                                                                                -
                                                                            </th>
                                                                            <th colspan="2">
                                                                                最大
                                                                            </th>
                                                                            <th colspan="2">
                                                                                最小
                                                                            </th>
                                                                            <th colspan="1">
                                                                                -
                                                                            </th>
                                                                            <th colspan="2">
                                                                                最大
                                                                            </th>
                                                                            <th colspan="2">
                                                                                最小
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                </template>
                                                            </v-data-table>
                                                        </v-card-text>
                                                    </v-card>
                                                    <v-card class="mx-0 my-1">
                                                        <v-card-title class="text-md-subtitle-2">
                                                            選定結果
                                                        </v-card-title>
                                                        <v-card-text>
                                                            <v-data-table
                                                                :headers="aggregatetionTopHeaders"
                                                                :items="[aggregationResult.selected]"
                                                                class="auto-select-aggregation-table"
                                                                hide-default-footer
                                                                disable-sort
                                                                dense
                                                            />
                                                            <v-data-table
                                                                :headers="aggregatetionBottomHeaders"
                                                                :items="[aggregationResult.selected]"
                                                                class="auto-select-aggregation-table"
                                                                hide-default-footer
                                                                disable-sort
                                                                dense
                                                            >
                                                                <template v-slot:header>
                                                                    <thead>
                                                                        <tr>
                                                                            <th colspan="5">
                                                                                個人
                                                                            </th>
                                                                            <th colspan="5">
                                                                                シーン
                                                                            </th>
                                                                            <th colspan="5">
                                                                                サブフォルダ
                                                                            </th>
                                                                        </tr>
                                                                        <tr>
                                                                            <th colspan="1">
                                                                                -
                                                                            </th>
                                                                            <th colspan="2">
                                                                                最大
                                                                            </th>
                                                                            <th colspan="2">
                                                                                最小
                                                                            </th>
                                                                            <th colspan="1">
                                                                                -
                                                                            </th>
                                                                            <th colspan="2">
                                                                                最大
                                                                            </th>
                                                                            <th colspan="2">
                                                                                最小
                                                                            </th>
                                                                            <th colspan="1">
                                                                                -
                                                                            </th>
                                                                            <th colspan="2">
                                                                                最大
                                                                            </th>
                                                                            <th colspan="2">
                                                                                最小
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                </template>
                                                            </v-data-table>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-container>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-col>
                            </v-row>
                            <!-- 写真サムネイル一覧部 -->
                            <v-row class="ma-0 pa-0" dense>
                                <v-col
                                    v-for="p in photos"
                                    :key="p.id"
                                    :cols="storageData.thumbnailSize.cols"
                                    :sm="storageData.thumbnailSize.sm"
                                    :md="storageData.thumbnailSize.md"
                                    :lg="storageData.thumbnailSize.lg"
                                    :xl="storageData.thumbnailSize.xl"
                                >
                                    <photo-thumbnail-card
                                        :photo="p"
                                        :is-selected="p.is_selected"
                                        :size="storageData.thumbnailSize"
                                        @photo-selected="(value) => {p.is_selected = value}"
                                        @selection-status-changed-for-page-spread="(value) => {updateSelectionStatus(value, p)}"
                                    />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
        </template>
        <template slot="bottom" />        
    </page-container>
</template>

<script>
import event from '../../utils/event';
import http from '../../services/http';
import leaveConform from '../../mixins/leave-confirm-mixin';
import monet from 'monet';

export default {
    components: {
        'photo-thumbnail-card': require('../Photos/PhotoThumbnailCard.vue').default,
        'icon-with-text': require('../Icons/IconWithText.vue').default,
    },
    mixins: [leaveConform],
    props: {
        albumId: {
            default: 0,
            type: [Number, String],
        },
        shootingId: {
            default: 0,
            type: [Number, String],
        },
        autoSelectResult: {
            type: Object,
            default: () => {},
        },
        closeCallback: {
            type: Function,
            default: () => {},
        },
    },
    data () {
        return {
            viewID: '',
            httpState: http.state,
            photos: [],
            showText: true,
            storageData: {
                isInitialized: false,
                album: undefined,
                sourceDirectoryInfo: {
                    shooting: undefined,
                    photos:[],
                    face_ids: [],
                    shooting_scene_names: [],
                    subdirectory_paths: [],
                },
                expansionPanels: 0,
                thumbnailSize: {
                    id: 2,
                    width: 300,
                    height: 200,
                    cols: 3,
                    sm: 12,
                    md: 5,
                    lg: 4,
                    xl: 3
                }, // サムネイルサイズ
            },
            rules: {
            },
            aggregatetionTopHeaders: [
                {
                    text: '写真数（枚）',
                    value: 'photos_count', 
                },
                { 
                    text: '笑顔率平均（%）', 
                    value: 'loughing_rate_avg', 
                },
                {
                    text: 'ピース検出数（個）', 
                    value: 'peace_sign_count', 
                },
                { 
                    text: 'カメラ目線検出数（人）', 
                    value: 'looking_camera_count',
                },
                { 
                    text: '口開き検出数（人）', 
                    value: 'mouth_opening_count', 
                },
                { 
                    text: '男性数（人）', 
                    value: 'male_count', 
                },
                { 
                    text: '女性数（人）', 
                    value: 'female_count', 
                },
            ],
            aggregatetionBottomHeaders: [
                // 個人
                {
                    text: '総数（人）',
                    value: 'faces.count', 
                },
                { 
                    text: '頻度（枚）', 
                    value: 'faces.max.frequency', 
                },
                { 
                    text: '個人数（人）', 
                    value: 'faces.max.count', 
                },
                { 
                    text: '頻度（枚）', 
                    value: 'faces.min.frequency', 
                },
                { 
                    text: '個人数（人）', 
                    value: 'faces.min.count', 
                },
                // シーン
                {
                    text: '総数（シーン）',
                    value: 'shooting_scenes.count', 
                },
                { 
                    text: '頻度（枚）', 
                    value: 'shooting_scenes.max.frequency', 
                },
                { 
                    text: 'シーン数（シーン）', 
                    value: 'shooting_scenes.max.count', 
                },
                { 
                    text: '頻度（枚）', 
                    value: 'shooting_scenes.min.frequency', 
                },
                { 
                    text: 'シーン数（シーン）', 
                    value: 'shooting_scenes.min.count', 
                },
                // サブフォルダ
                {
                    text: '総数（フォルダ）',
                    value: 'subdirectories.count', 
                },
                { 
                    text: '頻度（枚）', 
                    value: 'subdirectories.max.frequency', 
                },
                { 
                    text: 'フォルダ数（フォルダ）', 
                    value: 'subdirectories.max.count', 
                },
                { 
                    text: '頻度（枚）', 
                    value: 'subdirectories.min.frequency', 
                },
                { 
                    text: 'フォルダ数（フォルダ）', 
                    value: 'subdirectories.min.count', 
                },
            ],
        };
    },
    computed: {
        shouldSelectAll () {
            return this.photos.filter(p => !!p.is_selected).length == 0;
        },
        aggregationResult () {
            const directory = this.storageData.sourceDirectoryInfo;
            const selectedPhotos = this.photos.filter(p => p.is_selected);

            const reducer = (selectedKeysArray, allKeysArray) => {
                const reduced = selectedKeysArray
                    .reduce(
                        (acc, key) => { 
                            monet.Maybe.fromNull(acc.find(a => a.key === key)).forEach(a => a.frequency++);
                            return acc;
                        }, 
                        allKeysArray.map(key => { return {key: key, frequency: 0, }; })
                    );

                const max = reduced.length === 0 ? 0 : Math.max.apply(null, reduced.map(x => x.frequency));
                const min = reduced.length === 0 ? 0 : Math.min.apply(null, reduced.map(x => x.frequency));

                return {
                    count: [...new Set(selectedKeysArray)].length,
                    max: {
                        frequency: max,
                        count: reduced.filter(x => x.frequency === max).length,
                    },
                    min: {
                        frequency: min,
                        count: reduced.filter(x => x.frequency === min).length,
                    },
                };
            };

            const aggregater = (photos, allFaceIds, allSceneNames, allSybdirectoryPaths) => {
                return {
                    photos_count: photos.length, // 写真枚数

                    loughing_rate_avg: monet.Maybe.fromFalsy(photos.length)
                        .map(count => Math.round(photos.reduce((acc, p) => acc + p.laughing_rate_avg, 0) / count * 100))
                        .orSome(0), // 笑顔率平均
                    peace_sign_count: photos.reduce((acc, p) => acc + p.peace_count, 0), // ピース数
                    looking_camera_count: photos.reduce((acc, p) => acc + p.looking_camera_count, 0), // カメラ目線数
                    mouth_opening_count: photos.reduce((acc, p) => acc + p.mouth_opening_count, 0), // 口開き数

                    male_count: photos.map(p => p.male_count).reduce((acc, c) => acc + c, 0), // 男性数
                    female_count: photos.map(p => p.female_count).reduce((acc, c) => acc + c, 0), // 女性数

                    faces: reducer(photos.flatMap(p => p.photo_faces).map(pf => pf.face_id), allFaceIds),                
                    shooting_scenes: reducer(photos.map(p => p.shooting_scene_name).filter(x => x != ''), allSceneNames),
                    subdirectories: reducer(photos.map(p => p.subdirectory_path).filter(x => x != ''), allSybdirectoryPaths),
                };
            };

            return {
                all: aggregater(directory.photos, directory.face_ids, directory.shooting_scene_names, directory.subdirectory_paths),
                selected: aggregater(selectedPhotos, directory.face_ids, directory.shooting_scene_names, directory.subdirectory_paths),
            };
        },
    },
    created() {
        event.on('saveStorage', () => {
            if (this.storageData.isInitialized) {
                this.saveStorageData();
            }
        });

        event.on('receiveAutoSelectResponse', () => this.initialize());
    },
    mounted() {
    },
    beforeDestroy() {
        event.off('receiveAutoSelectResponse');
        event.off('saveStorage');
    },
    methods: {
        initialize () {
            return Promise
                .all([
                    this.fetchAlbum(),
                    this.fetchShooting(),
                ])
                .then(() => {
                    this.storageData.sourceDirectoryInfo = this.autoSelectResult.source_directory_info;
                    this.photos = JSON.parse(JSON.stringify(this.autoSelectResult.best_photos)) // deep copy
                        .map(p => {
                            p.is_selected = true;
                            p.is_highlighted = false;
                            p.label_icon = p.label.no >= 0 && p.label.no <= 9
                                ? 'mdi-numeric-' + p.label.no + '-box'
                                : null;
                            return p;
                        });

                    this.storageData.isInitialized = true;
                });
        },
        fetchAlbum () {
            return http.get('albums/' + this.albumId).then(res => this.storageData.album = res.data);
        },
        fetchShooting() {
            return http.get('shootings/' + this.shootingId).then(res => this.shooting = res.data);
        },
        selectAllPhotos(value) {
            this.photos.forEach(p => p.is_selected = value);
        },
        async bulkSelectPhotosForPageSpread() {
            if (!await this.showConfirm('現在の選定状態を全てリセットし、選択した写真のみを選定状態に変更します。　\nよろしいですか？')
                .then(() => true)
                .catch(() => false)
            ) {
                return;
            }
            const url = 'shootings/bulk_recreate_selections/' + this.shootingId;
            const photo_ids = this.photos.filter((p) => {return p.is_selected;}).map(p => p.id);
            if (!photo_ids.length) {
                this.showError('写真を選択してください');
                return;
            }

            http.put(url, {
                photo_ids: photo_ids
            })
                .then((res) => {
                    this.$emit('selection-status-changed', [
                        {
                            photo_ids: res.data.selected_photo_ids,
                            status: true,
                        },
                        {
                            photo_ids: res.data.unselected_photo_ids,
                            status: false,
                        }
                    ]);
                    this.closeCallback();
                })
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, '写真移動エラー');
                });
        },
        updateSelectionStatus (status, photo) {
            photo.is_selected_for_page_spread = status;
            http
                .backGroundRequest('put', 'photos/update_selection_status', {
                    photo_ids: [photo.id],
                    status: status,
                })
                .then(() => {
                    this.$emit('selection-status-changed', [{
                        photo_ids: [photo.id],
                        status: !status,
                    }]);
                })
                .catch(error => {
                    if (error === 'unauthorized') return;
                    photo.is_selected_for_page_spread = !status;
                    this.showError(error, '選定状態更新エラー');
                });
        },
    },
};
</script>