<template>
    <v-dialog
        v-model="value"
        persistent 
        max-width="600"
    >
        <v-card class="pa-3">
            <v-container>
                <v-form ref="form" lazy-validation>
                    <v-row>
                        <v-col cols="8">
                            <span>顔マスタ番号 : {{ _emitter_face.no }}</span>
                            <v-text-field
                                v-model="_emitter_face.student_no"
                                label="出席番号"
                                clearable
                                counter="10"
                                :rules="rules.studentNoRules"
                            />
                            <v-select
                                v-model="_emitter_face.sex"
                                :items="['男性', '女性']"
                                label="性別"
                                clearable
                            />  
                        </v-col>
                        <v-col cols="4">
                            <v-img
                                :src="_emitter_face.face_image_path"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea
                                v-model="_emitter_face.note"
                                label="個人メモ"
                                clearable
                            />
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
            <v-container>
                <v-row dense justify="space-between">
                    <cancel-dialog-button width="100" @click="closeDialog">
                        キャンセル
                    </cancel-dialog-button>
                    <v-spacer />
                    <proceed-dialog-button width="100" @click="$emit('updated')">
                        登録
                    </proceed-dialog-button>
                </v-row>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
import http from '../../services/http';
import leaveConform from '../../mixins/leave-confirm-mixin';

export default {
    mixins: [leaveConform],
    props: {
        value: {
            default: false,
            type: Boolean,
        },
        face: {
            default: () => { 
                return {
                    no: undefined,
                    sex: undefined,
                    note: undefined,
                };
            },
            type: Object,
        },
    },
    data () {
        return {
            viewID: '',
            storageData: {
                isInitialized: false,
                onlyUncompleted: true,
                modeUpdate: false,
            },
            httpState: http.state,
            rules: {
                studentNoRules: [
                    v => (v ? v.length <= 10 : true) || '10文字以内で入力してください。',
                ],
            },
        };
    },
    computed: {
        _emitter_face: {
            get: function() {
                return this.face;
            },
            set: function(val) {
                this.$emit('edited', val);
            }
        },
        _emitter_value: {
            get: function() {
                return this.value;
            },
            set: function(value) {
                this.$emit('input', value);
            },
        },
    },
    mounted() {
        // if (!this.restoreStorageData()) {
        //     this.initialize()
        //         .then(res => {
        //             this.storageData.isInitialized = true;
        //             this.confirmLeaveReset(['storageData']);
        //         })
        //         .catch(error => {
        //             if(error === 'unauthorized') return;
        //             this.showError("初期化処理に失敗しました。", "初期化エラー");
        //         });
        // }
    },
    methods: {
        async closeDialog() {
            if (await this.confirmLeave() === false) return;
            
            this._emitter_value = false;
            this.$refs.form.reset();
        },
        async initialize() {
            this.storageData.isInitialized = true;
            // this.confirmLeaveReset(['face']);
        },
        updateFaceInfo() {
            if (!this.$refs.form.validate()) return; // 入力チェックに違反したら以降の処理をしない。

            this.$emit('updated');

        },
    },

};

</script>