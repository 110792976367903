<template>
    <v-dialog v-model="visible" max-width="400" @click:outside="close">
        <v-card :color="$root.getTheme().background">
            <v-card-title class="text-md-subtitle-1">
                {{ title }}
            </v-card-title>
            <v-card-text>
                <v-form ref="form" lazy-validation>
                    <v-container fluid>
                        <v-row v-if="photos.length === 0">
                            <v-col cols="12">
                                <upload-area-input ref="uploadAreaInput" v-model="photos" :label="folderLabel" />
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <span>以下のファイルをアップロードします。</span>
                        </v-row>
                        <v-row>
                            <div v-if="this.screenType != 'createFace'"
                                style="text-align: right; width: 100%; margin-top: 16px;">制限枚数 : {{ limitCount -
                                    photosLength }}
                            </div>
                            <v-col cols="12"
                                style="min-height: 50px; max-height: 100px; padding: 0 24px; border: 1px solid #000; overflow-y: auto; scrollbar-width: none;">
                                <div v-if="photos != 0"
                                    style="display: flex; justify-content: space-between; position: sticky; top: 0; background-color: rgb(237, 252, 253);">
                                    <span style="align-content: center;">
                                        全{{ photos.length }}ファイル ( {{ getTotalBytes(photos) }}B )
                                    </span>
                                    <v-btn icon @click="photos = []">
                                        <v-icon>close</v-icon>
                                    </v-btn>
                                </div>
                                <div v-for="photo in photos" style="display: flex; justify-content: space-between;">
                                    <span>{{ photo.name }}</span>
                                    <span>{{ formatNumber(photo.size) }}B</span>
                                </div>
                            </V-col>
                        </v-row>
                        <!-- <v-row>
                            <v-progress-linear v-if="photos.length" v-model="progress" color="blue-grey" height="25">
                                <strong>{{ Math.ceil(progress) }}%</strong>
                            </v-progress-linear>
                        </v-row> -->
                        <v-row v-for="(alert, index) in duplicationAlerts" :key="index">
                            <span style="margin-top: 12px;">
                                <v-icon x-small :color="alert.type == 'warning' ? 'red' : ''">
                                    mdi-alert-circle
                                </v-icon>
                                {{ alert.text }}
                            </span>
                        </v-row>
                        <v-row>
                            <v-container class="px-3">
                                <v-row justify="space-between">
                                    <cancel-dialog-button width="150" @click="close">
                                        キャンセル
                                    </cancel-dialog-button>
                                    <proceed-dialog-button width="150"
                                        :disabled="!photos.length || (this.screenType != 'createFace' && this.limitCount < photosLength + photos.length)"
                                        @click="uploadPhotos">
                                        アップロード
                                    </proceed-dialog-button>
                                </v-row>
                            </v-container>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import http from '../../services/http';
import axios from 'axios';
import { List } from 'monet';

export default {
    mounted() {
        if (this.albumId != 0) {
            http
                .get('albums/' + this.albumId + '/edit')
                .then(res => {
                    this.limitCount = res.data.limit_count;
                })
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, 'データの取得に失敗しました。');
                });
        }
    },
    components: {
        'upload-area-input': require('../Inputs/UploadAreaInput.vue').default,
    },
    props: {
        title: {
            type: String,
            default: '写真アップロード',
        },
        folderLabel: {
            type: String,
            default: '対象フォルダ',
        },
        closeCallback: {
            type: Function,
            default: () => { },
        },
        visible: {
            type: Boolean,
            default: false,
        },
        albumId: {
            type: [String, Number],
            default: 0,
        },
        shootingId: {
            type: [String, Number],
            default: '-1'
        },
        photosLength: {
            type: [String, Number],
            default: 0,
        },
        screenType: {
            type: String,
            default: '',
        },
        existingPhotoNames: {
            type: Array,
            default: () => [],
        }
    },
    data() {
        return {
            folderName: null,
            fileData: null,
            photos: [],
            suspendOrder: false,
            chunkSize: 10,
            progressDialog: false,
            progress_num: 0,
            dupExistingPhotos: [],
            dupInUploadingPhotos: [],
            subFolderExisting: false,
            limitCount: 0,
        };
    },
    computed: {
        photoNames: function () {
            return this.photos.map((p) => {
                return p.name;
            });
        },
        progress: function () {
            return (this.progress_num / this.photos.length) * 100;
        },
        duplicationAlerts: function () {
            const alerts = [];
            if (this.subFolderExisting) {
                alerts.push({
                    type: 'info',
                    text: 'フォルダ内にサブフォルダが存在します。サブフォルダの構造情報は、自動選定機能の「サブフォルダ均等」に影響します。詳しくはマニュアルをご参照ください。',
                });
            }
            if (this.dupExistingPhotos.length) {
                alerts.push({
                    type: 'warning',
                    text: '既に存在する写真とファイル名が重複しています。ファイル名が重複するファイルはアップロードされません',
                });
            }
            if (this.dupInUploadingPhotos.length) {
                alerts.push({
                    type: 'warning',
                    text: '写真ファイル名がフォルダ内で重複しています。ファイル名が重複するサブフォルダのファイルはアップロードされません',
                });
            }
            if (this.limitCount < this.photosLength + this.photos.length && this.screenType != 'createFace') {
                alerts.push({
                    type: 'warning',
                    text: '制限枚数を超えているためアップロード出来ません',
                });
            }
            return alerts;
        },
    },
    watch: {
        photos: async function () {
            this.checkSubFolderExisting();
            this.checkPhotoDuplication();
        },
    },
    methods: {
        formatNumber(value) {
            if (value >= 1000000) {
                return (value / 1000000).toFixed(1) + 'M';
            } else if (value >= 1000) {
                return (value / 1000).toFixed(1) + 'K';
            } else {
                return value.toString();
            }
        },
        getTotalBytes(photos) {
            let total = 0;
            photos.forEach(function (photo) {
                total += photo.size;
            });
            return this.formatNumber(total);
        },

        async uploadPhotos() {
            console.log('upload photos');

            http.pushCommunicate();
            // ファイル名が重複するファイルを排除
            this.removePhotoDuplication();

            const photos_chunked = this.chunk(this.photos, this.chunkSize);

            let error_reason = "";
            for (const chunk of photos_chunked) {
                const readedFiles = await Promise.all(chunk.map(async (photo) => {
                    const result = await this.readFileAsync(photo);
                    return result;
                }));
                try {
                    await this.uploadPhotosChunk(readedFiles);
                }
                catch (error) {
                    error_reason = error.response.data;
                }
            };

            if (error_reason == "") {
                http.shiftCommunicate();
                this.showInfo('写真ファイルのアップロードが完了しました。', '写真アップロード');
                this.$emit('done-upload');
                this.photos = [];
                this.progress_num = 0;
                this.close();
            }
            else {
                http.shiftCommunicate();
                this.showError(error_reason, '写真アップロードエラー');
                this.$emit('done-upload');
                this.photos = [];
                this.progress_num = 0;
                this.close();
            };
        },
        async uploadPhotosChunk(readedFiles) {
            const uri = '/api/photos/upload';

            const formData = new FormData();
            formData.append('shooting_id', this.shootingId);
            const relativePathInfo = new Object();

            readedFiles.forEach(file => {
                if (file.type == 'image/jpeg') {
                    formData.append(
                        file.fileName,
                        new Blob([file.data], { type: file.type }),
                        file.fileName
                    );
                    relativePathInfo[file.fileName] = file.webkitRelativePath;
                }
            });
            formData.append('relative_path_info', JSON.stringify(relativePathInfo));

            console.log(...formData.entries());
            const config = {
                headers: {
                    'Content-type': 'multipart/form-data',
                },
            };
            return await axios
                .post(uri, formData, config)
                .then((res) => {
                    console.log(res);
                    this.progress_num += readedFiles.length;
                    return;
                })
                .catch((e) => {
                    console.log(e);
                    throw e;
                });
        },
        async readFileAsync(photo) {
            try {
                const file = new Object();
                file.type = photo.type;
                file.fileName = photo.name;
                file.webkitRelativePath = photo.webkitRelativePath;

                file.data = await this.readFile(photo);
                return file;
            } catch (error) {
                throw 'error';
            }
        },
        readFile(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => {
                    resolve(reader.result);
                };
                reader.onerror = reject;
                reader.readAsArrayBuffer(file);
            });
        },
        removePhotoDuplication() {
            this.photos = this.photos.filter((p) => {
                return (
                    // アップロード写真内でファイル名被りの場合はサブフォルダの物を排除
                    !this.dupInUploadingPhotos.includes(p.name) || p.webkitRelativePath.split('/').length <= 2
                ) // 既に撮影行事内に存在する写真を排除 
                    && !this.dupExistingPhotos.includes(p.name);
            });
        },
        checkPhotoDuplication() {
            const photoNames = this.photos.map((p) => { return p.name; });
            this.dupExistingPhotos = this.duplicatedPhotoNamesExistingPhotos(photoNames);
            this.dupInUploadingPhotos = this.duplicatedPhotoNamesInPhotoNames(photoNames);
        },
        checkSubFolderExisting() {
            this.subFolderExisting = this.photos.some((p) => {
                return p.webkitRelativePath.split('/').length > 2;
            });
        },
        duplicatedPhotoNamesExistingPhotos(photoNames) {
            return photoNames.filter((p) => this.existingPhotoNames.includes(p));
        },
        duplicatedPhotoNamesInPhotoNames(photoNames) {
            const photoNamesToSort = photoNames.concat();
            photoNamesToSort.sort();
            const duplication = photoNamesToSort.filter((name, idx) => {
                if (idx + 1 == photoNamesToSort.length) {
                    return false;
                }
                return name == photoNamesToSort[idx + 1];
            });
            return Array.from(new Set(duplication));
        },
        chunk(arr, size) {
            return arr.reduce(
                (newarr, _, i) => (i % size ? newarr : [...newarr, arr.slice(i, i + size)]),
                []
            );
        },
        close() {
            this.closeCallback();
        },
    },
};
</script>