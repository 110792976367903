<template>
    <div style="display: flex; flex-direction: column; align-items: center;">
        <span>画像フォルダを選択してください</span>
        <v-file-input v-model="inputValue" color="deep-purple accent-4" multiple outlined validate-on-blur
            placeholder="画像フォルダを選択" prepend-icon="" webkitdirectory class="no-icon-button">
        </v-file-input>
    </div>
</template>

<script>
export default {
    name: 'UploadAreaInput',
    props: {
        value: { type: Array, default: null, },
    },
    data() {
        return {
            rules: {
                filesRules: [v => v.length >= 1 || 'フォルダを選択してください。空のフォルダは選択できません。',],
            },
        };
    },
    computed: {
        inputValue: {
            get() { return this.value; },
            set(value) { this.$emit('input', value); },
        },
    },
    watch: {
        files() {
            this.inputValue = this.files.length >= 1
                ? this.files[0].webkitRelativePath.split('/')[0]
                : null;
        },
    },
    methods: {
        clear() {
            this.files = [];
        },
    },
};
</script>
<style>
/* アイコンのボタン要素を非表示にする */
.no-icon-button .v-text-field__details,
.no-icon-button .v-input__append-inner {
    display: none !important;
}
</style>