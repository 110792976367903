<template>
    <div class="fill-height">
        <v-layout row fill-height class="table-row-layout">
            <slot 
                :displayData="displayData" 
                :sortChanged="tableSortChanged" 
                :filterChanged="tableFilterChanged"
            />
        </v-layout>
        <v-layout v-if="paginate" row justify-center mt-2 class="pagination-row-layout">
            <el-pagination
                :page-sizes="[25, 50, 100, 999]"
                :page-size.sync="currentPageSize"
                :current-page.sync="currentPage"
                layout="sizes, prev, pager, next"
                :total="tableData == null ? 0 : tableData.length"
                @size-change="paginationSizeChanged"
                @current-change="paginationCurrentChanged"
            />
        </v-layout>
    </div>
</template>

<script>
export default {
    props:{
        tableData: {
            type: Array,
            default: undefined,
        },
        paginate: {
            type: Boolean,
            default: true,
        },
    },
    data () {
        return {
            displayData: [],
            currentPage: 1,
            currentPageSize: 25,
            sort: {
                prop: undefined,
                order: undefined,
            },
        };
    },
    watch: {
        tableData: function (newVal, oldVal) {
            this.currentPage = 1;
            this.updateDisplayData();
        },
    },
    created () {
        this.updateDisplayData();
    },
    methods: {
        tableSortChanged({column, prop, order}) {
            this.sort.prop = prop;
            this.sort.order = order;

            this.updateDisplayData();
        },
        tableFilterChanged({column, prop, filter}) {
            this.updateDisplayData();
        },
        paginationSizeChanged (size) {
            this.currentPageSize = size;
            this.updateDisplayData();
        },
        paginationCurrentChanged () {
            this.updateDisplayData();
        },
        updateDisplayData () {
            this.displayData = this.paginate 
                ? this.getPaginatedData(this.getSortedData()) 
                : this.tableData;
        },
        getSortedData () {
            if(this.tableData == null) {
                return this.tableData;
            }

            if(this.sort.prop == null || this.sort.order == null) {
                return this.tableData.slice();
            }

            const paths = this.sort.prop.split('.');

            return this.tableData.slice().sort((x, y) => {
                let valX = x;
                let valY = y;

                paths.forEach(path => {
                    valX = valX == null ? undefined : valX[path];
                    valY = valY == null ? undefined : valY[path];
                });

                return this.sort.order == 'ascending' ? (valX > valY ? 1 : -1) : (valY > valX ? 1 : -1);
            });
        },
        getPaginatedData(arr) {
            if(arr == null) {
                return;
            }

            const start = (this.currentPage - 1) * this.currentPageSize;
            return arr.slice(start, start + this.currentPageSize);
        }
    }
};
</script>

<style scoped>
.table-row-layout {
    padding-bottom: 40px;
}
.pagination-row-layout {
    position: relative;
    top: -40px;
}
</style>
