<template>
    <page-container>
        <template slot="navigation" />
        <template slot="header">
            <!-- 撮影行事ヘッダ -->
            <v-toolbar fixed dense :color="$root.getTheme().tertiary">
                <template>
                    <v-container fluid class="ma-0 pa-0">
                        <v-row justify="space-between" style="height: 48px !important">
                            <v-col cols="3">
                                <v-toolbar-title v-if="storageData.isInitialized" class="no-underline-breadcrumbs">
                                    <a :style="{ fontSize: '14px', textDecoration: 'underline' }"
                                        @click="transitToShootings">
                                        撮影行事
                                    </a>
                                    <span>
                                        <v-icon small>keyboard_arrow_right</v-icon>
                                        {{ storageData.shooting.name }}
                                    </span>
                                </v-toolbar-title>
                            </v-col>
                            <v-col v-if="storageData.isInitialized" cols="6">
                                <v-toolbar-title v-if="storageData.isInitialized">
                                    <span class="mr-2">撮影日：{{ formatDate(storageData.shooting.date) }}</span>
                                    <span class="mr-2">撮影者：{{ photographerName }}</span>
                                    <span v-if="allPhotoTabSelected">撮影行事内男女比：</span>
                                    <span v-else>撮影行事内男女比(選定中)：</span>
                                    <div v-if="sexRatio" :style="{ display: 'inline' }">
                                        <icon-sex-ratio :male="true" width="1.2em" height="1.2em" />
                                        <span>{{ sexRatio.manRatio }}</span>
                                        <icon-sex-ratio :male="false" width="1.2em" height="1.2em" />
                                        <span>{{ sexRatio.womanRatio }}</span>
                                    </div>
                                    <div v-else :style="{ display: 'inline' }">
                                        <span class="mr-2">未検出</span>
                                    </div>
                                </v-toolbar-title>
                            </v-col>
                            <v-col cols="3" class="ma-0 pa-0">
                                <v-container class="ma-0 py-0 pl-0 pr-6">
                                    <v-row justify="end">
                                        <icon-with-text text="人物頻度" :show-text="showText" icon icon-name="mdi-chart-box"
                                            :disabled="!isPhotoExisting" @click="openShootingFrequencyDialog" />
                                        <icon-with-text text="アップロード" :show-text="showText" icon
                                            icon-name="mdi-cloud-upload-outline" @click="openPhotoUploadDialog" />
                                        <icon-with-text :text="isDownloading ? '準備中...' : 'ダウンロード'" :show-text="showText"
                                            icon icon-name="mdi-cloud-download-outline"
                                            :disabled="isDownloadButtonClickable" @click="downloadPhotos" />
                                        <icon-with-text text="メモ" :show-text="showText" icon :icon-name="storageData.shooting.note
                                            ? 'mdi-comment-alert'
                                            : 'mdi-comment'
                                            " @click="() => {
        storageData.pageSpreadMemoDialog = true;
    }
        " />
                                    </v-row>
                                </v-container>
                            </v-col>
                        </v-row>
                    </v-container>
                </template>
            </v-toolbar>
            <!-- 撮影行事ツールバー -->
            <v-toolbar fixed dense :color="$root.getTheme().quaternary">
                <v-select v-model="storageData.label" :items="labels" label="ラベル" item-text="no" clearable return-object
                    dense outlined hide-details style="width: 100px" @blur="searchPhotos">
                    <template #item="{ item }">
                        <v-icon v-if="!!item.label_icon" :color="'#' + item.color_code">
                            {{ item.label_icon }}
                        </v-icon>
                    </template>
                    <template #selection="{ item }">
                        <v-icon v-if="!!item.label_icon" :color="'#' + item.color_code">
                            {{ item.label_icon }}
                        </v-icon>
                    </template>
                </v-select>
                <v-select v-model="storageData.selectedTags" :items="tags" label="タグ" item-text="name" clearable
                    return-object dense outlined hide-details multiple style="width: 100px" @blur="searchPhotos">
                    <template #selection="{ item }">
                        <v-chip :color="item.back_color_code" :text-color="item.fore_color_code" small>
                            {{ item.name }}
                        </v-chip>
                    </template>
                </v-select>
                <v-text-field v-model="storageData.pageNo" label="ページ番号" dense outlined hide-details style="width: 70px"
                    prefix="P" @blur="searchPhotos" />
                <face-select-panel :selected-faces="storageData.selectedFaces" :position-y="170" :faces="faces"
                    @selected-faces-changed="storageData.selectedFaces = $event" @face-selected="searchPhotos" />
                <v-select v-model="storageData.sortItem" :items="sortItems" label="並び順" return-object dense outlined
                    hide-details style="width: 100px" @blur="searchPhotos" />
                <v-btn-toggle v-model="storageData.sortOrder" dense group @change="searchPhotos">
                    <icon-with-text v-if="storageData.sortOrder == 'asc'" text="昇順" :show-text="showText" icon value="desc"
                        icon-name="mdi-order-numeric-ascending" />
                    <icon-with-text v-else text="降順" :show-text="showText" icon value="asc"
                        icon-name="mdi-order-numeric-descending" />
                </v-btn-toggle>
                <icon-with-text text="縮小" :show-text="showText" icon style="ma-0" icon-name="mdi-image-size-select-small"
                    :disabled="thumbnailSize.id <= 1" @click="changeThumbnailSize(-1)" />
                <icon-with-text text="拡大" :show-text="showText" icon-name="mdi-image-size-select-large" icon
                    :disabled="thumbnailSize.id >= thumbnailSizes.length" @click="changeThumbnailSize(1)" />
                <v-btn-toggle v-model="withRectangleToggles" dense group multiple>
                    <icon-with-text :color="withRectangleButtonColor" text="顔位置表示" :show-text="showText" :value="2" icon
                        icon-name="mdi-face-recognition" @click="switchWithRectangleMode" />
                </v-btn-toggle>
                <icon-with-text text="入替候補表示" :show-text="showText" icon icon-name="mdi-swap-vertical-circle"
                    :disabled="allPhotoTabSelected" @click="switchSuggestingSwapCandidateMode" />

                <v-spacer />
                <v-divider vertical color="#A0A0A0" />

                <v-btn-toggle dense group>
                    <icon-with-text v-if="!activeShouldSelectAll" text="全選択解除" :show-text="showText"
                        icon-name="mdi-select-all" icon :value="false" @click="selectAllPhotos(false)" />
                    <icon-with-text v-else text="全選択" :show-text="showText" icon-name="mdi-select" icon :value="true"
                        @click="selectAllPhotos(true)" />
                </v-btn-toggle>

                <icon-with-text v-if="areBothSwapCandidateSelected" text="入替" :show-text="showText" icon
                    icon-name="mdi-swap-horizontal-circle" @click="swapConfirmDialog = true" />
                <icon-with-text v-else-if="!allPhotoTabSelected && !isSelectedSwapSuggestionPhoto" text="選定解除"
                    :show-text="showText" icon icon-name="mdi-arrow-left-bold" :disabled="!isActivePhotoSelected"
                    @click="bulkChangePhotoSelectionStatusForPageSpread(0)" />
                <icon-with-text v-else text="選定" :show-text="showText" icon icon-name="mdi-arrow-right-bold"
                    :disabled="!isActivePhotoSelected" @click="bulkChangePhotoSelectionStatusForPageSpread(1)" />

                <icon-with-text text="選定操作取消" :show-text="showText" icon :icon-name="icons.mdiArrowULeftBottomBold"
                    :disabled="!isUndoMoveStacked" @click="undoChangeSelectionStatus" />
                <icon-with-text text="AI処理" :show-text="showText" icon :disabled="!isActivePhotoSelected"
                    icon-name="mdi-head-cog-outline" @click="doAiProc" />
                <icon-with-text text="削除" :show-text="showText" icon icon-name="mdi-delete"
                    :disabled="!isActivePhotoSelected" @click="deletePhotos" />
            </v-toolbar>
        </template>

        <template slot="top" />

        <template slot="middle">
            <v-container fluid class="pa-0">
                <!-- 写真未存在時のナビゲーション -->
                <v-row v-if="!isPhotoExisting">
                    <div class="ma-2 text-h6">
                        <v-icon> mdi-information-outline </v-icon>
                        <span>
                            撮影行事内に写真が存在しません。<br />
                            画面右上「アップロード」ボタンから写真をアップロードしてください。
                        </span>
                    </div>
                </v-row>
                <v-row v-else class="ma-0 pa-0">
                    <!-- 入替候補写真表示部 -->
                    <v-col v-if="suggestingSwapCandidate" cols="5" class="ma-0 pa-0">
                        <v-container fluid class="pt-0">
                            <v-toolbar justify="space-between">
                                <span class="mr-2 v-tab"> 入替候補 </span>
                                <v-divider vertical color="#A0A0A0" />
                                <icon-with-text v-if="!shouldSelectAllInSwapSuggestion" text="全選択解除" :show-text="showText"
                                    icon-name="mdi-select-all" icon :value="false"
                                    @click="selectAllPhotosInSwapSuggestion(false)" />
                                <icon-with-text v-else text="全選択" :show-text="showText" icon-name="mdi-select" icon
                                    :value="true" @click="selectAllPhotosInSwapSuggestion(true)" />
                                <div style="
                     {
                      width: 200px;
                    }
                  ">
                                    <v-text-field v-model="swapCandidateCount" class="mx-2" label="提示枚数" dense outlined
                                        hide-details type="number" min="1" max="100" @blur="searchSwapCandidatePhotos" />
                                </div>
                                <v-spacer />
                                <v-divider vertical color="#A0A0A0" />
                                <v-btn icon justify="end" @click="() => {
                                    suggestingSwapCandidateBase = false;
                                }
                                    ">
                                    <v-icon>close</v-icon>
                                </v-btn>
                            </v-toolbar>
                            <v-row v-if="swapCandidatePhotos.length" class="ma-0 pa-0 pl-2 pb-2 overlay-scrollable" dense
                                :style="{ maxmaxHeight: '90vh', background: $root.getTheme().tertiary }">
                                <v-col v-for="photo in swapCandidatePhotos" :key="photo.id" :cols="thumbnailSize.cols"
                                    :sm="thumbnailSize.sm" :md="thumbnailSize.md" :lg="thumbnailSize.lg"
                                    :xl="thumbnailSize.xl">
                                    <photo-thumbnail-card :photo="photo" :size="thumbnailSize"
                                        :with-rectangle="storageData.withRectangle" :is-selected="photo.uiStatus.selectedInSwapCandidateSuggestion
                                            " :click-callback="openPhotoDialog" @photo-selected="(value) => {
        photo.uiStatus.selectedInSwapCandidateSuggestion =
            value;
    }
        " @selection-status-changed-for-page-spread="(value) => {
        updateSelectionStatus(value, photo);
    }
        " @label-changed="incrementPhotoLabel(photo)" />
                                </v-col>
                            </v-row>
                            <v-row v-else class="ma-0 pa-0 pl-2 pb-2" dense
                                :style="{ height: '40vh', background: $root.getTheme().tertiary }">
                                <!-- ロード中 -->
                                <div v-if="swapCandidateLoading" class="el-loading-spinner" style="position: relative">
                                    <svg class="circular" viewBox="25 25 50 50">
                                        <circle class="path" cx="50" cy="50" r="20" fill="none" />
                                    </svg>
                                </div>
                                <div v-else class="ma-2">
                                    <div>
                                        <v-icon :style="{ verticalAlign: 'top' }">
                                            mdi-information-outline
                                        </v-icon>
                                        <span :style="{ fontSize: '1.5em' }">
                                            {{ frequencyDisplayDescription }}
                                            <br />
                                        </span>
                                    </div>
                                    <span> <br />入れ替え候補提示条件:<br /> </span>
                                    <span>・ラベル</span>
                                    <span v-for="label in labels.filter((l) => {
                                        return [1, 2].includes(l.no);
                                    })" :key="label.no">
                                        <v-icon :color="'#' + label.color_code">
                                            {{ label.label_icon }}
                                        </v-icon>
                                    </span>
                                    <span>が付いた未選定状態の写真の中から提示されます。<br /></span>
                                    <span>・選択した顔の選定中の写真内での人物頻度が、<br /></span>
                                    <p :style="{ textIndent: '2em' }">
                                        <span>
                                            多い場合: 選択した全ての顔が映っていない写真を提示します。
                                        </span>
                                    </p>
                                    <p :style="{ textIndent: '2em' }">
                                        <span>
                                            少ない場合:
                                            選択した顔のうち少なくとも一人が映っている写真を提示します。
                                        </span>
                                    </p>
                                </div>
                            </v-row>
                        </v-container>
                    </v-col>
                    <!-- 写真サムネイル一覧部 -->
                    <v-col :cols="suggestingSwapCandidate ? 5 : 10" class="ma-0 pa-0">
                        <v-container fluid class="pt-0">
                            <v-tabs v-model="selectedTab">
                                <v-tab> 全写真 </v-tab>
                                <v-tab> 選定中の写真 </v-tab>
                                <v-tab-item class="pb-2">
                                    <v-row class="ma-0 pa-0" dense>
                                        <v-col v-if="storageData.isInitialized" class="text-right">
                                            {{ photos.length + " 枚" }}
                                        </v-col>
                                    </v-row>
                                    <v-row class="ma-0 pa-0 pl-2 pb-2 overlay-scrollable" dense
                                        :style="{ maxHeight: '90vh' }">
                                        <v-col v-for="photo in photos" :key="photo.id" :cols="thumbnailSize.cols"
                                            :sm="thumbnailSize.sm" :md="thumbnailSize.md" :lg="thumbnailSize.lg"
                                            :xl="thumbnailSize.xl">
                                            <photo-thumbnail-card :photo="photo" :size="thumbnailSize"
                                                :with-rectangle="storageData.withRectangle"
                                                :click-callback="openPhotoDialog" :is-selected="photo.uiStatus.selected"
                                                @photo-selected="(value) => {
                                                    photo.uiStatus.selected = value;
                                                }
                                                    " @selection-status-changed-for-page-spread="(value) => {
        updateSelectionStatus(value, photo);
    }
        " @label-changed="incrementPhotoLabel(photo)" />
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                                <v-tab-item>
                                    <v-row class="ma-0 pa-0" dense>
                                        <v-col v-if="storageData.isInitialized" class="text-right">
                                            {{
                                                photosSelectedForPageSpread.length +
                                                (Boolean(storageData.shooting.expect_photo_amount)
                                                    ? "/" + storageData.shooting.expect_photo_amount
                                                    : "") +
                                                "枚"
                                            }}
                                        </v-col>
                                    </v-row>
                                    <v-row class="ma-0 pa-0 pl-2 pb-2 overlay-scrollable" dense
                                        :style="{ maxHeight: '90vh' }">
                                        <v-col v-for="photo in photosSelectedForPageSpread" :key="photo.id"
                                            :cols="thumbnailSize.cols" :sm="thumbnailSize.sm" :md="thumbnailSize.md"
                                            :lg="thumbnailSize.lg" :xl="thumbnailSize.xl">
                                            <photo-thumbnail-card :photo="photo" :size="thumbnailSize"
                                                :with-rectangle="storageData.withRectangle"
                                                :click-callback="openPhotoDialog"
                                                :is-selected="photo.uiStatus.selectedInPageSpread" @photo-selected="(value) => {
                                                    photo.uiStatus.selectedInPageSpread = value;
                                                }
                                                    " @selection-status-changed-for-page-spread="(value) => {
        updateSelectionStatus(value, photo);
    }
        " @label-changed="incrementPhotoLabel(photo)" />
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                            </v-tabs>
                        </v-container>
                    </v-col>

                    <!-- 検出人物表示部 -->
                    <!-- 人物頻度表示部 -->
                    <v-col cols="2" class="ma-0 pa-0">
                        <v-card :loading="frequencyDisplayLoading">
                            <v-card-title class="pa-1">
                                人物頻度({{
                                    allPhotoTabSelected ? "全写真" : "選定中の写真のみ"
                                }})
                            </v-card-title>
                            <v-toolbar fixed dense :color="$root.getTheme().quaternary" class="px-0">
                                <v-container class="px-0">
                                    <v-row class="px-0">
                                        <v-col cols="6" class="px-0">
                                            <v-text-field v-model="storageData.personStudentNo" label="出席番号" dense outlined
                                                hide-details @blur="searchFrequencies" />
                                        </v-col>
                                        <v-col cols="6" class="px-0">
                                            <v-text-field v-model="storageData.personNote" label="個人メモ" dense outlined
                                                hide-details @blur="searchFrequencies" />
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-toolbar>
                            <v-toolbar fixed dense :color="$root.getTheme().quaternary" class="px-0">
                                <v-container class="px-0">
                                    <v-row class="px-0">
                                        <v-col cols="8" class="px-0">
                                            <v-select v-model="storageData.frequencySortItem" :items="frequencySortItems"
                                                label="並び順" return-object dense outlined hide-details style="width: 200px"
                                                @blur="constractBothEndsOfFrequencies" />
                                        </v-col>
                                        <v-col cols="4" class="px-0">
                                            <v-text-field v-model="frequencyDisplayRowNum" label="各表示行数" dense outlined
                                                hide-details type="number" min="1" :max="Math.ceil(faces.length / 6)"
                                                @blur="constractBothEndsOfFrequencies" />
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-toolbar>
                            <v-card-text v-if="bestFrequencies.length == 0 && worstFrequencies == 0" class="px-1">
                                <v-icon>error</v-icon>
                                未検出
                            </v-card-text>
                            <v-card-text v-else class="py-1 px-0 overlay-scrollable" :style="{ maxHeight: '90vh' }">
                                <v-container class="ma-0 pa-0">
                                    <v-row v-if="bestFrequencies.length >= 1" class="ma-0 pa-0">
                                        <v-col v-for="f in bestFrequencies" :key="f.face_id"
                                            :cols="frequencyDisplayColumnNum" class="ma-0 pa-0">
                                            <person-frequency-card :frequency="f" :is-highlighting="f.is_highlighting"
                                                :click-callback="clickFaceInBestFrequencies" />
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="bestFrequencies.length >= 1">
                                        <v-col cols="12" class="text-center">
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="worstFrequencies.length >= 1" class="ma-0 pa-0">
                                        <v-col v-for="f in worstFrequencies" :key="f.face_id"
                                            :cols="frequencyDisplayColumnNum" class="ma-0 pa-0">
                                            <person-frequency-card :frequency="f" :is-highlighting="f.is_highlighting"
                                                :click-callback="clickFaceInWorstFrequencies" />
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </template>

        <template slot="bottom">
            <!-- 写真詳細ダイアログ -->
            <photo-dialog ref="photoDialogVue" :album-id="albumId" :photo-id="storageData.photoId" :photos="activePhotos"
                :faces="faces" :labels="labels" :tags="tags" :page-spread-mode="false" :visible="storageData.photoDialog"
                :slide-photo-callback="applyChangePhotoId" :update-photo-callback="applyUpdatePhoto"
                :close-callback="closePhotoDialog" />
            <!-- 人物頻度確認ダイアログ -->
            <v-dialog v-model="storageData.shootingFrequencyDialog" persistent fullscreen hide-overlay
                transition="dialog-bottom-transition">
                <v-card :color="$root.getTheme().background">
                    <shooting-frequency ref="shootingFrequencyVue" :album-id="albumId"
                        :close-callback="closeShootingFrequencyDialog" />
                </v-card>
            </v-dialog>
            <!-- 写真アップロードダイアログ -->
            <photo-upload-dialog ref="photoUploadDialogVue" :album-id="albumId" :shooting-id="shootingId"
                :photos-length="photos.length" :visible="storageData.photoUploadDialog"
                :close-callback="closePhotoUploadDialog" :existing-photo-names="photos.map((p) => {
                    return p.name;
                })
                    " @done-upload="searchPhotos" />
            <!-- 自動選定設定入力ダイアログ -->
            <auto-select-dialog ref="autoSelectDialogVue" :album-id="albumId" :shooting-id="shootingId"
                :completed-callback="autoSelectCompleted" />
            <!-- 自動選定結果出力ダイアログ -->
            <v-dialog v-model="storageData.autoSelectedPhotosDialog" persistent fullscreen hide-overlay
                transition="dialog-bottom-transition">
                <v-card :color="$root.getTheme().background">
                    <auto-selected-photos ref="autoSelectPhotosVue" :album-id="albumId" :shooting-id="shootingId"
                        :auto-select-result="storageData.autoSelectResult" :close-callback="closeAutoSelectedPhotosDialog"
                        @selection-status-changed="(val) => {
                            undoMoveStack.push(val);
                        }
                            " />
                </v-card>
            </v-dialog>
            <!-- ページメモダイアログ -->
            <v-dialog v-model="storageData.pageSpreadMemoDialog" persistent max-width="400">
                <v-form ref="pageSpreadForm" lazy-validation>
                    <v-card class="pa-3">
                        <v-container>
                            <v-row>
                                <v-col>
                                    <v-textarea v-model="storageData.shooting.note" label="メモ" outlined hide-details />
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container class="px-3">
                            <v-row justify="space-between">
                                <cancel-dialog-button width="100" @click="() => {
                                    storageData.pageSpreadMemoDialog = false;
                                }
                                    ">
                                    キャンセル
                                </cancel-dialog-button>
                                <proceed-dialog-button width="100" @click="registerPageSpreadMemo">
                                    登録
                                </proceed-dialog-button>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-form>
            </v-dialog>
            <!-- 人物頻度変化確認ダイアログ -->
            <swap-confirm-dialog v-model="swapConfirmDialog" :added-people="addedPeople" :rejected-people="rejectedPeople"
                :executable="areBothSwapCandidateSelected" @executed="swapPhotos" />
        </template>
    </page-container>
</template>

<script>
import event from '../../utils/event';
import http from '../../services/http';
import urlUtil from '../../utils/url';
import leaveConform from '../../mixins/leave-confirm-mixin';
import monet from 'monet';
import { mdiArrowULeftBottomBold } from '@mdi/js';
import { awaitJobDone } from '../../services/awaitJobDone';

export default {
    components: {
        'photo-thumbnail-card': require('../Photos/PhotoThumbnailCard.vue').default,
        'person-frequency-card': require('../Frequency/PersonFrequencyCard.vue')
            .default,
        'photo-dialog': require('../Photos/PhotoDialog.vue').default,
        'shooting-frequency': require('../Frequency/ShootingFrequency.vue').default,
        'face-select-panel': require('../Faces/FaceSelectPanel.vue').default,
        'photo-upload-dialog': require('../Dialogs/PhotoUploadDialog.vue').default,
        'icon-with-text': require('../Icons/IconWithText.vue').default,
        'auto-select-dialog': require('../PageSpreads/AutoSelectDialog.vue')
            .default,
        'auto-selected-photos': require('../PageSpreads/AutoSelectedPhotos.vue')
            .default,
        'swap-confirm-dialog': require('./SwapConfirmDialog.vue').default,
        'icon-sex-ratio': require('../Icons/IconSexRatio.vue').default,
    },
    mixins: [leaveConform],
    props: {
        albumId: {
            type: [String, Number],
            default: 0,
        },
        shootingId: {
            type: [String, Number],
            default: 0,
        },
    },
    data() {
        return {
            viewID: '',
            drawer: false,
            storageData: {
                isInitialized: false,
                shooting: undefined,

                photoDialog: false, // 写真詳細ダイアログの表示状態
                photoId: 0, // 詳細表示中の写真ID

                label: undefined, // 検索条件
                selectedTags: [], // 検索条件
                selectedFaces: [], // 検索条件
                pageNo: '', // 検索条件
                sortItem: undefined, // ソート条件
                sortOrder: 'asc', // ソート条件
                thumbnailSize: undefined, // サムネイルサイズ
                withRectangle: true, // 検出顔矩形付きサムネイルを表示するか否か

                shootingFrequencyDialog: false, // 人物頻度確認ダイアログの表示フラグ
                pageSpreadMemoDialog: false, // ページ構成メモダイアログ
                autoSelectedPhotosDialog: false, // 自動選定結果出力ダイアログ
                photoUploadDialog: false,

                personStudentNo: '', // 人物検索条件
                personNote: '', // 人物検索条件
                frequencySortItem: undefined, // 人物頻度ソート条件
            },
            rules: {},
            httpState: http.state,
            photos: [],
            shootingFaces: [],
            pageSpreads: [],
            faces: [],
            tags: [],
            labels: [],
            frequencies: [],
            bestFrequencies: [],
            worstFrequencies: [],
            swapCandidatePhotoIds: [],
            shouldSelectAll: true,
            shouldSelectAllInPageSpread: true,
            shouldSelectAllInSwapSuggestion: true,
            isDownloading: false,
            sortItems: [
                { value: 'shooting_time', text: '撮影日時' },
                { value: 'name', text: '写真名' },
                { value: 'loughting_rate', text: '笑顔率' },
                { value: 'photo_face_count', text: '検出人数' },
            ],
            thumbnailSizesBase: [
                {
                    id: 1,
                    width: 240,
                    height: 160,
                    cols: 2,
                    sm: 12,
                    md: 4,
                    lg: 3,
                    xl: 2,
                },
                {
                    id: 2,
                    width: 300,
                    height: 200,
                    cols: 3,
                    sm: 12,
                    md: 5,
                    lg: 4,
                    xl: 3,
                },
                {
                    id: 3,
                    width: 600,
                    height: 400,
                    cols: 6,
                    sm: 12,
                    md: 12,
                    lg: 12,
                    xl: 6,
                },
                {
                    id: 4,
                    width: 720,
                    height: 480,
                    cols: 6,
                    sm: 12,
                    md: 12,
                    lg: 12,
                    xl: 6,
                },
            ],
            thumbnailSizeIndex: 0,
            frequencySortItems: [
                { value: 1, text: '撮影行事内頻度' },
                { value: 2, text: 'アルバム内頻度' },
            ],
            withRectangleToggles: [2],
            showText: true,
            icons: {
                mdiArrowULeftBottomBold,
            },
            undoMoveStack: [], // // 選定操作取消写真ID,変更状態のスタック（後入先出し）
            selectedTab: null,
            frequencyDisplayLoading: false,
            frequencyDisplayColumnNum: 4, // 人物頻度表示列数
            frequencyDisplayRowNum: 5, // 人物頻度表示行数
            suggestingSwapCandidateBase: false, // 入れ替え候補写真を表示するか
            swapCandidateCount: 5, // 入れ替え候補表示数
            swapConfirmDialog: false, // 入れ替え結果確認ダイアログを表示するか
            swapCandidateLoading: false,
            descriptions: {
                noGoodSwapCandidateExistingDescription:
                    ' 条件に該当する写真が存在しません。',
                noFrequencyDisplayFaceSelectedDescription:
                    '画面右列の人物頻度から顔を選択してください。',
            },
        };
    },
    computed: {
        isActivePhotoSelected() {
            // const allPhotoTabSelected = this.allPhotoTabSelected;
            // return this.activePhotos.some(photo => allPhotoTabSelected ? photo.uiStatus.selected : photo.uiStatus.selectedInPageSpread);
            return this.activePhotos.some((photo) => photo.selected);
        },
        isSelectedSwapSuggestionPhoto() {
            return this.swapCandidatePhotos.some(
                (p) => p.uiStatus.selectedInSwapCandidateSuggestion
            );
        },
        isSelectedPageSpreadPhoto() {
            return this.photosSelectedForPageSpread.some(
                (p) => p.uiStatus.selectedInPageSpread
            );
        },
        areBothSwapCandidateSelected() {
            return (
                this.isSelectedPageSpreadPhoto && this.isSelectedSwapSuggestionPhoto
            );
        },
        isPhotoExisting() {
            return !(
                !this.storageData.label &&
                !this.storageData.pageNo &&
                !this.storageData.selectedTags.length &&
                !this.storageData.selectedFaces.length &&
                this.storageData.isInitialized &&
                !this.photos.length
            );
        },
        isFaceMasterCreated() {
            return this.faces.length > 0;
        },
        isUndoMoveStacked() {
            return this.undoMoveStack.length > 0;
        },
        withRectangleButtonColor() {
            return this.storageData.withRectangle ? 'accent' : '';
        },
        photosSelectedForPageSpread() {
            return this.photos.filter((photo) => {
                return photo.is_selected_for_page_spread;
            });
        },
        allPhotoTabSelected() {
            return this.selectedTab == 0;
        },
        activePhotos() {
            return this.allPhotoTabSelected
                ? this.photos.map((p) => {
                    p.selected = p.uiStatus.selected;
                    return p;
                })
                : this.photosSelectedForPageSpread
                    .map((p) => {
                        p.selected = p.uiStatus.selectedInPageSpread;
                        return p;
                    })
                    .concat(
                        this.swapCandidatePhotos.map((p) => {
                            p.selected = p.uiStatus.selectedInSwapCandidateSuggestion;
                            return p;
                        })
                    );
        },
        activeShouldSelectAll: {
            get: function () {
                return this.allPhotoTabSelected
                    ? this.shouldSelectAll
                    : this.shouldSelectAllInPageSpread;
            },
            set: function (value) {
                if (this.allPhotoTabSelected) {
                    this.shouldSelectAll = value;
                } else {
                    this.shouldSelectAllInPageSpread = value;
                }
            },
        },
        swapCandidatePhotos() {
            return this.suggestingSwapCandidate
                ? this.photos.filter((photo) => {
                    return this.swapCandidatePhotoIds.includes(photo.id);
                })
                : [];
        },
        isFrequencyDisplayFaceHighlighted() {
            return (
                this.bestFrequencies.some((f) => {
                    return f.is_highlighting;
                }) |
                this.worstFrequencies.some((f) => {
                    return f.is_highlighting;
                })
            );
        },
        frequencyDisplayDescription() {
            return this.isFrequencyDisplayFaceHighlighted
                ? // 顔選択はされているが該当する写真が無い場合
                this.descriptions.noGoodSwapCandidateExistingDescription
                : this.descriptions.noFrequencyDisplayFaceSelectedDescription;
        },
        frequencyDisplayCount() {
            return (
                this.frequencyDisplayRowNum * (12 / this.frequencyDisplayColumnNum)
            );
        },
        suggestingSwapCandidate() {
            return this.suggestingSwapCandidateBase && !this.allPhotoTabSelected;
        },
        addedPeople() {
            return this.aggregateFrequency(
                this.swapCandidatePhotos.filter(
                    (p) => p.uiStatus.selectedInSwapCandidateSuggestion
                )
            );
        },
        rejectedPeople() {
            return this.aggregateFrequency(
                this.photosSelectedForPageSpread.filter(
                    (p) => p.uiStatus.selectedInPageSpread
                )
            );
        },
        thumbnailSizes() {
            return !this.suggestingSwapCandidate
                ? this.thumbnailSizesBase
                : [
                    {
                        id: 1,
                        width: 240,
                        height: 160,
                        cols: 4,
                        sm: 12,
                        md: 6,
                        lg: 6,
                        xl: 4,
                    },
                    {
                        id: 2,
                        width: 300,
                        height: 200,
                        cols: 6,
                        sm: 12,
                        md: 12,
                        lg: 6,
                        xl: 6,
                    },
                    {
                        id: 3,
                        width: 600,
                        height: 400,
                        cols: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                ];
        },
        thumbnailSize() {
            return this.thumbnailSizeIndex >= this.thumbnailSizes.length
                ? this.thumbnailSizes[this.thumbnailSizes.length - 1]
                : this.thumbnailSizeIndex < 0
                    ? this.thumbnailSizes[0]
                    : this.thumbnailSizes[this.thumbnailSizeIndex];
        },
        sexRatio() {
            if (!this.storageData.shooting.sex_ratio) {
                return false;
            }
            if (this.allPhotoTabSelected) {
                return {
                    manRatio: Math.round(this.storageData.shooting.sex_ratio * 100) + '%',
                    womanRatio:
                        Math.round((1 - this.storageData.shooting.sex_ratio) * 100) + '%',
                };
            } else {
                // フロントで集計 微妙すぎる
                const count = this.photosSelectedForPageSpread.reduce(
                    (accumulator, current) => {
                        // man count
                        accumulator[0] += current.photo_faces.filter((pf) => {
                            return pf.face.sex == '男性';
                        }).length;
                        // whole count
                        accumulator[1] += current.photo_faces.length;
                        return accumulator;
                    },
                    [0, 0]
                );

                return count[1] == 0
                    ? { manRatio: '0%', womanRatio: '0%' }
                    : {
                        manRatio: Math.round((count[0] / count[1]) * 100) + '%',
                        womanRatio: Math.round(100 - (count[0] / count[1]) * 100) + '%',
                    };
            }
        },
        photographerName() {
            return this.storageData.shooting.photographer_name
                ? this.storageData.shooting.photographer_name
                : '未登録';
        },
        isDownloadButtonClickable() {
            return Boolean(!this.isPhotoExisting | this.isDownloading);
        },
    },
    watch: {
        selectedTab: function () {
            this.searchFrequencies();
        },
    },
    created() {
        this.storageData.sortItem = this.sortItems[0];
        this.storageData.frequencySortItem = this.frequencySortItems[0];

        event.on('saveStorage', () => {
            if (this.storageData.isInitialized == true) {
                this.saveStorageData();
            }
        });
        event.on('updatedFaces', () => {
            this.fetchFaces();
        });
        event.on('updatedTags', () => {
            this.fetchTags();
        });
    },
    mounted() {
        this.restoreStorageData();
        this.initialize();
    },
    beforeDestroy() {
        event.off('saveStorage');
        event.off('updatedFaces');
        event.off('updatedTags');
    },
    methods: {
        initialize() {
            this.storageData.isInitialized = false;
            this.undoMoveStack = [];
            Promise.all([
                this.fetchAlbum(),
                this.fetchShooting(),
                // this.fetchImportedPageSpreads(),
                this.fetchFaces(),
                this.fetchTags(),
                this.fetchLabels(),
            ])
                .then(() => {
                    return this.searchPhotos();
                })
                .then(() => {
                    this.storageData.isInitialized = true;
                })
                .catch((error) => {
                    if (error === 'unauthorized') return;
                    this.showError('初期化処理に失敗しました。', '初期化エラー', error);
                });
        },
        fetchAlbum() {
            return http.get('albums/' + this.albumId).then((res) => {
                this.album = res.data;
            });
        },
        fetchShooting() {
            return http
                .get('shootings/' + this.shootingId)
                .then((res) => (this.storageData.shooting = res.data));
        },
        fetchImportedPageSpreads() {
            return http
                .get(
                    'page_spreads?album_id=' +
                    this.albumId +
                    '&photo_directory_status=取込済'
                )
                .then((res) => (this.pageSpreads = res.data));
        },
        fetchFaces() {
            return http
                .get('faces?album_id=' + this.albumId)
                .then((res) => (this.faces = res.data));
        },
        fetchTags() {
            return http
                .get('tags?album_id=' + this.albumId)
                .then((res) => (this.tags = res.data));
        },
        fetchLabels() {
            return http.get('labels').then(
                (res) =>
                (this.labels = res.data.map((l) => {
                    l.label_icon =
                        l.no >= 0 && l.no <= 9 ? 'mdi-numeric-' + l.no + '-box' : null;
                    return l;
                }))
            );
        },
        fetchPhotos() {
            return http
                .get(
                    ((url) => {
                        url = urlUtil.addQueryParamIfDefined(
                            url,
                            'shooting_id',
                            this.shootingId
                        );
                        url = urlUtil.addQueryParamIfDefined(
                            url,
                            'sort_item',
                            this.storageData.sortItem.value
                        );
                        url = urlUtil.addQueryParamIfDefined(
                            url,
                            'sort_order',
                            this.storageData.sortOrder
                        );

                        url = monet.Maybe.fromNull(this.storageData.label)
                            .map((l) => urlUtil.addQueryParamIfDefined(url, 'label_id', l.id))
                            .orSome(url);

                        url = monet.Maybe.fromFalsy(this.storageData.pageNo) // 空白もnone
                            .map((n) => urlUtil.addQueryParamIfDefined(url, 'page_no', n))
                            .orSome(url);

                        this.storageData.selectedTags.forEach(
                            (t) =>
                                (url = urlUtil.addQueryParamIfDefined(url, 'tag_ids[]', t.id))
                        );

                        this.storageData.selectedFaces.forEach(
                            (f) =>
                                (url = urlUtil.addQueryParamIfDefined(url, 'face_ids[]', f.id))
                        );

                        return url;
                    })('photos')
                )
                .then((res) => {
                    this.photos = res.data.map((p) => {
                        p.uiStatus = {
                            selected: false,
                            selectedInPageSpread: false,
                            selectedInSwapCandidateSuggestion: false,
                        };
                        p.is_highlighted = false;

                        p.label_icon =
                            p.label.no >= 0 && p.label.no <= 9
                                ? 'mdi-numeric-' + p.label.no + '-box'
                                : null;

                        p.photo_faces = p.photo_faces.map((pf) => {
                            pf.is_highlighting = false; // 詳細ダイアログでの検出顔による矩形ハイライト用のフラグ
                            return pf;
                        });

                        return p;
                    });

                    this.shootingFaces = this.faces
                        .map((f) => {
                            f.is_highlighting = false;
                            return f;
                        })
                        .sort((f, s) => f.no - s.no);

                    // this.shootingFaces = this.photos
                    //     .flatMap(p => p.photo_faces.map(pf => pf.face))
                    //     .reduce((acc, f) => monet.Maybe.fromNull(acc.find(x => x.id === f.id)).cata(() => acc.concat(f), _ => acc), []) // 顔ID で一意にする。
                    //     .map(f => {
                    //         f.is_highlighting = false; // 検出人物による写真ハイライト用のフラグ
                    //         return f;
                    //     })
                    //     .sort((f, s) => f.no - s.no); // 顔マスタ番号の降順
                });
        },
        async searchPhotos() {
            await this.fetchPhotos()
                .then(() => {
                    this.searchFrequencies();
                })
                .catch((error) => {
                    if (error === 'unauthorized') return;
                    this.showError('写真の検索に失敗しました。', '写真検索エラー', error);
                });
        },
        // 撮影行事系
        initShootingStorageData() {
            this.storageData.shootingDialog = false;
            this.storageData.shootingModeUpdate = false;

            this.storageData.shooting.id = 0;
            this.storageData.shooting.name = '';
            this.storageData.shooting.date = undefined;
            this.storageData.shooting.photographerName = '';
            this.storageData.shooting.updatedAt = undefined;
        },
        createShooting() {
            return http.post('shootings/store', {
                album_id: this.albumId,
                name: this.storageData.shooting.name,
                date: this.storageData.shooting.date,
                photographer_name: this.storageData.shooting.photographerName,
            });
        },
        updateShooting() {
            return http.put('shootings/' + this.storageData.shooting.id, {
                name: this.storageData.shooting.name,
                date: this.$moment(this.storageData.shooting.date).format('YYYY-MM-DD'),
                photographer_name: this.storageData.shooting.photographer_name,
                note: this.storageData.shooting.note,
                updated_at: this.storageData.shooting.updated_at,
            });
        },
        editShooting(shooting) {
            http
                .get('shootings/' + shooting.id + '/edit')
                .then((res) => {
                    this.storageData.shooting.id = res.data.id;
                    this.storageData.shooting.name = res.data.name;
                    this.storageData.shooting.date = res.data.date;
                    this.storageData.shooting.photographerName =
                        res.data.photographer_name;
                    this.storageData.shooting.updatedAt = res.data.updated_at;

                    this.confirmLeaveReset(['storageData', 'shooting']);

                    this.storageData.shootingModeUpdate = true;
                    this.storageData.shootingDialog = true;
                })
                .catch((error) => {
                    if (error === 'unauthorized') return;
                    this.showError(error, 'データの取得に失敗しました。');
                });
        },
        async closeShootingDialog() {
            if ((await this.confirmLeave()) === false) return;

            this.storageData.shootingDialog = false;
            this.$refs.shootingForm.reset();

            setTimeout(() => {
                this.initShootingStorageData();
                this.confirmLeaveReset(['storageData', 'shooting']);
            }, 10);
        },
        changeThumbnailSize(change) {
            this.thumbnailSizeIndex = this.thumbnailSizeIndex + change;
        },
        selectAllPhotos(value) {
            const allPhotoTabSelected = this.allPhotoTabSelected;
            this.activePhotos.forEach((p) => {
                if (allPhotoTabSelected) {
                    p.uiStatus.selected = value;
                } else {
                    p.uiStatus.selectedInPageSpread = value;
                }
            });
            this.activeShouldSelectAll = !value;
        },
        selectAllPhotosInSwapSuggestion(value) {
            this.swapCandidatePhotos.forEach((p) => {
                p.uiStatus.selectedInSwapCandidateSuggestion = value;
            });
            this.shouldSelectAllInSwapSuggestion = !value;
        },
        deletePhotos() {
            monet.NEL.fromArray(this.activePhotos.filter((p) => p.selected))
                .toValidation('写真を選択してください。')
                .cata(
                    (error) => this.showError(error, '写真削除エラー'),
                    (photos) => {
                        this.showConfirm(
                            '選択した写真を削除します。　\nよろしいですか？'
                        ).then((confirmResult) => {
                            if (confirmResult) {
                                http
                                    .delete('photos/delete', {
                                        photo_ids: photos.map((p) => p.id).toArray(),
                                    })
                                    .then(() => {
                                        this.showInfo('選択写真の削除が完了しました。', '写真削除');
                                        this.searchPhotos();
                                    })
                                    .catch((error) => {
                                        if (error === 'unauthorized') return;
                                        this.showError(error, '写真削除エラー');
                                    });
                            }
                        });
                    }
                );
        },
        updateSelectionStatus(status, photo) {
            photo.is_selected_for_page_spread = status;
            http
                .backGroundRequest('put', 'photos/update_selection_status', {
                    photo_ids: [photo.id],
                    status: status,
                })
                .then(() => {
                    // 全写真タブがアクティブな時は変化が無いため更新しない
                    if (!this.allPhotoTabSelected) {
                        this.searchFrequencies();
                    }
                    this.undoMoveStack.push([
                        {
                            photo_ids: [photo.id],
                            status: !status,
                        },
                    ]);
                })
                .catch((error) => {
                    if (error === 'unauthorized') return;
                    this.searchPhotos();
                    this.showError(error, '選定状態更新エラー');
                });
        },
        bulkChangePhotoSelectionStatusForPageSpread(status) {
            monet.NEL.fromArray(this.activePhotos.filter((p) => p.selected))
                .toValidation('写真を選択してください。')
                .cata(
                    (error) => this.showError(error, '写真選定状態変更エラー'),
                    (photos) => {
                        const photo_ids = photos
                            .filter((p) => p.is_selected_for_page_spread != status)
                            .map((p) => p.id)
                            .toArray();
                        if (photo_ids.length == 0) {
                            this.selectAllPhotos(false);
                            return;
                        }
                        http
                            .put('photos/update_selection_status', {
                                photo_ids: photo_ids,
                                status: status,
                            })
                            .then(() => {
                                this.undoMoveStack.push([
                                    {
                                        photo_ids: photo_ids,
                                        status: !status,
                                    },
                                ]);
                                this.searchPhotos();
                            })
                            .catch((error) => {
                                if (error === 'unauthorized') return;
                                this.showError(error, '写真選定状態変更エラー');
                            });
                    }
                );
        },
        undoChangeSelectionStatus() {
            monet.Maybe.fromNull(this.undoMoveStack.pop())
                .toValidation('元に戻せる操作がありません。')
                .cata(
                    (error) => this.showError(error, '写真選定状態変更エラー'),
                    (requests) => {
                        let url, payload;
                        if (requests.length == 1) {
                            url = 'photos/update_selection_status';
                            payload = {
                                photo_ids: requests[0].photo_ids,
                                status: requests[0].status,
                            };
                        } else if (requests.length == 2) {
                            const toBeSelected =
                                requests[0].status == true ? requests[0] : requests[1];
                            const toBeDisselected =
                                requests[0].status == false ? requests[0] : requests[1];

                            url = 'photos/swap';
                            payload = {
                                photo_ids_to_be_selected: toBeSelected.photo_ids,
                                photo_ids_to_be_disselected: toBeDisselected.photo_ids,
                            };
                        } else {
                            this.showError(
                                '写真選定操作取り消し時にエラーが発生しました。\n 以下のメッセージをシステム管理者にお知らせください。' +
                                ':写真選定操作履歴異常',
                                '写真選定操作取消エラー'
                            );
                        }
                        http
                            .put(url, payload)
                            .then(() => this.searchPhotos())
                            .catch((error) => {
                                if (error === 'unauthorized') return;
                                this.showError(error, '写真選定操作取消エラー');
                            });
                    }
                );
        },
        incrementPhotoLabel(photo) {
            const oldLabelNo = photo.label.no;
            const incrementedLabelId = this.labels.find((label) => {
                return label.no == (oldLabelNo != 9 ? oldLabelNo + 1 : 0);
            }).id;
            // クリック連打で更新日時前後しないように更新中は消す
            photo.label_icon = undefined;

            http
                .backGroundRequest('put', 'photos/update/' + photo.id, {
                    name: photo.name,
                    label_id: incrementedLabelId,
                    photo_tags: photo.photo_tags.map((t) => t.tag.id),
                    page_no: photo.page_no,
                    is_selected_for_page_spread: photo.is_selected_for_page_spread,
                    updated_at: photo.updated_at,
                })
                .then((res) => {
                    photo.label = this.labels.find((label) => {
                        return label.id == res.data.label_id;
                    });
                    photo.label_icon =
                        photo.label.no >= 0 && photo.label.no <= 9
                            ? 'mdi-numeric-' + photo.label.no + '-box'
                            : null;
                    photo.updated_at = res.data.updated_at;
                })
                .catch((error) => {
                    if (error === 'unauthorized') return;
                    // 失敗したら元に戻す
                    photo.label = this.labels.find((label) => {
                        return label.no == oldLabelNo;
                    });
                    photo.label_icon =
                        photo.label.no >= 0 && photo.label.no <= 9
                            ? 'mdi-numeric-' + photo.label.no + '-box'
                            : null;
                    this.showError(error, 'ラベル更新エラー');
                });
        },
        downloadPhotos() {
            monet.NEL.fromArray(this.activePhotos.filter((p) => p.selected))
                .toValidation('写真を選択してください。')
                .cata(
                    (error) => this.showError(error, '写真ダウンロードエラー'),
                    (photos) =>
                        http
                            .get(
                                ((url) => {
                                    url = urlUtil.addQueryParamIfDefined(
                                        url,
                                        'with_rectangle',
                                        this.storageData.withRectangle
                                    );
                                    url = urlUtil.addQueryParamIfDefined(
                                        url,
                                        'zip_file_name',
                                        this.storageData.shooting.name
                                    );
                                    photos.forEach(
                                        (p) =>
                                            (url = urlUtil.addQueryParamIfDefined(url, 'ids[]', p.id))
                                    );
                                    return url;
                                })('photos/export')
                            )
                            .then(async (res) => {
                                const preSignedUrl = res.data.url;
                                const jobId = res.data.job_id;

                                this.isDownloading = true;
                                const successCallback = () => { download(preSignedUrl, ''); };

                                awaitJobDone(jobId)
                                    .then(successCallback)
                                    .catch((error) => {
                                        this.showError(
                                            '写真のダウンロードに失敗しました。',
                                            '写真ダウンロードエラー',
                                            error
                                        );
                                    })
                                    .finally(() => {
                                        this.isDownloading = false;
                                    });

                            })
                            .catch((error) => {
                                if (error === 'unauthorized') return;
                                this.showError(
                                    '写真のダウンロードに失敗しました。',
                                    '写真ダウンロードエラー',
                                    error
                                );
                            })

                );
        },
        doOcrProc() {
            monet.NEL.fromArray(this.activePhotos.filter((p) => p.selected))
                .toValidation('写真を選択してください。')
                .cata(
                    (error) => this.showError(error, '札番号リネームエラー'),
                    (photos) =>
                        http
                            .put('photos/ai_ocr_rename', {
                                shooting_id: this.shootingId,
                                photo_ids: photos.map((p) => p.id).toArray(),
                            })
                            .then((res) => {
                                if (res.data.length == 0) {
                                    this.showInfo(
                                        '選択写真の札番号リネームが完了しました。',
                                        '札番号リネーム'
                                    );
                                } else {
                                    this.showError(
                                        '一部の写真の札番号読み取りに失敗しました。 \n選択状態の写真は札番号読み取りに失敗したため、リネームを行っていません。',
                                        '札番号リネームエラー'
                                    );
                                }
                                this.searchPhotos().then(() => {
                                    const allPhotoTabSelected = this.allPhotoTabSelected;
                                    this.activePhotos.forEach((p) => {
                                        if (res.data.includes(p.id)) {
                                            if (allPhotoTabSelected) {
                                                p.uiStatus.selected = true;
                                            } else {
                                                if (p.is_selected_for_page_spread) {
                                                    p.uiStatus.selectedInPageSpread = true;
                                                } else {
                                                    p.uiStatus.selectedInSwapCandidateSuggestion = true;
                                                }
                                            }
                                        }
                                    });
                                });
                            })
                            .catch((error) => {
                                if (error === 'unauthorized') return;
                                this.showError(error, '札番号リネームエラー');
                            })
                );
        },
        doAiProc() {
            monet.NEL.fromArray(this.activePhotos.filter((p) => p.selected))
                .toValidation('写真を選択してください。')
                .cata(
                    (error) => this.showError(error, 'AI処理エラー'),
                    (photos) => {
                        this.showConfirm(
                            '選択した写真にAI処理を行います。作成済みの検出顔情報は上書きされます。　\nよろしいですか？',
                            'AI処理'
                        ).then(() => {
                            http
                                .post('photo_faces/ai_proc', {
                                    album_id: this.album.id,
                                    photo_ids: photos.map((p) => p.id).toArray(),
                                    face_ids: [],
                                }) // 顔検出
                                .then((res) => {
                                    const jobId = res.data.job_id;

                                    awaitJobDone(jobId, true).then(() => {
                                        // 物体・シーン検出
                                        this.showInfo('選択写真のAI処理が完了しました。', 'AI処理');
                                        this.searchPhotos();
                                    }).catch((error) => {
                                        this.showError(error, 'AI処理エラー');
                                    });

                                })
                                .catch((error) => {
                                    if (error === 'unauthorized') return;
                                    this.showError(error, 'AI処理エラー');
                                });
                        });
                    }
                );
        },
        openPhotoDialog(photoId) {
            this.applyChangePhotoId(photoId);
        },
        applyChangePhotoId(photoId) {
            this.storageData.photoId = photoId;
            this.storageData.photoDialog = true;

            this.$nextTick(() => {
                this.$refs.photoDialogVue.initialize();
            });
        },
        applyUpdatePhoto(updatedPhotoId, maybeNextPhotoId) {
            this.fetchPhotos()
                .then(() => {
                    this.storageData.photoId =
                        this.photos.length > 0
                            ? monet.Maybe.fromNull(
                                this.photos.find((p) => p.id === updatedPhotoId)
                            )
                                .catchMap(() =>
                                    maybeNextPhotoId.bind((nextPhotoId) =>
                                        monet.Maybe.fromNull(
                                            this.photos.find((p) => p.id === nextPhotoId)
                                        )
                                    )
                                )
                                .map((p) => p.id)
                                .orSome(this.photos[0].id) // 更新写真、その次の写真、先頭写真の順に探索。
                            : 0; // photos が空なら 0
                    this.$nextTick(() => {
                        this.$refs.photoDialogVue.initialize();
                    });
                })
                .catch((error) => {
                    if (error === 'unauthorized') return;
                    this.showError(
                        '写真の再検索に失敗しました。',
                        '写真再検索エラー',
                        error
                    );
                });
        },
        closePhotoDialog() {
            this.storageData.photoDialog = false;
        },
        openShootingFrequencyDialog() {
            this.storageData.shootingFrequencyDialog = true;

            this.$nextTick(() =>
                this.$refs.shootingFrequencyVue
                    .initialize()
                    .then(() => {
                        this.$refs.shootingFrequencyVue.setShooting(this.shootingId);
                        this.$refs.shootingFrequencyVue.setActiveTab(this.selectedTab);
                    })
                    .catch((error) => {
                        this.closeShootingFrequencyDialog();

                        if (error === 'unauthorized') return;
                        this.showError(
                            '人物頻度確認画面の初期化に失敗しました。',
                            '初期化エラー',
                            error
                        );
                    })
            );
        },
        closeShootingFrequencyDialog() {
            this.storageData.shootingFrequencyDialog = false;
        },
        clickFace(faceId) {
            monet.Maybe.fromNull(
                this.shootingFaces.find((f) => f.id === faceId)
            ).forEach((f) => {
                f.is_highlighting = !f.is_highlighting; // トグル

                const highlightFaceIds = this.shootingFaces
                    .filter((f) => f.is_highlighting)
                    .map((f) => f.id);

                this.photos = this.photos.map((p) => {
                    p.is_highlighted = this.photo_faces.some((pf) =>
                        highlightFaceIds.includes(pf.face_id)
                    );
                    return p;
                }); // 写真のハイライト
            });
        },
        aggregateFrequency(photos) {
            return photos
                .flatMap((p) => p.photo_faces)
                .reduce(
                    (acc, pf) =>
                        monet.Maybe.fromNull(acc.find((x) => x[0].id === pf.face_id)).cata(
                            () => acc.concat([[pf.face, 1]]),
                            (x) =>
                                acc
                                    .filter((x) => x[0].id !== pf.face_id)
                                    .concat([[pf.face, x[1] + 1]])
                        ),
                    []
                ) // photos -> [[face, count]]
                .sort((f, s) => s[1] - f[1]); // 出現回数の降順にソート
        },
        searchFrequencies() {
            if (this.photos.length <= 0) return;

            this.frequencies = [];
            this.bestFrequencies = [];
            this.worstFrequencies = [];

            this.photos = this.photos.map((p) => {
                p.is_highlighted = false;
                return p;
            }); // 写真のハイライト解除

            this.frequencyDisplayLoading = true;
            this.fetchFrequencies()
                .catch((error) => {
                    if (error === 'unauthorized') return;
                    console.log(error);
                    this.showError(
                        '人物頻度の検索に失敗しました。',
                        '人物頻度検索エラー',
                        error
                    );
                })
                .finally(() => {
                    this.frequencyDisplayLoading = false;
                });
        },
        fetchFrequencies() {
            return http
                .backGroundRequest('post', 'photo_faces/shooting_frequency', {
                    album_id: this.albumId,
                    photo_id_list: this.photos.map((p) => {
                        return p.id;
                    }),
                    student_no: this.storageData.personStudentNo,
                    note: this.storageData.personNote,
                    only_selected: !this.allPhotoTabSelected,
                })
                .then((res) => {
                    const maxFrequencyOfWholeAlbum = Math.max.apply(
                        null,
                        res.data.map((f) => f.frequency_of_whole_album)
                    );
                    const maxFrequency = Math.max.apply(
                        null,
                        res.data.map((f) => f.student_frequency)
                    );

                    this.frequencies = res.data.map((f) => {
                        f.album_frequency_rate =
                            (f.frequency_of_whole_album / maxFrequencyOfWholeAlbum) * 100;
                        f.frequency_rate = (f.student_frequency / maxFrequency) * 100;
                        f.is_highlighting = false;
                        return f;
                    });

                    this.constractBothEndsOfFrequencies();

                    this.swapCandidatePhotoIds = [];
                });
        },
        constractBothEndsOfFrequencies() {
            const shouldSplit =
                this.frequencies.length > this.frequencyDisplayCount * 2;
            const sortedFrequencies = this.frequencies.sort((self, other) =>
                this.storageData.frequencySortItem.value === 1
                    ? other.student_frequency - self.student_frequency
                    : other.frequency_of_whole_album - self.frequency_of_whole_album
            );

            this.bestFrequencies = shouldSplit
                ? sortedFrequencies.slice(0, this.frequencyDisplayCount)
                : sortedFrequencies.slice(
                    0,
                    // 小数点切り捨て
                    this.frequencies.length / 2
                );

            this.worstFrequencies = shouldSplit
                ? sortedFrequencies.slice(-this.frequencyDisplayCount)
                : sortedFrequencies.slice(
                    // 小数点切り捨て
                    this.frequencies.length / 2,
                    this.frequencies.length
                );
        },
        searchSwapCandidatePhotos() {
            this.swapCandidatePhotoIds = [];

            const bests = this.bestFrequencies.filter((f) => f.is_highlighting);
            const worsts = this.worstFrequencies.filter((f) => f.is_highlighting);

            (bests.concat(worsts).length > 0 // 人物選択済なら Right、それ以外は Left とする。
                ? monet.Either.of([
                    bests.length > 0 ? 'remove' : 'add',
                    bests.concat(worsts),
                ])
                : monet.Left()
            ).forEach((t) =>
                this.fetchSwapCandidatePhotos(t[0], t[1]).catch((error) => {
                    if (error === 'unauthorized') return;
                    this.showError(
                        '入替候補写真の検索に失敗しました。',
                        '入替候補写真検索エラー',
                        error
                    );
                })
            );
        },
        fetchSwapCandidatePhotos(purpose, faceIds) {
            this.swapCandidateLoading = true;
            return http
                .backGroundRequest(
                    'get',
                    ((url) => {
                        url = urlUtil.addQueryParamIfDefined(
                            url,
                            'shooting_id',
                            this.shootingId
                        );
                        url = urlUtil.addQueryParamIfDefined(url, 'swap_purpose', purpose);
                        url = urlUtil.addQueryParamIfDefined(
                            url,
                            'candidate_num',
                            this.swapCandidateCount
                        );
                        faceIds.forEach(
                            (f) =>
                            (url = urlUtil.addQueryParamIfDefined(
                                url,
                                'face_id_list[]',
                                f.face_id
                            ))
                        );

                        return url;
                    })('photos/swap_candidate')
                )
                .then((res) => {
                    this.swapCandidatePhotoIds = res.data.map((p) => {
                        return p.id;
                    });
                    this.swapCandidatePhotos.forEach((photo) => {
                        photo.uiStatus.selectedInSwapCandidateSuggestion = false;
                    });
                })
                .finally(() => {
                    this.swapCandidateLoading = false;
                });
        },
        swapPhotos() {
            const url = 'photos/swap';
            const toBeSelected = this.swapCandidatePhotos
                .filter((photo) => {
                    return photo.uiStatus.selectedInSwapCandidateSuggestion;
                })
                .map((photo) => {
                    return photo.id;
                });
            const toBeDisselected = this.photosSelectedForPageSpread
                .filter((photo) => {
                    return photo.uiStatus.selectedInPageSpread;
                })
                .map((photo) => {
                    return photo.id;
                });

            http
                .put(url, {
                    photo_ids_to_be_selected: toBeSelected,
                    photo_ids_to_be_disselected: toBeDisselected,
                })
                .then(() => {
                    this.undoMoveStack.push([
                        {
                            photo_ids: toBeSelected,
                            status: false,
                        },
                        {
                            photo_ids: toBeDisselected,
                            status: true,
                        },
                    ]);
                    this.searchPhotos();
                    this.swapConfirmDialog = false;
                })
                .catch((error) => {
                    if (error === 'unauthorized') return;
                    this.showError(error, '写真選定状態変更エラー');
                });
        },
        switchSuggestingSwapCandidateMode() {
            this.suggestingSwapCandidateBase = !this.suggestingSwapCandidateBase;
        },
        clickFaceInBestFrequencies(faceId) {
            this.highlightPhotos(faceId, true);
        },
        clickFaceInWorstFrequencies(faceId) {
            this.highlightPhotos(faceId, false);
        },
        highlightPhotos(faceId, isBestSide) {
            const selfs = isBestSide ? this.bestFrequencies : this.worstFrequencies;
            const others = isBestSide ? this.worstFrequencies : this.bestFrequencies;

            monet.Maybe.fromNull(selfs.find((f) => f.face_id === faceId)).forEach(
                (f) => {
                    f.is_highlighting = !f.is_highlighting; // トグル

                    const highlightFaceIds = selfs
                        .filter((f) => f.is_highlighting)
                        .map((f) => f.face_id);

                    others.map((f) => {
                        f.is_highlighting = false;
                        return f;
                    });

                    this.photos = this.photos.map((p) => {
                        p.is_highlighted = p.photo_faces.some((pf) =>
                            highlightFaceIds.includes(pf.face_id)
                        );
                        return p;
                    }); // 写真のハイライト
                    this.searchSwapCandidatePhotos();
                }
            );
        },
        switchWithRectangleMode() {
            this.storageData.withRectangle = !this.storageData.withRectangle;
        },
        openPhotoUploadDialog() {
            this.storageData.photoUploadDialog = true;
        },
        closePhotoUploadDialog() {
            this.storageData.photoUploadDialog = false;
        },
        openAutoSelectDialog() {
            this.$refs.autoSelectDialogVue.display = true;

            this.$nextTick(() => {
                this.$refs.autoSelectDialogVue.initialize();
            });
        },
        closeAutoSelectedPhotosDialog() {
            this.storageData.autoSelectedPhotosDialog = false;
            this.searchPhotos();
        },
        autoSelectCompleted(result) {
            this.$refs.autoSelectDialogVue.display = false;

            this.storageData.autoSelectResult = result;
            this.storageData.autoSelectedPhotosDialog = true;

            this.$nextTick(() => {
                event.emit('receiveAutoSelectResponse');
            });
        },
        registerPageSpreadMemo() {
            this.updateShooting()
                .then(() => {
                    this.fetchShooting();
                    this.storageData.pageSpreadMemoDialog = false;
                })
                .catch((error) => {
                    if (error === 'unauthorized') return;
                    this.showError(error, 'メモ登録エラー');
                });
        },
        transitToShootings() {
            this.$emit('change-active-component', 'shootingsVue');
        },
    },
};

const download = (href, filename) => {
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', filename);
    link.click();
};
</script>


<style scoped>
.no-underline-breadcrumbs>>>a:not(:hover) {
    text-decoration: none !important;
}
</style>