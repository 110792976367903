import http from '../services/http'

export default {
    debug: true,
    state: {
        user: {},
        factory: {},
        authenticated: false,
    },
    login (loginId, password) {
        return http.post('authenticate', { login_id: loginId, password: password })
            .then(res => {
                this.state.user = res.data.user;
                this.state.authenticated = true;
                return res.data.user.level;
            });
    },
    logout () {
        return http.get('logout')
            .then(res => {
                localStorage.removeItem('jwt-token');
                this.state.authenticated = false;
            });
    },
    setCurrentUser () {
        http.get('me')
            .then(res => {
                this.state.user = res.data.user;
                this.state.authenticated = true;
            })
            .catch(error => {
                this.state.user = {};
                this.state.authenticated = false;
            });
    },
    init () {
        this.setCurrentUser()
    }
}
