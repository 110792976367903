<template>
    <page-container>
        <template slot="top">
            <v-container class="ma-0 pa-0">
                <v-row dense>
                    <v-col cols="2">
                        <v-select
                            v-model="storageData.customerType"
                            :items="customerTypes"
                            label="顧客区分"
                            item-text="name"
                            clearable
                            return-object                        
                            dense
                            outlined
                            hide-details
                            @change="searchAlbums"
                        />
                    </v-col>
                    <v-col cols="2">
                        <v-switch
                            v-model="storageData.onlyUncompleted"
                            label="未完了のみ"
                            dense 
                            hide-details
                            :ripple="false"
                            class="mt-2"
                            @change="searchAlbums"
                        />  
                    </v-col>                  
                </v-row>
            </v-container>
        </template>

        <template slot="middle">
            <v-data-table
                :headers="headers"
                :items="albums"
                :items-per-page="20"
                :footer-props="{
                    'items-per-page-options': [10, 20, 30, 40, 50, -1]
                }"
                dense
                tile
                @click:row="clickRow"
            >
                <template v-slot:item.edit="{ item }">
                    <v-btn 
                        tile 
                        x-small 
                        color="secondary"
                        @click.stop="editAlbum(item)"
                    >
                        編集
                    </v-btn>
                </template>
                <template v-slot:item.delete="{ item }">
                    <v-btn
                        tile 
                        x-small 
                        color="accent"
                        @click.stop="deleteAlbum(item)"
                    >
                        削除
                    </v-btn>
                </template>
            </v-data-table>
        </template>

        <template slot="bottom">
            <v-dialog v-model="storageData.albumDialog" persistent max-width="400">
                <v-form ref="form" lazy-validation>
                    <v-card class="pa-3">
                        <v-container>
                            <v-row>
                                <v-autocomplete
                                    v-model="storageData.album.customer"
                                    :rules="rules.customerRules"
                                    :items="customers"
                                    :disabled="storageData.modeUpdate"
                                    label="顧客"
                                    item-text="name"
                                    return-object
                                />
                            </v-row>
                            <v-row>
                                <v-text-field
                                    v-model="storageData.album.schoolYear"
                                    :rules="rules.shoolYearRules"
                                    :disabled="storageData.modeUpdate"                                        
                                    label="年度【必須】"
                                    type="number"
                                    min="1900"
                                    max="3000"
                                    required
                                    return-object
                                />
                            </v-row>
                            <v-row>
                                <v-text-field
                                    v-model="storageData.album.name"
                                    :rules="rules.nameRules"
                                    label="アルバム名【必須】"
                                    counter="30"
                                    required
                                />
                            </v-row>
                        </v-container>
                        <v-container class="px-3">
                            <v-row justify="space-between">
                                <cancel-dialog-button width="100" @click="closeAlbumDialog">
                                    キャンセル
                                </cancel-dialog-button>
                                <proceed-dialog-button width="100" @click="registerAlbum">
                                    登録
                                </proceed-dialog-button>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-form>
            </v-dialog>
        </template>
    </page-container>
</template>

<script>
import event from '../../utils/event';
import http from '../../services/http';
import urlUtil from '../../utils/url';
import leaveConform from '../../mixins/leave-confirm-mixin';
import monet from 'monet';

export default {
    mixins: [leaveConform],
    data () {
        return {
            viewID: 'AL01-A00',
            storageData: {
                isInitialized: false,
                customerType: undefined,
                onlyUncompleted: true,
                album: {
                    id: 0,
                    customerId: 0,
                    schoolYear: undefined,
                    name : '',
                    isCompleted: 0,
                    updatedAt: undefined,
                },
                modeUpdate: false,
                albumDialog: false,
            },
            headers: [
                {
                    text: '顧客区分',
                    value: 'customer.customer_type.name',
                    width: 100, 
                },
                { 
                    text: '顧客名', 
                    value: 'customer.name',
                },
                {
                    text: '年度', 
                    value: 'formated_school_year',
                    width: 100, 
                },
                { 
                    text: 'アルバム名', 
                    value: 'name', 
                },
                { 
                    text: '状態', 
                    value: 'formated_is_completed',
                    width: 100,  
                },
                { 
                    text: '編集', 
                    value: 'edit', 
                    align: 'center', 
                    sortable: false, 
                    width: 64, 
                },
                { 
                    text: '削除', 
                    value: 'delete', 
                    align: 'center', 
                    sortable: false, 
                    width: 64, 
                },
            ],
            rules: {
                customerRules: [
                    v => !!v || '選択してください。',
                ],
                shoolYearRules: [
                    v => !!v || '入力してください。',
                    v => v && Math.round(v) == v || '整数を入力してください。',
                    v => v && (v >= 1900 && v <= 3000) || '西暦で入力してください。',
                ],
                nameRules:[
                    v => !!v || '入力してください。',
                    v => !!v && v.length <= 30 || '30文字以内で入力してください。',
                ], 
                directoryNameRules:[
                    v => !!v || '入力してください。',
                    v => !!v && v.length <= 60 || '60文字以内で入力してください。',
                ], 
                rootPathRules:[
                    v =>  !!v || '入力してください。',
                ],
            },
            httpState: http.state,
            albums: [],
            customerTypes: [],
            customers: [],
            systemDate: undefined,
        };
    },
    created () {
        this.$root.init();

        event
            .on('saveStorage', () => {
                if (this.storageData.isInitialized == true) {
                    this.saveStorageData();
                }
            });
    },
    mounted() {
        this.$root.setBreadcrumbs([
            {
                text: 'アルバム一覧',
                disabled: true,
                href: '',
            },
        ]); // ヘッダ部のパンくずリストを設定する。

        this.$root.setHeaderActions([
            {
                name: 'アルバム追加',
                consumer: () => this.storageData.albumDialog = true,
                icon: 'add_box',
            },
        ]); // ヘッダアクションを設定する。

        this.restoreStorageData();

        Promise
            .all([
                this.getSystemDate(),
                this.fetchCustomerTypes(),
                this.fetchCustomers(),
                this.fetchAlbums(),
            ]) 
            .then(() => this.storageData.isInitialized = true )
            .catch(error => {
                if (error === 'unauthorized') return;
                this.showError('初期化処理に失敗しました。', '初期化エラー', error);
            });
    },
    beforeDestroy() {
        event.off('saveStorage');
    },
    methods: {
        getSystemDate () {
            return http
                .get('temporal/system_date')
                .then(res => {
                    this.systemDate = this.$moment(res.data.value);
                    this.storageData.album.schoolYear = this.systemDate.format('YYYY');
                });
        },        
        fetchAlbums () {
            return http.get(this.toFetchAlbumsUrl())
                .then(res => this.albums = res.data
                    .map(a => {
                        a.formated_school_year = this.formatShoolYear(a.school_year);
                        a.formated_is_completed = this.formatCompletedStatus(a.is_completed);
                        return a;
                    }));
        },
        toFetchAlbumsUrl () {
            const url = urlUtil.addQueryParamIfDefined('albums', 'only_uncompleted', this.storageData.onlyUncompleted);

            return monet.Maybe
                .fromNull(this.storageData.customerType)
                .map(type => urlUtil.addQueryParamIfDefined(url, 'customer_type_id', type.id))
                .orSome(url);
        },
        fetchCustomerTypes () {
            return http.get('customer_types').then(res => this.customerTypes = res.data);
        },
        fetchCustomers () {
            return http.get('customers').then(res => this.customers = res.data);
        },
        searchAlbums () {
            this.fetchAlbums()
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, '検索に失敗しました。');
                });
        },
        editAlbum (album) {
            http
                .get('albums/' + album.id + '/edit')
                .then(res => {
                    this.storageData.album.id = res.data.id;
                    this.storageData.album.customer = res.data.customer;
                    this.storageData.album.schoolYear = res.data.school_year;
                    this.storageData.album.name = res.data.name;
                    this.storageData.album.isCompleted = res.data.is_completed;
                    this.storageData.album.updatedAt = res.data.updated_at;

                    this.confirmLeaveReset(['storageData', 'album']);
                    
                    this.storageData.modeUpdate = true;
                    this.storageData.albumDialog = true;
                })
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, 'データの取得に失敗しました。');
                });
        },
        registerAlbum () {
            if (!this.$refs.form.validate()) return; // 入力チェックに違反したら以降の処理をしない。

            (this.storageData.modeUpdate ? this.updateAlbum : this.createAlbum) // createAlbum or updateAlbum
                .apply()
                .then(() => {
                    this.confirmLeaveReset(null);
                    this.searchAlbums();
                    this.closeAlbumDialog();
                })
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, '登録に失敗しました。');
                });
        },
        createAlbum () {
            return http
                .post('albums/store', {
                    customer_id: this.storageData.album.customer.id,
                    school_year: this.storageData.album.schoolYear,
                    name: this.storageData.album.name,
                    is_completed: 0,
                });
        },
        updateAlbum () {
            return http
                .put('albums/' + this.storageData.album.id, {
                    name: this.storageData.album.name,
                    updated_at: this.storageData.album.updatedAt,
                });
        },
        async deleteAlbum(album) {
            const confirmMessage = album.is_completed 
                ? 'アルバム「' + album.name + '」を削除します。　\nよろしいですか？'
                : 'アルバム「' + album.name + '」を削除します。　\n制作が未完了のアルバムですが、よろしいですか？';

            if (!await this.showConfirm(confirmMessage, 'アルバム削除')
                .then(() => true)
                .catch(() => false)
            ) {
                return;
            }

            var url = urlUtil.addQueryParamIfDefined('albums', 'album_id', album.id);
            // 手動削除時は作成完了後保持期間を無視
            url = urlUtil.addQueryParamIfDefined(url, 'ignore_prebation', true);

            return http
                .delete(url)
                .then(() => {
                    this.showInfo('アルバム削除に成功しました', 'アルバム削除');
                    this.fetchAlbums();
                })
                .catch((error) => {
                    if (error === 'unauthorized') return;
                    this.showError(error, 'アルバム削除に失敗しました');
                });
        },
        async closeAlbumDialog () {
            if (await this.confirmLeave() === false) return;

            this.storageData.dialog = false;
            this.$refs.form.reset();

            setTimeout(() => {
                this.initStorageData();
                this.confirmLeaveReset(['storageData', 'album']);
            }, 10);
        },
        initStorageData() {
            this.storageData.albumDialog = false;
            this.storageData.modeUpdate = false;

            this.storageData.album.id = 0;
            this.storageData.album.customerId = 0;
            this.storageData.album.schoolYear = this.systemDate.format('YYYY');
            this.storageData.album.name = '';
            this.storageData.album.updatedAt = undefined;
        },
        clickRow (row, column) {
            this.$router.push('/inhouse/albums/album?album_id=' + row.id);
        },
    },
};
</script>