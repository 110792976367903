<template>
    <v-dialog
        v-model="innerValue"
        persistent
        max-width="800"
        max-height="800"
    >
        <v-card :color="$root.getTheme().background">
            <page-container class="ma-0 pa-0">
                <template slot="middle">
                    <v-container class="ma-0 pa-0">
                        <v-row class="pa-1">
                            <v-col cols="12">
                                この操作により、このページ構成の人物頻度が下記のとおり変化します。よろしければ「実行」ボタンを押してください。
                            </v-col>
                        </v-row>
                        <v-row class="pa-1">
                            <v-col cols="6" class="pa-1">
                                <v-card>
                                    <v-card-title
                                        dense
                                        style="background: #EF4E5B; #color: #FFFFFF;"
                                    >
                                        増加
                                    </v-card-title>
                                    <v-card-text
                                        class="ma-0 pa-0 overflow-y-auto"
                                        style="max-height: 530px"
                                    >
                                        <v-container>
                                            <v-row>
                                                <v-col
                                                    v-for="x in addedPeople"
                                                    :key="x[0].id"
                                                    :cols="4"
                                                >
                                                    <photo-face-card
                                                        :id="x[0].id"
                                                        :face="x[0]"
                                                        :image-src="
                                                            x[0]
                                                                .face_image_path
                                                        "
                                                        :is-highlighting="
                                                            x[0]
                                                                .is_highlighting
                                                        "
                                                    />
                                                    <div
                                                        class="text-center"
                                                        style="background: #EF4E5B; #color: #FFFFFF;"
                                                    >
                                                        {{ "+" + x[1] }}
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="6" class="pa-1">
                                <v-card>
                                    <v-card-title
                                        style="background: #0F68B1; #color: #FFFFFF;"
                                    >
                                        減少
                                    </v-card-title>
                                    <v-card-text
                                        class="ma-0 pa-0 overflow-y-auto"
                                        style="max-height: 530px"
                                    >
                                        <v-container>
                                            <v-row>
                                                <v-col
                                                    v-for="x in rejectedPeople"
                                                    :key="x[0].id"
                                                    :cols="4"
                                                >
                                                    <photo-face-card
                                                        :id="x[0].id"
                                                        :face="x[0]"
                                                        :image-src="
                                                            x[0]
                                                                .face_image_path
                                                        "
                                                        :is-highlighting="
                                                            x[0]
                                                                .is_highlighting
                                                        "
                                                    />
                                                    <div
                                                        class="text-center"
                                                        style="background: #0F68B1; #color: #FFFFFF;"
                                                    >
                                                        {{ "-" + x[1] }}
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </template>
                <template slot="bottom">
                    <v-container class="px-3">
                        <v-row justify="space-between">
                            <cancel-dialog-button
                                width="100"
                                @click="innerValue = false"
                            >
                                キャンセル
                            </cancel-dialog-button>
                            <proceed-dialog-button
                                :disabled="!executable"
                                width="100"
                                @click="$emit('executed')"
                            >
                                実行
                            </proceed-dialog-button>
                        </v-row>
                    </v-container>
                </template>
            </page-container>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    components: {
        'photo-face-card': require('../PhotoFaces/PhotoFaceCard.vue').default,
    },
    props: {
        addedPeople: {
            type: Array,
            default: () => [],
        },
        rejectedPeople: {
            type: Array,
            default: () => [],
        },
        executable: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        innerValue: {
            get: function() {
                return this.value;
            },
            set: function(val) {
                this.$emit('input', val);
            }
        }
    }
};
</script>