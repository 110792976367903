<template>
    <v-dialog 
        v-model="display"
        width="auto" 
        persistent
    >
        <v-card class="pa-3">
            <v-container fluid>
                <v-row>
                    <v-col cols="3">
                        <span>顔マスタ補足情報一覧</span>
                        <v-toolbar dense color="silver">  
                            <input
                                ref="file_input"
                                style="display: none"
                                type="file"
                                @change="selectedFile()"
                                @click="clearSelectedFile();"
                            />
                            <icon-with-text
                                text="CSVファイル読込"
                                icon
                                icon-name="mdi-playlist-plus"
                                @click="btnclick" 
                            />
                        </v-toolbar>
                        <v-container style="max-height: 60vh" class="overflow-y-auto">
                            <v-data-table
                                :headers="headers"
                                :items="storageData.faceInfo"
                                hide-default-footer
                                disable-pagination
                                :options.sync="data_table_options"
                                dense
                                tile
                            >
                                <template v-slot:item="{ item, isSelected}">
                                    <tr :class="tableRowClass(item, isSelected)" @click="clickInfoRow(item)">
                                        <td v-for="header in headers" :key="header.value">
                                            {{ item[header.value] }}
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-container>
                    </v-col>
                    <v-col cols="5">
                        <span>顔マスタ(補足情報割当前)</span>
                        <v-toolbar dense color="silver">
                            <v-switch
                                v-model="beforeAssginedFacesSwitch"
                                hide-details
                                label="補足情報が未入力な顔マスタのみ"
                                @change="storageData.beingSelectedFace = undefined;"
                            />
                        </v-toolbar>
                        <v-container style="max-height: 60vh" class="overflow-y-auto">
                            <v-row v-if="beforeAssginedFacesSwitch" no-gutters>
                                <v-col v-for="face in filterFace(storageData.faces)"
                                       :key="face.no" cols="2"
                                >
                                    <v-container :class="faceContainerClass(face)"
                                                 @click="selectFace(face, $event)"
                                    >     
                                        <v-row dense align="center" justify="center">
                                            <span>{{ face.no }}</span>
                                        </v-row>
                                        <v-row dense>
                                            <v-img
                                                contain
                                                height="100"
                                                :src="face.face_image_path"
                                            />
                                        </v-row>
                                    </v-container>
                                </v-col>
                            </v-row>
                            <v-row v-else no-gutters>
                                <v-col v-for="face in storageData.faces"
                                       :key="face.no" cols="2"
                                >
                                    <v-container :class="faceContainerClass(face)"
                                                 @click="selectFace(face, $event)"
                                    >     
                                        <v-row dense align="center" justify="center">
                                            <span>{{ face.no }}</span>
                                        </v-row>
                                        <v-row dense>
                                            <v-img
                                                contain
                                                height="100"
                                                :src="face.face_image_path"
                                            />
                                        </v-row>
                                    </v-container>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                    <v-col cols="1" align-self="center" align="center">
                        <v-container>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn slot="activator" large icon class="mb-2" @click="assignInfo()" v-on="on">
                                        <v-icon>keyboard_arrow_right</v-icon>
                                    </v-btn>
                                </template>
                                <span>顔マスタに補足情報を割当</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn slot="activator" large icon class="mt-2" @click="detachInfo()" v-on="on">
                                        <v-icon>keyboard_arrow_left</v-icon>
                                    </v-btn>
                                </template>
                                <span>”顔マスタの補足情報割当を解除</span>
                            </v-tooltip>
                        </v-container>
                    </v-col>
                    <v-col cols="3">
                        <span>顔マスタ(補足情報割当済)</span>
                        <v-toolbar dense color="silver" />
                        <v-container style="max-height: 60vh" class="overflow-y-auto">
                            <v-row no-gutters>
                                <v-col v-for="face in storageData.assignedFaces"
                                       :key="face.no" cols="4"
                                >
                                    <v-container :class="assignedFaceContainerClass(face)" @click="selectAssignedFace(face, $event)">     
                                        <v-row dense align="center" justify="center">
                                            <span>{{ face.no }}</span>
                                        </v-row>
                                        <v-row dense>
                                            <v-img
                                                :src="face.face_image_path"
                                            />
                                        </v-row>
                                        <v-row dense align="center" justify="center">
                                            <span>{{ face.assigned_student_no }}</span>
                                        </v-row>
                                    </v-container>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>

            <v-container fluid>
                <v-row dense justify="space-between">
                    <cancel-dialog-button width="100" @click="closeDialog">
                        キャンセル
                    </cancel-dialog-button>
                    <v-spacer />
                    <proceed-dialog-button width="150" @click="registerFaceInfo">
                        登録
                    </proceed-dialog-button>
                </v-row>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
import event from '../../utils/event';
import http from '../../services/http';
import urlUtil from '../../utils/url';
import leaveConform from '../../mixins/leave-confirm-mixin';

export default {
    components: {
        'icon-with-text': require('../Icons/IconWithText.vue').default,
    },
    mixins: [leaveConform],
    props: {
        albumId: {
            default: 0,
            type: [Number, String],
        },
        closeCallback: {
            default: () => {},
            type: Function
        },
    },
    data () {
        return {
            viewID: '',
            storageData: {
                isInitialized: false,
                faces: [],
                assignedFaces: [],
                beingSelectedFace: undefined,
                beingSelectedInfo: undefined,
                beingSelectedAssignedFace: [],
                excelPath: undefined,
                modeUpdate: false,
                faceInfo: [],
                assignedFaceInfo: [],
            },
            httpState: http.state,
            albums: [],
            faces: [],
            display: false,
            beforeAssginedFacesSwitch: true,
            headers: [
                {
                    text: '出席番号',
                    value: 'student_no',
                },
                { 
                    text: '性別', 
                    value: 'sex',
                },
                {
                    text: '個人メモ', 
                    value: 'note',
                },
            ],
            data_table_options: {
                sortBy: ['index'],
                sortDesc: [false],
                mustSort: true,
            },
        };
    },
    mounted() {
        event
            .on('saveStorage', () => {
                if (this.storageData.isInitialized) {
                    this.saveStorageData();
                }
            });
    },
    beforeDestroy() {
        event.off('saveStorage');
    },
    methods:{
        async closeDialog() {
            if (this.storageData.assignedFaces.length != 0) {
                var self = this;
                await this.showConfirm('保存されていない変更内容は破棄されます。\nよろしいですか？', '保存されていません')
                    .then(() => self.display = false)
                    .catch(() => {return;});
            } else {
                this.display = false;
            }
        },
        initialize() {
            return Promise.all([
                this.fetchFaces(),
                this.initSelection(),
            ])
                .then(() => {
                    this.storageData.isInitialized = true;
                    this.confirmLeaveReset(['storageData']);
                })
                .catch(error => {
                    if(error === 'unauthorized') return;
                    this.showError('初期化処理に失敗しました。', '初期化エラー', error);
                });
        },
        initSelection() {
            this.storageData.assignedFaces = [];
            this.storageData.beingSelectedFace = undefined;
            this.storageData.beingSelectedInfo = undefined;
            this.storageData.beingSelectedAssignedFace = [];
        },
        assignInfo() {
            if (this.storageData.beingSelectedFace == undefined || this.storageData.beingSelectedInfo == undefined) {
                alert('顔マスタと補足情報を選択してください');
                return;
            }

            var face_id = this.storageData.beingSelectedFace.id;
            var index = this.storageData.faces.findIndex(({id}) => id === face_id);
            this.storageData.faces.splice(index, 1);

            var assigned_face = this.storageData.beingSelectedFace;
            assigned_face.assigned_student_no = this.storageData.beingSelectedInfo.student_no;
            assigned_face.assigned_sex = this.storageData.beingSelectedInfo.sex;
            assigned_face.assigned_note = this.storageData.beingSelectedInfo.note;
            this.storageData.assignedFaces.push(assigned_face);
            // 選択状態にする
            this.storageData.beingSelectedAssignedFace.push(assigned_face);

            var selected_info = this.storageData.beingSelectedInfo;
            var info_index = this.storageData.faceInfo.findIndex(({student_no}) => student_no === selected_info.student_no);
            this.storageData.faceInfo.splice(info_index, 1);
            this.storageData.assignedFaceInfo.push(selected_info);

            this.storageData.beingSelectedFace = undefined;
            this.storageData.beingSelectedInfo = undefined;
        },
        detachInfo() {
            this.storageData.beingSelectedAssignedFace.forEach(selectedFace => {
                var face_id = selectedFace.id;
                var index = this.storageData.assignedFaces.findIndex(({id}) => id === face_id);
                this.storageData.assignedFaces.splice(index, 1);

                var selected_student_no = selectedFace.assigned_student_no;
                var info_index = this.storageData.assignedFaceInfo.findIndex(({student_no}) => student_no === selected_student_no);
                var selected_info = this.storageData.assignedFaceInfo[info_index];
                this.storageData.assignedFaceInfo.splice(info_index, 1);

                this.storageData.faces.push(selectedFace);
                this.storageData.faceInfo.push(selected_info);

                this.storageData.faces.sort((a, b) => 
                    a.no - b.no
                );

                // 選択状態にする
                // 補足情報無しのみ表示設定かつ補足情報があった場合は選択状態にしない
                if (!this.beforeAssginedFacesSwitch || this.isNotAssignedInfo(selectedFace)) {
                    this.storageData.beingSelectedFace = selectedFace;
                }
                this.storageData.beingSelectedInfo = selected_info;
            });

            this.storageData.beingSelectedAssignedFace = [];
        },
        clickInfoRow(item) {
            if (this.storageData.beingSelectedInfo == item) {
                this.storageData.beingSelectedInfo = undefined;
            } else {
                this.storageData.beingSelectedInfo = item;
            }
        },
        selectFace(face, event) {
            if (this.storageData.beingSelectedFace != undefined && this.storageData.beingSelectedFace.id == face.id) {
                this.storageData.beingSelectedFace = undefined;
            } else {
                this.storageData.beingSelectedFace = face;
            }
        },
        selectAssignedFace(face, event) {
            if (this.storageData.beingSelectedAssignedFace.includes(face)) {
                var index = this.storageData.beingSelectedAssignedFace.findIndex(({id}) => id === face.id);
                this.storageData.beingSelectedAssignedFace.splice(index, 1);
            } else {
                this.storageData.beingSelectedAssignedFace.push(face);
            }
        },
        tableRowClass (item, isSelected) {
            if (this.storageData.beingSelectedInfo == item) {
                return('red');
            } else {
                return(' ');
            }
        },
        faceContainerClass(face) {
            if (this.storageData.beingSelectedFace != undefined && this.storageData.beingSelectedFace.id == face.id) {
                return('red');
            } else {
                return(' ');
            }
        },
        assignedFaceContainerClass(face) {
            if (this.storageData.beingSelectedAssignedFace.includes(face)) {
                return('red');
            } else {
                return(' ');
            }
        },
        readFaceInfoExcel (excel) {
            var reader = new FileReader();
            var self = this;

            reader.onerror = function() {
                alert('ファイル読み取りに失敗しました');
            };
            reader.onload = function() {
                var result = [];
                var tmp = reader.result.split('\n');
                console.log(tmp);
            
                // 一行目は読まない
                for(var i=1;i<tmp.length;++i) {
                    if (tmp[i] == '') {
                        continue;
                    }

                    var tmp_array = {};
                    tmp_array['student_no'] = tmp[i].split(',')[0];
                    tmp_array['sex'] = tmp[i].split(',')[1];
                    tmp_array['note'] = tmp[i].split(',')[2];
                    tmp_array['index'] = i - 1;

                    var errors = self.validateCsvInput(tmp_array);
                    if (errors.length != 0) {
                        self.showError(errors.join('\n'), i + '行目読み込み中にエラー' );
                        return;
                    }

                    result.push(tmp_array);
                }

                var unique = [...new Set(result.map(a => a.student_no))];
                if (unique.length != result.length) {
                    alert('出席番号に重複が存在します');
                    return;
                }

                self.storageData.faceInfo = result;
            };

            reader.readAsText(excel, 'Shift_JIS');
        },
        validateCsvInput(tmp_array) {
            const errors = [];
            if (tmp_array['student_no'].length > 10 ) {
                errors.push('出席番号欄は10文字以内で入力してください');
            }
            if (!['男性', '女性'].includes(tmp_array['sex'])) {
                errors.push('性別欄には「男性」、「女性」のどちらかを入力してください');
            }
            return errors;
        },
        fetchFaces () {
            var url = urlUtil.addQueryParamIfDefined('faces', 'album_id', this.albumId);
            console.log(url);

            http
                .get(url)
                .then(res => 
                {
                    this.storageData.faces = res.data;
                })
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, '顔マスタ取得エラー');
                });
        },
        registerFaceInfo() {
            if (!this.storageData.assignedFaces.length) {
                return;
            }

            var faces = [];
            this.storageData.assignedFaces.forEach(face => {
                var face_array = {};
                face_array['id'] = face.id;
                face_array['student_no'] = face.assigned_student_no;
                face_array['sex'] = face.assigned_sex;
                face_array['note'] = face.assigned_note;
                faces.push(face_array);
            });

            http
                .put('faces/bulk', {
                    faces: faces
                })
                .then(() => {
                    alert('補足情報の付与に成功しました'),
                    this.initSelection(),
                    event.emit('updatedFaces');
                    this.$emit('doneSuccessfully');
                    this.closeDialog();
                })
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, '顔マスタ情報登録エラー。');
                });
        },
        btnclick() {
            this.$refs.file_input.click();
        },
        filterFace(faces) {
            return faces.filter(this.isNotAssignedInfo);
        },
        isNotAssignedInfo(face) {
            return !face.student_no && !face.sex && !face.note;
        },
        selectedFile() {
            const file = this.$refs.file_input.files[0];
            if (!file) {
                return;
            }
            else {
                console.log(file);
                this.readFaceInfoExcel(file);
            }
        },
        clearSelectedFile() {
            this.$refs.file_input.value = null;
        },
        clickRow() {
            console.log('clickRow');
        },

    },
};
</script>