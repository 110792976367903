<template>
    <div>
        <v-select 
            v-model="dummySelected"
            :label="buttonText"
            dense
            outlined
            hide-details
            :hide-selected="true"
            :items="dummyItems"
            item-text="text"
            :style="{ width: width, height:height }"
            :clearable="isFaceSelected && !dialogDisplayed"
            :menu-props="{ disabled: true }"
            @click:clear.stop="resetFaceSelect"
            @click.stop="toggleDisplay"
        />
        
        <v-dialog
            ref="vDialog"
            hide-overlay
            :close-on-content-click="false"
            scrollable
            :width="computedWidth"
            :position-x="positionX"
            :position-y="positionY"
            transition="slide-y-transition"
        >
            <v-card
                :height="computedHeight"
                :width="computedWidth"
                outlined
                elevation="24"
            >
                <v-card-text>
                    <v-container :v-if="sortedFaces.length != 0">
                        <v-row class="ma-0 p-0" dense>
                            <v-col
                                v-for="f in sortedFaces"
                                :key="f.id"
                                cols="1"
                            >
                                <v-container :style="{'background-color': f.is_selected ? '#228b22' : $root.getTheme().background}"
                                             class="ma-0 pt-0 pb-1"
                                             @click="selectFace(f)"
                                >
                                    <v-row justify="center" class="ma-0 pa-0 text-center" dense>
                                        <v-col cols="12" class="ma-0 pa-0 text-center">
                                            {{ f.no }}
                                        </v-col>
                                    </v-row>
                                    <v-row justify="center" class="ma-0 pa-0" dense>
                                        <v-col cols="12" class="ma-0 pa-0 flex-center">
                                            <v-img
                                                :src="f.face_image_path"
                                                contain
                                                height="80"
                                                width="60"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-col>
                            <v-spacer />
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-container>
                        <v-row dense justify="space-between">
                            <cancel-dialog-button width="100" @click="() => {resetFaceSelect();toggleDisplay()}">
                                絞り込み解除
                            </cancel-dialog-button>
                            <v-spacer />
                            <proceed-dialog-button width="150" @click="() => {applyFaceSelect();toggleDisplay()}">
                                絞り込む
                            </proceed-dialog-button>
                        </v-row>
                    </v-container>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: {
        faces: {
            type: Array,
            default: () => []
        },
        selectedFaces: {
            type: Array,
            default: () => [],
        },
        positionY: {
            type: Number,
            default: 0,
        },
        width: {
            type: String,
            default:'200px',
        },
        height: {
            type: String,
            default:'40px',
        }
    },
    data () {
        return {
            position_x: 0,
            display: false,
            selectedFaceIdsAtOpened: [],
            selected: null,
            applying: false,
            dummyItems: [{value: 0, text:'絞り込み中'}]
        };
    },
    computed: {
        computedWidth: function () {
            return innerWidth * 0.6;
        },
        computedHeight: function () {
            return innerHeight * 0.6;
        },
        sortedFaces: function() {
            const faces_copy = this.faces.concat();
            return faces_copy
                .sort((a, b) => {return a.no - b.no;})
                .map(f => {
                    f.is_selected = this._emitter_selectedFaces.map(sf => sf.id).includes(f.id);
                    return f;
                });
        },
        positionX: function () {
            return innerWidth * 0.2;
        },
        // positionY: function () {
        //     return this.position_y;
        // },
        _emitter_selectedFaces: {
            get: function() {
                return this.selectedFaces.concat();
            },
            set: function(value) {
                this.$emit('selected-faces-changed', value);
            },
        },
        isFaceSelected: function() {
            return this._emitter_selectedFaces.length > 0;
        },
        buttonText: function () {
            return  this.applying ? '絞り込み中' : '顔で絞り込み';
        },
        hasFaceSelectChanged: function () {
            return JSON.stringify(this.selectedFaceIdsAtOpened) != JSON.stringify(this._emitter_selectedFaces.map(f => f.id));
        },
        dialogDisplayed:function () {
            return this.$refs.vDialog ? this.$refs.vDialog.isActive : false;
        },
        dummySelected: { 
            get: function() {
                return this.applying ? {value:0, text: '絞り込み中'} : null;
            },
            set: function() {
                return;
            }
        }
    },
    mounted() {
    },
    methods: {
        applyFaceSelect () {
            this.$emit('face-selected');
            if (this.isFaceSelected) {
                this.applying = true;
            }
        },
        selectFace (selectedFace) {
            if (this._emitter_selectedFaces.includes(selectedFace)) {
                this._emitter_selectedFaces = this._emitter_selectedFaces.filter(f => f != selectedFace);
            } else {
                this._emitter_selectedFaces = this._emitter_selectedFaces.concat(selectedFace);
            }
        },
        resetFaceSelect () {
            this._emitter_selectedFaces = [];
            if (this.applying) {
                this.applyFaceSelect();
                this.applying = false;
            }
        },
        toggleDisplay () {
            this.$refs.vDialog.isActive = !this.$refs.vDialog.isActive;
        }
    },
};

</script>

<style scoped>
div >>> .v-select__selection.v-select__selection--comma{
    color: #EF4E5B !important;
}
</style>