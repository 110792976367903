<template>
    <v-btn class="mx-1" tile :width="$attrs.width ? $attrs.width : 100" v-bind="$attrs" @click.stop="$emit('click')">
        <slot />
    </v-btn>
</template>

<script>
export default {
    
};
</script>