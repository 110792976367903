<template>
    <page-container>
        <template slot="top" />        
        <template slot="middle">
            <v-container v-if="!!storageData.setting.id" fluid>
                <v-row>
                    <v-col>
                        <v-card>
                            <v-card-title class="text-md-subtitle-2">
                                制作完了アルバムのデータ自動削除設定
                            </v-card-title>
                            <v-card-text>
                                <v-container fluid>
                                    <v-row dense>
                                        <v-col md="2">
                                            <v-autocomplete
                                                v-model="storageData.setting.deleteCycle"
                                                :items="deleteCycles"
                                                label="自動削除周期"
                                                :rules="rules.deleteCyclesRules"
                                                dense
                                                @change="changeDeleteCycle"
                                            />
                                        </v-col>
                                        <v-col md="1">
                                            <v-autocomplete
                                                v-model="storageData.setting.deleteMonth"
                                                :items="months"
                                                :disabled="storageData.setting.deleteCycle != '年次'"
                                                label="削除月"
                                                :rules="rules.deleteMonthRules"
                                                dense
                                            />
                                        </v-col>
                                        <v-col md="1">
                                            <v-autocomplete
                                                v-model="storageData.setting.deleteDay"
                                                :items="days"
                                                :disabled="storageData.setting.deleteCycle == '日次'"
                                                label="削除日"
                                                :rules="rules.deleteDayRules"
                                                dense
                                            />
                                        </v-col>
                                        <v-col md="1">
                                            <v-menu
                                                ref="menu"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                :return-value.sync="storageData.setting.deleteTime"
                                                lazy
                                                transition="scale-transition"
                                                offset-y
                                                full-width
                                                max-width="450px"
                                                min-width="450px"
                                            >
                                                <template #activator="{ on }">
                                                    <v-text-field
                                                        v-model="storageData.setting.deleteTime"
                                                        label="削除時刻"
                                                        prepend-icon="access_time"
                                                        readonly
                                                        :rules="rules.deleteTimeRules"
                                                        dense
                                                        v-on="on"
                                                    />
                                                </template>
                                                <v-time-picker
                                                    v-model="storageData.setting.deleteTime"
                                                    full-width
                                                    @change="$refs.menu.save(storageData.setting.deleteTime)"
                                                />
                                            </v-menu>
                                        </v-col>
                                        <v-col md="2">
                                            <v-text-field
                                                v-model="storageData.setting.probationDays"
                                                label="制作完了後データ保持日数"
                                                :rules="rules.probationDaysRules"
                                                return-object
                                                type="number"
                                                min="1"
                                                max="120"
                                                required
                                                dense
                                            />
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-card>
                            <v-card-title class="text-md-subtitle-2">
                                連写判定設定
                            </v-card-title>
                            <v-card-text>
                                <v-container fluid>
                                    <v-row dense>
                                        <v-col md="1">
                                            <v-text-field
                                                v-model="storageData.setting.burstIntervalMillisecThreshold"
                                                label="連写間隔の閾値"
                                                :rules="rules.burstIntervalMillisecThresholdRules"
                                                type="number"
                                                min="10"
                                                max="990"
                                                step="10"
                                                suffix="ミリ秒"
                                                dense
                                            />
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-row justify="end">
                            <proceed-dialog-button width="100" @click="registerSetting">
                                登録
                            </proceed-dialog-button>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </template>
        <template slot="bottom" />
    </page-container>
</template>

<script>
import event from '../../utils/event';
import http from '../../services/http';
import urlUtil from '../../utils/url';
import leaveConform from '../../mixins/leave-confirm-mixin';
import monet from 'monet';

export default {
    mixins: [leaveConform],
    data () {
        return {
            viewID: '',
            storageData: {
                isInitialized: false,
                setting: {
                    id: 1,
                    deleteCycle: '年次',
                    deleteMonth: 0,
                    deleteDay: 0,
                    deleteTime: '',
                    probationDays: 0,
                    burstIntervalMillisecThreshold: 0,
                    updatedAt: undefined,
                },
                modeUpdate: false,
                customerDialog: false,
            },
            rules: {
                deleteCyclesRules: [
                    v => !!v || '選択してください。',
                ],
                deleteMonthRules: [
                    v => this.storageData.setting.deleteCycle != '年次' || !!v || '選択してください。',
                ],
                deleteDayRules: [
                    v => this.storageData.setting.deleteCycle == '日次' || !!v || '選択してください。',
                ],
                deleteTimeRules: [
                    v => !!v || '選択してください。',
                ],
                probationDaysRules: [
                    v => !!v || '入力してください。',
                    v => v && Math.round(v) == v || '整数値を入力してください。',
                    v => v && (v >= 1 && v <= 120) || '1～120の範囲で入力してください。',
                ],
                burstIntervalMillisecThresholdRules: [
                    v => !!v || '入力してください。',
                    v => v && Math.round(v) == v || '整数値を入力してください。',
                    v => v && (v >= 10 && v <= 990) || '10～990の範囲で入力してください。',
                ],
            },
            httpState: http.state,
            deleteCycles: ['年次', '月次', '日次', ],
            months: Array.from(new Array(12)).map((v,i)=> i + 1),
            days: Array.from(new Array(31)).map((v,i)=> i + 1),
        };
    },
    computed: {
    },
    created () {
        this.$root.init();
        event
            .on('saveStorage', () => {
                if (this.storageData.isInitialized == true) {
                    this.saveStorageData();
                }
            });
    },
    mounted() {
        this.$root.setBreadcrumbs([
            {
                text: 'システム設定',
                disabled: true,
                to: '',
            },
        ]); // ヘッダ部のパンくずリストを設定する。

        this.restoreStorageData();

        Promise
            .all([
                this.editSetting()
            ]) 
            .then(() => this.storageData.isInitialized = true);
    },
    beforeDestroy() {
        event.off('saveStorage');
    },
    methods: {
        editSetting () {
            http
                .get('settings/1/edit')
                .then(res => {
                    this.storageData.setting.id = res.data.id;
                    this.storageData.setting.deleteCycle = res.data.delete_cycle;
                    this.storageData.setting.deleteMonth = res.data.delete_month;
                    this.storageData.setting.deleteDay = res.data.delete_day;
                    this.storageData.setting.deleteTime = this.$moment(this.$moment().format('YYYY/MM/DD') + ' ' + res.data.delete_time).format('HH:mm');
                    this.storageData.setting.probationDays = res.data.probation_days;
                    this.storageData.setting.burstIntervalMillisecThreshold = res.data.burst_interval_millisec_threshold;
                    this.storageData.setting.updatedAt = res.data.updated_at;
                })
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, 'データの取得に失敗しました。');
                });
        },
        registerSetting () {
            this.updateSetting()
                .then(() => this.editSetting())
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, '登録に失敗しました。');
                });
        },
        async updateSetting () {
            return http
                .put(
                    'settings/' + this.storageData.setting.id, 
                    {
                        delete_cycle: this.storageData.setting.deleteCycle,
                        delete_month: this.storageData.setting.deleteMonth,
                        delete_day: this.storageData.setting.deleteDay,
                        delete_time: this.storageData.setting.deleteTime,
                        probation_days: this.storageData.setting.probationDays,
                        burst_interval_millisec_threshold: this.storageData.setting.burstIntervalMillisecThreshold,
                        updated_at: this.storageData.setting.updatedAt,
                    }).catch((error) => {this.showError(error, '設定の更新に失敗しました');});            
        },
        changeDeleteCycle () {
            switch (this.storageData.setting.deleteCycle) {
                case '月次':
                    this.storageData.setting.deleteMonth = 0;
                    break;
                case '日次':
                    this.storageData.setting.deleteMonth = 0;
                    this.storageData.setting.deleteDay = 0;
                    break;
                default:
                    break;
            }
        },
    },
};
</script>