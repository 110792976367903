import { render, staticRenderFns } from "./PhotoFaceCard.vue?vue&type=template&id=343088f7&"
import script from "./PhotoFaceCard.vue?vue&type=script&lang=js&"
export * from "./PhotoFaceCard.vue?vue&type=script&lang=js&"
import style0 from "./PhotoFaceCard.vue?vue&type=style&index=0&id=343088f7&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports