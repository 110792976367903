<template>
    <div class="dialog">
        <div class="dialog-title" v-if="messageHasTitle">
            <div class="wrap">{{messageTitle}}</div>
        </div>

        <div class="dialog-body">
            <div class="wrap">{{messageBody}}</div>
        </div>

        <hr/>

        <div class="action">
            <button v-on:click="proceed">{{options.okText}}</button>
        </div>
    </div>
</template>

<script>
import DialogMixin from 'vuejs-dialog/dist/vuejs-dialog-mixin.min.js';

export default {
    mixins: [DialogMixin],
}
</script>

<style scoped>

* {
    margin: 0px;
}

.dialog-title {
    background-color: #66bcff;
    font-weight: bold;
    font-size: 20px!important;
    letter-spacing: .02!important;
    line-height: 1!important;
    padding: 16px!important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.dialog-body {
    white-space: pre-line;
    padding: 16px!important;
    font-size: 14px!important;
}

hr {
    border:solid;
    border-width: thin 0 0;
    height: 0;
    max-height: 0;
    max-width: 100%;
    transition: inherit;
    border-color: rgba(0,0,0,.12);
}

.action {
    padding: 8px;
    text-align: right;
}

button {
    background-color: #dbdbdb;
    padding: 0;
    font-family: 'Meiryo';
    border-style: none;
    color: inherit;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 2px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    font-size: 14px;
    font-weight: 500;
    height: 36px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 6px 8px;
    min-width: 88px;
    outline: 0;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: .3s cubic-bezier(.25,.8,.5,1),color 1ms;
    transition: .3s cubic-bezier(.25,.8,.5,1),color 1ms;
    user-select: none;
    vertical-align: middle;
    -webkit-box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    will-change: box-shadow;
    cursor: pointer;
}

button:hover {
    position: relative;
}

button:before {
    border-radius: inherit;
    color: inherit;
    content: "";
    height: 100%;
    left: 0;
    opacity: .12;
    position: absolute;
    top: 0;
    -webkit-transition: .3s cubic-bezier(.25,.8,.5,1);
    transition: .3s cubic-bezier(.25,.8,.5,1);
    width: 100%;
}

button:hover:before {
    background-color: currentColor;
}

.wrap {
    overflow-wrap: break-word;
}


</style>
