<template>
    <page-container>
        <template slot="navigation" />
        <template slot="header">
            <v-toolbar 
                fixed
                dense  
                :color="$root.getTheme().tertiary"
                elevation="10"
            >
                <v-container fluid class="ma-0 pa-0">
                    <v-row justify="space-between" style="height:48px!important;">
                        <v-col cols="3">
                            <v-toolbar-title v-if="storageData.isInitialized">
                                <span>撮影行事一覧</span>
                            </v-toolbar-title>
                        </v-col>
                        <v-col cols="3">
                            <v-switch
                                v-if="storageData.isInitialized"
                                v-model="storageData.album.is_completed"
                                label="アルバム制作完了"
                                hide-details
                                :ripple="false"
                                class="mt-1 mr-2"
                                @change="changeAlbumStatus"
                            />
                        </v-col>
                        <v-col cols="3" class="ma-0 pa-0">
                            <v-container class="ma-0 py-0 pl-0 pr-6">
                                <v-row justify="end">
                                    <icon-with-text 
                                        text="人物頻度確認"
                                        :show-text="showText"
                                        icon
                                        icon-name="mdi-chart-box"
                                        style="ma-0"
                                        @click="openShootingFrequencyDialog(0)"
                                    />
                                    <icon-with-text 
                                        text="撮影行事追加"
                                        :show-text="showText"
                                        icon
                                        icon-name="mdi-folder-plus"
                                        style="ma-0"
                                        @click="createCallback"
                                    />
                                </v-row>
                            </v-container>
                        </v-col>
                    </v-row>
                </v-container>
            </v-toolbar>
        </template>

        <template slot="top" />
        
        <template slot="middle">
            <v-container class="pa-0">
                <v-row>
                    <v-col
                        v-for="s in shootings"
                        :key="s.id"
                        cols="12"
                        md="4"
                        lg="3"
                        xl="2"
                    >
                        <shooting-card 
                            :shooting="s" 
                            :select-callback="selectCallback" 
                            :edit-callback="() => editCallback(s.id)"
                            :delete-callback="() => deleteShooting(s.id)"
                            :confirm-callback="() => openShootingFrequencyDialog(s.id)"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </template>
        
        <template slot="bottom">
            <!-- 人物頻度確認ダイアログ -->
            <v-dialog 
                v-model="storageData.shootingFrequencyDialog" 
                persistent
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <v-card :color="$root.getTheme().background">
                    <shooting-frequency
                        ref="shootingFrequencyVue"
                        :album-id="albumId"
                        :close-callback="closeShootingFrequencyDialog"
                    />
                </v-card>
            </v-dialog>
        </template>
    </page-container>
</template>

<script>
import event from '../../utils/event';
import http from '../../services/http';
import urlUtil from '../../utils/url';
import leaveConform from '../../mixins/leave-confirm-mixin';
import monet from 'monet';

export default {
    mixins: [leaveConform],
    props: {
        albumId: {
            default: 0,
            type: [String, Number]       
        },
        selectCallback: {
            default: () => {},
            type: Function
        },
        createCallback: {
            default: () => {},
            type: Function,
        },
        editCallback: {
            default: () => {},
            type: Function,
        },
        onlyUnsubmited: {
            default: false,
            type: Boolean,
        },
    },
    components: {
        'shooting-card': require('../Shootings/ShootingCard.vue').default,
        'shooting-frequency': require('../Frequency/ShootingFrequency.vue').default,
        'icon-with-text':require('../Icons/IconWithText.vue').default,
    },
    data () {
        return {
            viewID: '',
            drawer: false, 
            storageData: {
                isInitialized: false,
                shootingFrequencyDialog: false,
            },
            httpState: http.state,
            shootings: [],
            showText: true,
        };
    },
    computed: {
        onlyUnsubmitedComputed: {
            get () {
                return this.onlyUnsubmited;
            },
            set (value) {
                console.log('c:' + value);
                this.$emit('update:only-unsubmited', value);
            }
        }
    },
    watch: {
        onlyUnsubmitedComputed() {
            this.fetchShootings();
        },
    },
    created () {
        event
            .on('saveStorage', () => {
                if (this.storageData.isInitialized == true) {
                    this.saveStorageData();
                }
            });
    },
    mounted() {
        this.restoreStorageData();
        this.initialize();
    },
    beforeDestroy() {
        event.off('saveStorage');
    },
    methods: {
        initialize () {
            this.storageData.isInitialized = false;
            
            Promise.all([this.fetchAlbum(), this.fetchShootings()])
                .then(() => this.storageData.isInitialized = true)
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError('初期化処理に失敗しました。', '初期化エラー', error);
                });
        },
        fetchShootings () {
            return http.get((url => {
                url = urlUtil.addQueryParamIfDefined(url, 'album_id', this.albumId);
                url = urlUtil.addQueryParamIfDefined(url, 'only_unsubmited', this.onlyUnsubmited);
                return url;
            })('shootings'))
                .then(res => this.shootings = res.data);
        },
        fetchAlbum() {
            return http
                .get('albums/' + this.albumId)
                .then((res) => (this.storageData.album = res.data));
        },
        changeAlbumStatus() {
            this.updateAlbumStatus()
                .then(() => this.fetchAlbum())
                .catch((error) => {
                    this.storageData.album.is_completed =
            !this.storageData.album.is_completed;

                    if (error === 'unauthorized') return;
                    this.showError(error, '状態変更エラー');
                });
        },
        updateAlbumStatus() {
            return http.put('albums/status/' + this.storageData.album.id, {
                is_completed: this.storageData.album.is_completed,
                updated_at: this.storageData.album.updated_at,
            });
        },
        deleteShooting(shooting_id) {
            const url = 'shootings/delete/' + shooting_id;
            this.showConfirm('撮影行事を削除します。　\nよろしいですか？')
                .then((confirmResult) => {
                    if (confirmResult) {
                        http.
                            delete(url)
                            .then(() => {
                                this.showInfo('撮影行事の削除が完了しました。', '撮影行事削除');
                                this.fetchShootings();
                            })
                            .catch(error => {
                                if (error === 'unauthorized') return;
                                this.showError(error, '撮影行事削除エラー');
                            });
                    }
                });
        },
        openShootingFrequencyDialog (shootingId) {
            this.storageData.shootingFrequencyDialog = true;

            this.$nextTick(() => this.$refs.shootingFrequencyVue.initialize()
                .then(() => this.$refs.shootingFrequencyVue.setShooting(shootingId))
                .catch(error => {
                    this.closeShootingFrequencyDialog();

                    if (error === 'unauthorized') return;
                    this.showError('人物頻度確認画面の初期化に失敗しました。', '初期化エラー', error);
                })); 
        },
        closeShootingFrequencyDialog () {
            this.storageData.shootingFrequencyDialog = false;
        },
        changeOnlyUnsubmited(e) {
            this.fetchShootings();
            this.$emit('update:only-unsubmited', e);
        },
    },
};
</script>