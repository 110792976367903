<template>
    <div v-if="loading" class="loading-screen">
        <div class="el-loading-spinner">
            <svg class="circular" viewBox="25 25 50 50">
                <circle class="path" cx="50" cy="50" r="20" fill="none" />
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    props: ['loading'],
};
</script>
<style>
    .loading-screen {
        z-index: 1000;
        position: fixed!important;
        background-color: rgba(0, 0, 0, 0.3);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
</style>
