<template>
    <page-container>
        <template slot="navigation" />

        <template slot="header">
            <div>
                <v-toolbar dense color="secondary">
                    <v-btn icon @click.stop="closeCallback">
                        <v-icon>close</v-icon>
                    </v-btn>
                    <v-toolbar-title v-if="!!storageData.isInitialized">
                        <span>{{ storageData.album.name + " - 顔マスタ管理" }}</span>
                    </v-toolbar-title>
                    <v-spacer />
                    <v-toolbar-items @click.stop="() => {
                        openCreateFacesDialog();
                    }
                        ">
                        <icon-with-text text="顔マスタ一括作成" :show-text="showText" icon :icon-name="icons.mdiFaceManShimmer"
                            style="ma-0" />
                    </v-toolbar-items>
                    <v-toolbar-items @click.stop="() => {
                        openUpdateFaceInfoDialog();
                    }
                        ">
                        <icon-with-text text="補足情報付与" :show-text="showText" icon icon-name="attach_file"
                            :disabled="!isFaceMasterCreated" style="ma-0" />
                    </v-toolbar-items>
                    <v-toolbar-items @click.stop="() => {
                        openAddFaceDialog();
                    }
                        ">
                        <icon-with-text text="手動削除" :show-text="showText" icon icon-name="add_box"
                            :disabled="!isFaceMasterCreated" style="ma-0" />
                    </v-toolbar-items>
                </v-toolbar>
                <!-- </template> -->

                <!-- 顔マスタ基写真 -->
                <!-- <template slot="top"> -->
                <v-expansion-panels v-model="expansionPanels">
                    <v-expansion-panel>
                        <v-expansion-panel-header v-if="expansionPanels == undefined">
                            <p class="ma-0 text-right">
                                顔マスタに使用した写真を見る
                            </p>
                        </v-expansion-panel-header>
                        <v-expansion-panel-header v-else>
                            顔マスタに使用した写真({{ storageData.facebases.length }}枚)
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-container class="overlay-scrollable" fluid no-gutters
                                :style="{ height: faceBasePanelHeight }">
                                <v-row no-gutters>
                                    <v-col v-for="facebase in storageData.facebases" :key="facebase.name" cols="2">
                                        <v-container>
                                            <v-row align="center" justify="space-between" class="mb-2">
                                                <v-chip v-for="pt in facebase.photo_tags" :key="pt.id" class="ml-2"
                                                    :color="pt.tag.back_color_code" :text-color="pt.tag.fore_color_code"
                                                    small>
                                                    {{
                                                        facebase.photo_tags.length == 1
                                                        ? pt.tag.name
                                                        : pt.tag.name.slice(0, 1) + "."
                                                    }}
                                                </v-chip>
                                                <v-spacer />
                                                <v-icon v-if="facebase.label != null"
                                                    :color="'#' + facebase.label.color_code" class="mr-2">
                                                    mdi-numeric-{{ facebase.label.no }}-box
                                                </v-icon>
                                            </v-row>
                                            <v-row dense>
                                                <v-img :src="facebase.visualized_file_path" :style="{ cursor: 'pointer' }"
                                                    @click.stop="openEditFaceBaseDialog(facebase)" />
                                            </v-row>
                                            <v-row dense align="center" justify="center">
                                                <span>{{ facebase.name }}</span>
                                            </v-row>
                                        </v-container>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-dialog v-model="largeFaceBase" width="90vw">
                    <v-card>
                        <select-face-canvas v-if="selectedFacebase" :src="selectedFacebase.file_path"
                            :faces="selectedFacebase.faces" :selected-face-ids="storageData.selectedFaces"
                            :canvas-height="canvasHeight" @faceRectClicked="faceRectClicked" />
                    </v-card>
                </v-dialog>
                <!-- </template> -->

                <!-- 検索項目 -->
                <!-- <template slot="middle"> -->
                <v-toolbar fixed dense :color="$root.getTheme().quaternary">
                    <v-text-field v-model="storageData.face_no_form" hide-details clearable dense outlined label="顔マスタ番号"
                        @change="fetchFaces()" />
                    <v-text-field v-model="storageData.student_no_form" hide-details clearable dense outlined label="出席番号"
                        @change="fetchFaces()" />
                    <v-select v-model="storageData.sex_selector" hide-details clearable dense outlined :items="['男性', '女性']"
                        label="性別" @change="fetchFaces()" />
                    <v-text-field v-model="storageData.note_form" hide-details clearable dense outlined label="個人メモ"
                        @change="fetchFaces()" />

                    <v-spacer />

                    <v-btn-toggle dense group>
                        <icon-with-text v-if="!notAllSelected" text="全選択解除" :show-text="showText" icon-name="mdi-select-all"
                            icon :value="false" :disabled="!isFaceMasterCreated" @click="checkAllFaces" />
                        <icon-with-text v-else text="全選択" :show-text="showText" icon-name="mdi-select" icon :value="true"
                            :disabled="!isFaceMasterCreated" @click="checkAllFaces" />
                    </v-btn-toggle>

                    <v-toolbar-items @click="() => {
                        bulkAIProc();
                    }
                        ">
                        <icon-with-text text="AI処理一括実行" :show-text="showText" icon icon-name="mdi-head-cog-outline"
                            style="ma-0" :disabled="!isFaceSelected" />
                    </v-toolbar-items>
                    <v-toolbar-items @click="() => {
                        deleteFace();
                    }
                        ">
                        <icon-with-text text="顔マスタを削除" :show-text="showText" icon icon-name="delete" style="ma-0"
                            :disabled="!isFaceSelected" />
                    </v-toolbar-items>
                </v-toolbar>
            </div>
        </template>

        <!-- 顔マスタ一覧 -->
        <template slot="middle">
            <v-container fluid class="overlay-scrollable" :style="{ height: facePanelHeight }">
                <v-row align="center" justify="start">
                    <span>顔マスタ一覧 ({{ faces.length }}件)</span>
                </v-row>
                <v-row no-gutters>
                    <v-col v-for="face in faces" :key="face.no" cols="1">
                        <v-card :color="storageData.selectedFaces.includes(face.id)
                            ? '#228b22'
                            : $root.getTheme().background
                            " min-height="200" max-height="200">
                            <v-container class="">
                                <v-row no-gutters align="center" justify="space-between">
                                    <v-checkbox v-model="storageData.selectedFaces" :label="`${face.no}`" hide-details
                                        class="shrink mr-2 mt-0" :value="face.id" @change="selectFace()" />
                                    <v-spacer />
                                    <icon-sex-ratio :male="face.sex == '男性'" width="1.5em" height="1.5em"
                                        :is-clickable="true" @click="updateToggleFaceSex(face)" />
                                </v-row>
                                <v-row dense>
                                    <v-img contain max-height="120" min-height="120" :src="face.face_image_path" />
                                </v-row>
                                <v-row dense align="center" justify="space-around">
                                    <v-col cols="9">
                                        <span>{{ face.student_no }}</span>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-btn icon style="ma-0" @click="openEditFaceInfoDialog(face.id)">
                                            <v-icon>edit</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>

            <!-- 顔マスタ追加ダイアログ -->
            <add-face-dialog ref="addFaceDialogVue" :album-id="albumId"
                :shooting-id="selectedFacebase ? selectedFacebase.shooting_id : 0"
                :photo-id="selectedFacebase ? selectedFacebase.id : 0" @closed="editFaceBaseDialogClosed"
                @doneSuccessfully="initialize" />
            <create-faces-dialog ref="createFacesDialogVue" :album-id="albumId" :is-created-faces="faces.length != 0"
                @doneSuccessfully="initialize" />
            <bulk-update-face-info-dialog ref="bulkUpdateFaceInfoDialogVue" :album-id="albumId"
                @doneSuccessfully="initialize" />
            <edit-face-info-dialog v-model="editFaceInfoDialog" :face="editingFace" @edited="(val) => {
                editingFace = val;
            }
                " @updated="updateFaceInfo" />
        </template>
    </page-container>
</template>

<script>
import event from '../../utils/event';
import http from '../../services/http';
import urlUtil from '../../utils/url';
import leaveConform from '../../mixins/leave-confirm-mixin';

import { mdiFaceManShimmer } from '@mdi/js';
import { awaitJobDone } from '../../services/awaitJobDone';

export default {
    components: {
        AddFaceDialog: require('../Faces/AddFaceDialog.vue').default,
        CreateFacesDialog: require('../Faces/BulkCreateFacesDialog.vue').default,
        BulkUpdateFaceInfoDialog: require('../Faces/BulkUpdateFaceInfoDialog.vue')
            .default,
        EditFaceInfoDialog: require('../Faces/EditFaceInfoDialog.vue').default,
        SelectFaceCanvas: require('../Faces/SelectFaceCanvas.vue').default,
        'icon-with-text': require('../Icons/IconWithText.vue').default,
        'icon-sex-ratio': require('../Icons/IconSexRatio.vue').default,
    },
    mixins: [leaveConform],
    props: {
        albumId: {
            type: [String, Number],
            default: 0,
        },
        closeCallback: {
            type: Function,
            default: undefined,
        },
    },
    data() {
        return {
            viewID: 'AL02-F00',
            storageData: {
                isInitialized: false,
                customerType: undefined,
                onlyUncompleted: true,
                facebases: [],
                album: {
                    id: 0,
                    customerId: 0,
                    schoolYear: 2020, // TODO 現在年とする
                    name: '',
                    submitedDirectoryName: '',
                    submitedDataDirectoryName: '',
                    isCompleted: 0,
                    updatedAt: undefined,
                },
                modeUpdate: false,
                albumDialog: false,
                addFaceDialog: false,
                bulkCreateFaceMasterDialog: false,
                selectAllCheckBox: false,
                selectedFaces: [],
                face_no_form: undefined,
                sex_selector: undefined,
                student_no_form: undefined,
                note_form: undefined,
            },
            httpState: http.state,
            albums: [],
            customerTypes: [],
            customers: [],
            faces: [],
            editingFace: undefined,
            editFaceInfoDialog: false,
            largeFaceBase: false,
            selectedFacebase: undefined,
            icons: {
                mdiFaceManShimmer,
            },
            expansionPanels: undefined,
            showText: true,
        };
    },
    computed: {
        canvasHeight: function () {
            return innerHeight * 0.9;
        },
        notAllSelected() {
            return !(this.storageData.selectedFaces.length == this.faces.length);
        },
        isFaceSelected() {
            return Boolean(this.storageData.selectedFaces.length);
        },
        isFaceMasterCreated() {
            return Boolean(this.faces.length);
        },
        faceBasePanelHeight() {
            return '40vh';
        },
        facePanelHeight() {
            return this.expansionPanels !== undefined ? '40vh' : '80vh';
        },
    },
    mounted() {
        event.on('saveStorage', () => {
            if (this.storageData.isInitialized) {
                this.saveStorageData();
            }
        });

        if (!this.restoreStorageData()) {
            this.initialize()
                .then(() => {
                    this.storageData.isInitialized = true;
                    this.confirmLeaveReset(['storageData']);
                })
                .catch((error) => {
                    if (error === 'unauthorized') return;
                    this.showError('初期化処理に失敗しました。', '初期化エラー', error);
                });
        }
    },
    beforeDestroy() {
        event.off('saveStorage');
    },
    methods: {
        fetchFaceBases() {
            http
                .get(
                    urlUtil.addQueryParamIfDefined(
                        'photos/facebases',
                        'album_id',
                        this.albumId
                    )
                )
                .then((res) => {
                    this.storageData.facebases = res.data
                        // 検出矩形再描画時のキャッシュ避け
                        .map((f) => {
                            f.visualized_file_path += '?' + Math.random();
                            return f;
                        });
                })
                .catch((error) => {
                    if (error === 'unauthorized') return;
                    this.showError(error, '顔マスタ基写真取得エラー');
                });
        },
        fetchFaces() {
            var url = urlUtil.addQueryParamIfDefined(
                'faces',
                'album_id',
                this.albumId
            );
            url = urlUtil.addQueryParamIfDefined(
                url,
                'face_no',
                this.storageData.face_no_form
            );
            url = urlUtil.addQueryParamIfDefined(
                url,
                'student_no',
                this.storageData.student_no_form
            );
            url = urlUtil.addQueryParamIfDefined(
                url,
                'sex',
                this.storageData.sex_selector
            );
            url = urlUtil.addQueryParamIfDefined(
                url,
                'note',
                this.storageData.note_form
            );

            console.log(url);

            http
                .get(url)
                .then((res) => {
                    this.faces = res.data;
                })
                .catch((error) => {
                    if (error === 'unauthorized') return;
                    this.showError(error, '顔マスタ取得エラー');
                });
        },
        async bulkAIProc() {
            var face_ids = this.storageData.selectedFaces;
            if (!face_ids || face_ids.length == 0) {
                this.showError('顔マスタパネルが未選択です');
                return;
            }
            if (
                !(await this.showConfirm(
                    '取込済の全ての写真に対してAI処理を行います。作成済みの検出顔情報は上書きされます　\nよろしいですか？'
                )
                    .then(() => true)
                    .catch(() => false))
            ) {
                return;
            }

            var url = 'photo_faces/ai_proc';
            var data = new FormData();

            data.append('album_id', this.albumId);
            face_ids.forEach((face_id) => {
                data.append('face_ids[]', face_id);
            });

            http
                .post(url, data)
                .then((res) => {
                    const jobId = res.data.job_id;
                    awaitJobDone(jobId, true).then(() => {
                        alert('AI処理の実行が完了しました');
                    }).catch((error) => {
                        this.showError(error, 'AI処理エラー');
                    });
                })
                .catch((error) => {
                    if (error === 'unauthorized') return;
                    this.showError(error, 'AI処理の実行に失敗しました');
                });
        },
        initStorageData() {
            this.storageData.albumDialog = false;
            this.storageData.modeUpdate = false;

            this.storageData.album.id = 0;
            this.storageData.album.customerId = 0;
            this.storageData.album.schoolYear = 2020; // TODO
            this.storageData.album.name = '';
            this.storageData.album.submitedDirectoryName = '';
            this.storageData.album.submitedDataDirectoryName = '';
            this.storageData.album.updatedAt = undefined;
        },
        initialize() {
            return Promise.all([
                this.fetchAlbum(),
                this.fetchFaceBases(),
                this.fetchFaces(),
                (this.storageData.selectedFaces = []),
            ]).then(() => (this.storageData.isInitialized = true));
        },
        fetchAlbum() {
            return http
                .get('albums/' + this.albumId)
                .then((res) => (this.storageData.album = res.data));
        },
        checkAllFaces() {
            if (!this.notAllSelected) {
                this.storageData.selectedFaces = [];
            } else {
                this.storageData.selectedFaces = [];
                for (var face in this.faces) {
                    this.storageData.selectedFaces.push(this.faces[face].id);
                }
            }
        },
        selectFace() {
            if (this.storageData.selectedFaces.length == this.faces.length) {
                this.storageData.selectAllCheckBox = true;
            } else {
                this.storageData.selectAllCheckBox = false;
            }
        },
        faceRectClicked(id) {
            if (this.storageData.selectedFaces.includes(id)) {
                this.storageData.selectedFaces = this.storageData.selectedFaces.filter(
                    (f) => f != id
                );
            } else {
                this.storageData.selectedFaces.push(id);
            }
            this.selectFace();
        },
        deleteFace() {
            if (this.storageData.selectedFaces.length == 0) {
                alert('削除対象が選択されていません');
                return;
            }

            var url = 'faces';
            url = urlUtil.addQueryParamIfDefined(url, 'album_id', this.albumId);
            this.storageData.selectedFaces.forEach(
                (f) => (url = urlUtil.addQueryParamIfDefined(url, 'face_ids[]', f))
            );

            http
                .delete(url)
                .then(() => {
                    this.initialize();
                    event.emit('updatedFaces');
                })
                .catch((error) => {
                    if (error === 'unauthorized') return;
                    this.showError(error, '顔マスタ削除に失敗しました');
                });
        },
        updateFaceInfo() {
            var url = 'faces/' + this.editingFace.id;
            var data = {};
            data['student_no'] = this.editingFace.student_no;
            data['sex'] = this.editingFace.sex;
            data['note'] = this.editingFace.note;

            http
                .put(url, data)
                .then(() => {
                    alert('補足情報の付与に成功しました');
                    event.emit('updatedFaces');
                    this.editingFace = undefined;
                    this.editFaceInfoDialog = false;

                    this.fetchFaces();
                })
                .catch((error) => {
                    if (error === 'unauthorized') return;
                    this.showError(error, '顔情報更新エラー。');
                });
        },
        updateToggleFaceSex(face) {
            const oldSexVal = face.sex;
            const newSexVal = face.sex == '男性' ? '女性' : '男性';

            face.sex = newSexVal;
            const url = 'faces/' + face.id;

            http
                .backGroundRequest('put', url, {
                    sex: newSexVal,
                })
                .then(() => {
                    console.log('updated');
                })
                .catch((error) => {
                    if (error === 'unauthorized') return;
                    face.sex = oldSexVal;
                    this.showError(error, '顔情報更新エラー。');
                });
        },
        searchFaces(event) {
            console.log(event.target.tagName);
        },
        openAddFaceDialog() {
            this.$refs.addFaceDialogVue.display = true;
            this.$refs.addFaceDialogVue.initialize();
        },
        closeAddFaceDialog() {
            this.$refs.addFaceDialogVue.display = false;
        },
        openCreateFacesDialog() {
            this.$refs.createFacesDialogVue.display = true;
            this.$refs.createFacesDialogVue.initialize();
        },
        closeCreateFacesDialog() {
            this.$refs.createFacesDialogVue.display = false;
        },
        openUpdateFaceInfoDialog() {
            this.$refs.bulkUpdateFaceInfoDialogVue.display = true;
            this.$refs.bulkUpdateFaceInfoDialogVue.initialize();
        },
        openEditFaceInfoDialog(id) {
            // 参照は切る
            this.editingFace = Object.assign(
                {},
                this.faces.filter((f) => {
                    return f.id == id;
                })[0]
            );
            this.editFaceInfoDialog = true;
        },
        showLargeFaceBase(facebase) {
            this.selectedFacebase = facebase;
            this.largeFaceBase = true;
        },
        openEditFaceBaseDialog(facebase) {
            this.selectedFacebase = facebase;
            this.$refs.addFaceDialogVue.display = true;
            this.$refs.addFaceDialogVue.initialize();
        },
        editFaceBaseDialogClosed() {
            this.selectedFacebase = undefined;
        },
    },
};
</script>