import event from '../utils/event';

export default {
    baseDataPathes: undefined,
    ignoreItemName: undefined,
    baseData: undefined,
    async beforeRouteLeave(to, from, next) {
        await this.confirmLeaveRouter(next);
    },
    created() {
        this.initializeLeaveConfirm();

        event
            .on('unauthorized', () => {
                this.saveCurrentState();
                this.confirmLeaveReset(undefined);
            });

        window.addEventListener('beforeunload', this.confirmLeaveBrowser);
    },
    beforeDestroy () {
        window.removeEventListener('beforeunload', this.confirmLeaveBrowser)
    },
    methods: {
        initializeLeaveConfirm() {
            const storageKey = 'ams-data-leaveConfirm-' + this.viewID;
            let stroagedData = localStorage.getItem(storageKey);
            
            localStorage.removeItem(storageKey);

            if (stroagedData != null) {
                const data = JSON.parse(stroagedData);
                this.baseDataPathes = data.paths;
                this.baseData = data.baseData;
            } else {
                this.baseDataPathes = null;
                this.baseData = null;
            }
        },
        saveCurrentState () {
            const obj = {
                paths: this.baseDataPathes,
                baseData: this.baseData,
            };

            const json = JSON.stringify(obj);
            const storageKey = 'ams-data-leaveConfirm-' + this.viewID;

            localStorage.setItem(storageKey, json);
        },
        confirmLeaveReset (baseDataPathes, ignoreItemName) {
            this.baseDataPathes = baseDataPathes;
            this.ignoreItemName = ignoreItemName;
            this.baseData = this.getCurrentData();
        },
        async confirmLeaveRouter(next) {
            await this.confirmLeave() ? next() : next(false);
        },
        async confirmLeave () {
            if (this.hasChanges() === false) {
                return true;
            }

            return await this.showConfirm('保存されていない変更内容は破棄されます。\nよろしいですか？', '保存されていません')
                .then(() => true)
                .catch(() => false);
        },
        confirmLeaveBrowser (e) {
            if (this.hasChanges() === false) {
                return;
            }

            const msg = '保存されていない変更内容は破棄されます。\nよろしいですか？';

            e.returnValue = msg;
            return msg;
        },
        getCurrentData () {
            if (this.baseDataPathes == null) {
                return null;
            }

            let data = this;
            this.baseDataPathes.forEach(path => data = data == null ? null : data[path]);

            return JSON.stringify(data, (k, v) => this.ignoreItemName && this.ignoreItemName.includes(k) ? '' : (v == null ? '' : v));
        },
        hasChanges () {
            return this.getCurrentData() != this.baseData;
        }
    }
};
