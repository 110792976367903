<template>
    <page-container>
        <template slot="middle">
            <v-dialog v-model="display" max-width="1000" persisitent scrollable>
                <v-card class="pa-3">
                    <v-container>
                        <span>
                            顔マスタに使用する写真 ({{ facebases_candidate.length }}枚)
                        </span>
                        <v-toolbar dense color="silver">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-checkbox slot="activator" v-model="selectAllCheckBox" class="shrink mr-2 mt-0"
                                        hide-details icon style="ma-0" label="全選択" v-on="on"
                                        @change="checkAllFacebases()" />
                                </template>
                                <span>全選択</span>
                            </v-tooltip>
                            <v-toolbar-items>
                                <icon-with-text text="削除" :show-text="showText" icon style="ma-0" icon-name="mdi-delete"
                                    :disabled="!Boolean(selectedFacebases.length)" @click="deletePhotos" />
                            </v-toolbar-items>
                            <v-spacer />
                            <v-toolbar-items>
                                <icon-with-text text="アップロード" :show-text="showText" icon style="ma-0"
                                    icon-name="mdi-cloud-upload-outline"
                                    @click="() => { storageData.photoUploadDialog = true }" />
                            </v-toolbar-items>
                        </v-toolbar>
                    </v-container>

                    <v-card-text style="height: 70vh;">
                        <v-container>
                            <v-row no-gutters>
                                <v-col v-for="facebase in facebases_candidate" :key="facebase.name" cols="3">
                                    <v-container>
                                        <v-row align="center" justify="start" class="mb-2">
                                            <v-checkbox v-model="selectedFacebases" hide-details class="mt-0"
                                                :value="facebase.id" @change="selectFacebase()" />
                                            <v-chip v-for="pt in facebase.photo_tags" :key="pt.id"
                                                :color="pt.tag.back_color_code" :text-color="pt.tag.fore_color_code" small>
                                                {{ facebase.photo_tags.length == 1 ? pt.tag.name : (pt.tag.name.slice(0, 1)
                                                    + '.') }}
                                            </v-chip>
                                            <v-spacer />
                                            <v-icon v-if="facebase.label != null" :color="'#' + facebase.label.color_code"
                                                class="mr-2">
                                                mdi-numeric-{{ facebase.label.no }}-box
                                            </v-icon>
                                        </v-row>
                                        <v-row dense>
                                            <v-img :src="facebase.thumbnail_file_path" />
                                        </v-row>
                                        <v-row dense align="center" justify="center">
                                            <span>{{ facebase.name }}</span>
                                        </v-row>
                                    </v-container>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-container>
                            <v-row dense justify="space-between">
                                <cancel-dialog-button width="100" @click="closeDialog">
                                    キャンセル
                                </cancel-dialog-button>
                                <v-spacer />
                                <proceed-dialog-button width="150" @click="bulkCreateFace">
                                    顔マスタ一括作成
                                </proceed-dialog-button>
                            </v-row>
                        </v-container>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <photo-upload-dialog ref="photoUploadDialogVue" :screen-type="screenType" :shooting-id="shootingId"
                :visible="storageData.photoUploadDialog" :close-callback="() => { storageData.photoUploadDialog = false }"
                :existing-photo-names="facebases_candidate.map((f) => { return f.name })"
                @done-upload="fetchFaceBasesCandidate" />
        </template>
    </page-container>
</template>

<script>
import event from '../../utils/event';
import http from '../../services/http';
import urlUtil from '../../utils/url';
import leaveConform from '../../mixins/leave-confirm-mixin';
import monet from 'monet';
import { awaitJobDone } from '../../services/awaitJobDone';

export default {
    components: {
        'photo-upload-dialog': require('../Dialogs/PhotoUploadDialog.vue').default,
        'icon-with-text': require('../Icons/IconWithText.vue').default,
    },
    mixins: [leaveConform],
    props: {
        albumId: {
            type: [Number, String],
            default: 0,
        },
        closeCallback: {
            type: Function,
            default: () => { },
        },
        isCreatedFaces: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            viewID: '',
            storageData: {
                isInitialized: false,
                customerType: undefined,
                onlyUncompleted: true,
                faces: [],

                photoUploadDialog: false,

                modeUpdate: false,
            },
            shootingId: 0,
            httpState: http.state,
            albums: [],
            customerTypes: [],
            customers: [],
            faces: [],
            selectAllCheckBox: false,
            selectedFacebases: [],
            facebases_candidate: [],
            display: false,
            showText: true,
            screenType: 'createFace',
        };
    },
    mounted() {
        event
            .on('saveStorage', () => {
                if (this.storageData.isInitialized) {
                    this.saveStorageData();
                }
            });
    },
    beforeDestroy() {
        event.off('saveStorage');
    },
    methods: {
        closeDialog() {
            this.display = false;
        },
        initialize() {
            return Promise.all([
                console.log('initialize'),
                this.fetchFaceBasesCandidate(),
                this.findOrStoreShootingForFaceMaster(),
            ])
                .then(() => {
                    this.storageData.isInitialized = true;
                    this.confirmLeaveReset(['storageData']);
                })
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError('初期化処理に失敗しました。', '初期化エラー', error);
                });
        },
        findOrStoreShootingForFaceMaster() {
            http.get('shootings/for_face_master/' + this.albumId)
                .then((res) => {
                    this.shootingId = res.data.id;
                })
                .catch((error) => {
                    if (error === 'unauthorized') return;
                    this.showError(error, '初期化処理に失敗しました');
                });
        },
        selectFacebase() {
            if (this.selectedFacebases.length == this.facebases_candidate.length) {
                this.selectAllCheckBox = true;
            } else {
                this.selectAllCheckBox = false;
            }
        },
        checkAllFacebases() {
            if (!this.selectAllCheckBox) {
                this.selectedFacebases = [];
            } else {
                this.selectedFacebases = [];
                for (var facebase in this.facebases_candidate) {
                    this.selectedFacebases.push(this.facebases_candidate[facebase].id);
                }
            }
        },
        async bulkCreateFace() {
            if (this.isCreatedFaces) {
                if (!await this.showConfirm('顔マスタを再作成します。作成済みの顔マスタと関連する検出顔は破棄されます。　\nよろしいですか？')
                    .then(() => true)
                    .catch(() => false)
                ) {
                    return;
                }
            }

            var data = new FormData();
            data.append('album_id', this.albumId);

            http
                .post('faces/ai_proc', data)
                .then(async (res) => {
                    console.log(res.data);
                    const jobId = res.data.job_id;

                    const successCallback = () => {
                        this.$emit('doneSuccessfully');
                        event.emit('updatedFaces');
                        this.closeDialog();
                    };
                    const failureCallback = (error) => {
                        this.showError(error, '顔マスタ一括作成エラー');
                    };
                    awaitJobDone(jobId, true)
                        .then(successCallback)
                        .catch(failureCallback);

                })
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, '顔マスタ一括作成エラー');
                });
        },
        removeSelectedFacebases() {
            if (!this.selectedFacebases.length) {
                return;
            }

            http
                .put('photos/facebases_candidate/reject', {
                    photo_ids: this.selectedFacebases,
                })
                .then(() => {
                    this.initialize();
                })
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, '顔マスタ基写真削除エラー');
                });
        },
        deletePhotos() {
            monet.NEL.fromArray(this.selectedFacebases)
                .toValidation('写真を選択してください。')
                .cata(
                    error => this.showError(error, '写真削除エラー'),
                    photo_ids => http.
                        delete('photos/delete', {
                            photo_ids: photo_ids.toArray()
                        })
                        .then(() => {
                            this.showInfo('選択写真の削除が完了しました。', '写真削除');
                            this.fetchFaceBasesCandidate();
                            this.selectedFacebases = [];
                        })
                        .catch(error => {
                            if (error === 'unauthorized') return;
                            this.showError(error, '写真削除エラー');
                        })
                );
        },
        fetchFaceBasesCandidate() {
            http
                .get(urlUtil.addQueryParamIfDefined('photos/facebases_candidate', 'album_id', this.albumId))
                .then(res => {
                    console.log(res.data);
                    this.facebases_candidate = res.data;
                })
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, '顔マスタ基写真取得エラー');
                });
        },
    },
};
</script>