<template>
    <page-container>
        <template slot="top">
            <v-container class="ma-0 pa-0">
                <v-row dense>
                    <v-col cols="2">
                        <v-select
                            v-model="storageData.customerType"
                            :items="customerTypes"
                            label="顧客区分"
                            item-text="name"
                            clearable
                            return-object                       
                            dense
                            outlined
                            hide-details
                            @change="searchCustomers"                      
                        />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field
                            v-model="storageData.name"
                            label="顧客名（部分一致）"
                            clearable
                            return-object                       
                            dense
                            outlined
                            hide-details
                            @change="searchCustomers"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </template>

        <template slot="middle">
            <v-data-table
                :headers="headers"
                :items="customers"
                :items-per-page="20"
                :footer-props="{
                    'items-per-page-options': [10, 20, 30, 40, 50, -1]
                }"
                dense
                tile
            >
                <template v-slot:item.edit="{ item }">
                    <v-btn 
                        tile 
                        x-small 
                        color="accent"
                        @click.stop="editCustomer(item.id)"
                    >
                        編集
                    </v-btn>
                </template>
            </v-data-table>
        </template>

        <template slot="bottom">
            <v-dialog v-model="storageData.customerDialog" persistent max-width="400">
                <v-form ref="form" lazy-validation>
                    <v-card class="pa-3">
                        <v-container>
                            <v-row>
                                <v-autocomplete
                                    v-model="storageData.customer.customerType"
                                    :rules="rules.customerTypeRules"
                                    :items="customerTypes"
                                    label="顧客区分"
                                    item-text="name"
                                    return-object
                                />
                            </v-row>
                            <v-row>
                                <v-text-field
                                    v-model="storageData.customer.name"
                                    :rules="rules.nameRules"
                                    label="顧客名【必須】"
                                    counter="30"
                                    required
                                />
                            </v-row>
                        </v-container>
                        <v-container class="px-3">
                            <v-row justify="space-between">
                                <cancel-dialog-button width="100" @click="closeCustomerDialog">
                                    キャンセル
                                </cancel-dialog-button>
                                <proceed-dialog-button width="100" @click="registerCustomer">
                                    登録
                                </proceed-dialog-button>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-form>
            </v-dialog>
        </template>
    </page-container>
</template>

<script>
import event from '../../utils/event';
import http from '../../services/http';
import urlUtil from '../../utils/url';
import leaveConform from '../../mixins/leave-confirm-mixin';
import monet from 'monet';

export default {
    mixins: [leaveConform],
    data () {
        return {
            viewID: 'CU01-A00',
            storageData: {
                isInitialized: false,
                customerType: undefined,
                name : undefined,
                customer: {
                    id : 0,
                    customerTypeId : 0,
                    customerType : undefined,
                    name : '',
                    updatedAt : undefined,
                },
                modeUpdate: false,
                customerDialog: false,
            },
            headers: [
                {
                    text: '顧客区分',
                    value: 'customer_type.name',
                    width: 100, 
                },
                { 
                    text: '顧客名', 
                    value: 'name',
                },
                { 
                    text: '編集', 
                    value: 'edit', 
                    align: 'center', 
                    sortable: false, 
                    width: 64, 
                },
            ],
            rules: {
                customerTypeRules: [
                    v => !!v || '選択してください。',
                ],
                nameRules:[
                    v => !!v || '入力してください。',
                    v => !!v && v.length <= 30 || '30文字以内で入力してください。',
                ], 
            },
            httpState: http.state,
            customerTypes: [],
            customers: [],
        };
    },
    created () {
        this.$root.init();

        event
            .on('saveStorage', () => {
                if (this.storageData.isInitialized == true) {
                    this.saveStorageData();
                }
            });
    },
    mounted() {
        this.$root.setBreadcrumbs([
            {
                text: '顧客管理',
                disabled: true,
                to: '',
            },
        ]); // ヘッダ部のパンくずリストを設定する。

        this.$root.setHeaderActions([
            {
                name: '顧客追加',
                consumer: () => this.storageData.customerDialog = true,
                icon: 'add_box',
            },
        ]); // ヘッダアクションを設定する。

        this.restoreStorageData();

        Promise
            .all([
                this.fetchCustomerTypes(),
                this.fetchCustomers(),
            ]) 
            .then(() => this.storageData.isInitialized = true )
            .catch(error => {
                if (error === 'unauthorized') return;
                this.showError('初期化処理に失敗しました。', '初期化エラー', error);
            });
    },
    beforeDestroy() {
        event.off('saveStorage');
    },
    methods: {
        fetchCustomerTypes () {
            return http.get('customer_types').then(res => this.customerTypes = res.data);
        },
        fetchCustomers () {
            return http
                .get((url => {
                    monet.Maybe.fromNull(this.storageData.customerType)
                        .forEach(type => url = urlUtil.addQueryParamIfDefined(url, 'customer_type_id', type.id));

                    monet.Maybe.fromNull(this.storageData.name)
                        .forEach(name => url = urlUtil.addQueryParamIfDefined(url, 'name', name));

                    return url;

                })('customers'))
                .then(res => this.customers = res.data);
        },
        searchCustomers () {
            this.fetchCustomers()
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, '検索に失敗しました。');
                });
        },
        editCustomer (id) {
            http
                .get('customers/' + id + '/edit')
                .then(res => {

                    this.storageData.customer.id = res.data.id;
                    this.storageData.customer.customerType = res.data.customer_type;
                    this.storageData.customer.name = res.data.name;
                    this.storageData.customer.updatedAt = res.data.updated_at;

                    this.confirmLeaveReset(['storageData', 'customer']);
                    
                    this.storageData.modeUpdate = true;
                    this.storageData.customerDialog = true;
                })
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, 'データの取得に失敗しました。');
                });
        },
        registerCustomer () {
            if (!this.$refs.form.validate()) return; // 入力チェックに違反したら以降の処理をしない。

            (this.storageData.modeUpdate ? this.updateCustomer : this.createCustomer)
                .apply()
                .then(() => {
                    this.confirmLeaveReset(null);
                    this.searchCustomers();
                    this.closeCustomerDialog();
                })
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, '登録に失敗しました。');
                });
        },
        createCustomer () {
            return http
                .post('customers/store', {
                    customer_type_id: this.storageData.customer.customerType.id,
                    name: this.storageData.customer.name,
                });
        },
        updateCustomer () {
            return http
                .put('customers/' + this.storageData.customer.id, {
                    customer_type_id: this.storageData.customer.customerType.id,
                    name: this.storageData.customer.name,
                    updated_at: this.storageData.customer.updatedAt,
                });
        },
        async closeCustomerDialog () {
            if (await this.confirmLeave() === false) return;

            this.storageData.dialog = false;
            this.$refs.form.reset();

            setTimeout(() => {
                this.initStorageData();
                this.confirmLeaveReset(['storageData', 'customer']);
            }, 10);
        },
        initStorageData() {
            this.storageData.customerDialog = false;
            this.storageData.modeUpdate = false;

            this.storageData.customer.id = 0;
            this.storageData.customer.customerTypeId = 0;
            this.storageData.customer.customerType = undefined,
            this.storageData.customer.name = '';
            this.storageData.customer.submitedDataDirectoryName = '';
            this.storageData.customer.updatedAt = undefined;

        },
    },
};
</script>