export default {
    addQueryParamIfDefined (url, key, value) {

        var delimiter = url.indexOf('?') < 0 ? '?' : '&';

        if(value) {
            // URLでは使用できるが、SQLのLIKE句でワイルドカードになってしまう2文字をエスケープ
            url = url + delimiter + key + '=' + encodeURIComponent(value);
        }

        return url;
    }
};
