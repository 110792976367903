<template>
    <v-dialog
        v-model="computedDisplay"
        hide-overlay
        scrollable
        :width="computedWidth"
        :position-x="positionX"
        :position-y="positionY"
        transition="slide-y-transition"
        @click:outside="closeDialog"
    >
        <v-card
            :height="computedHeight"
            :width="computedWidth"
            outlined
            elevation="24"
        >
            <v-card-title>
                <v-container>
                    <v-row>
                        <v-col v-if="photoFace.face" cols="2" offset="3">
                            <v-container :style="{'background-color': $root.getTheme().background}"
                                         class="ma-0 pt-0 pb-1"
                            >
                                <v-row justify="center" class="ma-0 pa-0 text-center" dense>
                                    <v-col cols="12" class="ma-0 pa-0 text-center text-caption">
                                        {{ selectedFace ? '選択中の顔マスタ:' + selectedFace.no : '現在の顔マスタ:' + photoFace.face.no }}
                                    </v-col>
                                </v-row>
                                <v-row justify="center" class="ma-0 pa-0" dense>
                                    <v-col cols="12" class="ma-0 pa-0 flex-center">
                                        <v-img
                                            :src="selectedFace ? selectedFace.face_image_path : photoFace.face.face_image_path"
                                            contain
                                            height="80"
                                            width="60"
                                        />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-col>
                        <v-col cols="2">
                            <!-- <v-row>
                                <span>
                                    {{ 
                                        selectedFace ? '選択中の顔マスタが正しければ「変更」ボタンをクリックしてください'
                                        : '検出顔と同一人物の正しい顔マスタを一覧から選択してください' 
                                    }}
                                </span>
                            </v-row> -->
                            <v-row justify="center">
                                <arrow 
                                    height="30px" width="100px" 
                                    :color="selectedFace ? '#EF4E5B' : 'white' "
                                />
                            </v-row>
                        </v-col>
                        <v-col cols="2" class="ml-4">
                            <v-container :style="{'background-color': $root.getTheme().background}"
                                         class="ma-0 pt-0 pb-1"
                            >
                                <v-row justify="center" class="ma-0 pa-0 text-center" dense>
                                    <v-col cols="12" class="ma-0 pa-0 text-center text-caption">
                                        検出顔
                                    </v-col>
                                </v-row>
                                <v-row justify="center" class="ma-0 pa-0" dense>
                                    <v-col cols="12" class="ma-0 pa-0 flex-center">
                                        <v-img
                                            :src="photoFace.detected_image_path"
                                            contain
                                            height="80"
                                            width="60"
                                        />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-title>
            <v-card-text class="overlay-scrollable">
                <v-container :v-if="sortedFaces.length != 0">
                    <v-row class="ma-0 p-0" dense>
                        <v-col
                            v-for="f in sortedFaces"
                            :key="f.id"
                            cols="1"
                        >
                            <v-container :style="{'background-color': f.is_selected ? '#228b22' : $root.getTheme().background}"
                                         class="ma-0 pt-0 pb-1"
                                         @click="toggleSelectFace(f)"
                            >
                                <v-row justify="center" class="ma-0 pa-0 text-center" dense>
                                    <v-col cols="12" class="ma-0 pa-0 text-center">
                                        {{ f.no }}
                                    </v-col>
                                </v-row>
                                <v-row justify="center" class="ma-0 pa-0" dense>
                                    <v-col cols="12" class="ma-0 pa-0 flex-center">
                                        <v-tooltip bottom :disabled="!f.is_existing">
                                            <template #activator="{ on }">
                                                <v-img
                                                    :src="f.face_image_path"
                                                    contain
                                                    height="80"
                                                    width="60"
                                                    v-on="on"
                                                />
                                            </template>
                                            <span>
                                                同じ写真内の他の検出顔に紐づいている顔マスタです
                                            </span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-col>
                        <v-spacer />
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-container> 
                    <v-row dense justify="space-between">
                        <cancel-dialog-button width="100" @click="closeDialog">
                            キャンセル
                        </cancel-dialog-button>
                        <v-spacer />
                        <proceed-dialog-button width="150" :disabled="!selectedFace" @click="applyFaceSelect">
                            適用
                        </proceed-dialog-button>
                    </v-row>
                </v-container>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    components:{
        'arrow': require('../Misc/Arrow.vue').default,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        photoFace: {
            type: Object,
            default: () => []
        },
        faces: {
            type: Array,
            default: () => []
        },
        faceIdsExistingInPhoto:{
            type: Array,
            default: () => []
        },
        positionY: {
            type: Number,
            default: 0,
        },
        width: {
            type: String,
            default:'200px',
        },
    },
    data () {
        return {
            position_x: 0,
            selectedFace: undefined,
        };
    },
    computed: {
        computedWidth: function () {
            return innerWidth * 0.6;
        },
        computedHeight: function () {
            return innerHeight * 0.8;
        },
        sortedFaces: function() {
            const faces_copy = this.faces.concat();
            return faces_copy
                .filter((f) => {
                    return this.photoFace && this.photoFace.face ? f.id != this.photoFace.face.id : false;
                })
                .sort((a, b) => {return a.no - b.no;})
                .map(f => {
                    f.is_selected = this.selectedFace ? f.id == this.selectedFace.id : false;
                    f.is_existing = this.faceIdsExistingInPhoto.includes(f.id);
                    return f;
                });
        },
        positionX: function () {
            return innerWidth * 0.2;
        },
        // positionY: function () {
        //     return this.position_y;
        // },
        computedDisplay: {
            set: function (value) {
                this.$emit('input', value);
            },
            get: function() {
                return this.value;
            },
        },
    },
    mounted() {
    },
    methods: {
        applyFaceSelect () {
            this.$emit('face-selected', this.selectedFace.id);
            this.closeDialog();
        },
        toggleSelectFace (selectedFace) {
            if (selectedFace.is_existing) {
                this.showInfo('同じ写真内の他の検出顔に紐づいている顔マスタです。', '対象の顔マスタは選択できません');
                return;
            }
            if (this.selectedFace && this.selectedFace.id == selectedFace.id) {
                this.resetFaceSelect();
            } else {
                this.selectedFace = selectedFace;
            }
        },
        resetFaceSelect () {
            this.selectedFace = undefined;
        },
        closeDialog () {
            this.computedDisplay = false;
            this.resetFaceSelect();
        }
    },
};

</script>