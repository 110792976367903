<template>
    <v-main v-if="isInDialog == false" class="pa-0 mb-0">
        <slot name="navigation" />
        <v-flex class="ma-0 pa-0">
            <slot name="header" />
            <slot name="fill-contnts" />
        </v-flex>
        <div class="ma-4">
            <v-flex>
                <slot name="top" />
            </v-flex>
            <v-flex>
                <slot name="middle" />
            </v-flex>
            <v-flex>
                <slot name="bottom" />
            </v-flex>
        </div>
    </v-main>
    <v-card v-else ref="card" class="pa-4">
        <div class="fill-height">
            <v-flex>
                <slot name="top" />
            </v-flex>
            <v-flex>
                <slot name="middle" />
            </v-flex>
            <v-flex>
                <slot name="bottom" />
            </v-flex>
        </div>
    </v-card>
</template>

<script>
export default {
    props: {
        isInDialog: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
        };
    },
    mounted () {
    },
    beforeDestroy () {
    },
    methods: {
    }
};
</script>

<style scoped>
</style>
