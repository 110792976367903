import axios from 'axios';
import event from '../utils/event';

/**
 * Responsible for all HTTP requests.
 */

const state = {
    loading: false,
    loadingQueue: [],
}
const pushCommunicate = () => {
    state.loadingQueue.push({});
    state.loading = true;
}
const shiftCommunicate = () => {
    state.loadingQueue.shift();

    if (state.loadingQueue.length == 0) {
        state.loading = false;
    }
}

const defaultInstance = axios.create({
    baseURL: '/api',
});
const backGroundInstance = axios.create({
    baseURL: '/api',
});
console.log(defaultInstance);

defaultInstance.interceptors.request.use(config => {
    //event.emit('spinner:start')
    pushCommunicate();

    // config.headers['Authorization'] = `Bearer ${localStorage.getItem('jwt-token')}`;
    // config.headers['X-CSRF-TOKEN'] = window.Laravel.csrfToken;
    config.headers['X-Requested-With'] = 'XMLHttpRequest';
    return config;
});

defaultInstance.interceptors.response.use(
    response => {
        shiftCommunicate();

        const token = response.headers['Authorization'] || response.data['token'];
        if (token) {
            localStorage.setItem('jwt-token', token);
        }
        return response;
    },
    error => {
        shiftCommunicate();

        if (error.response && ((error.response.status === 400 && error.response.data.error === 'token_not_provided') || error.response.status === 401)) {

            event.emit('saveStorage');
            event.emit('unauthorized');

            if (error.response.config.url.indexOf('authenticate') < 0) {
                return Promise.reject('unauthorized');
            }
        }

        return Promise.reject(error.response.data);
    });

export default {
    defaultInstance: {
        default: defaultInstance,
        type: axios.AxiosInstance,
    },
    backGroundInstance: {
        default: backGroundInstance,
        type: axios.AxiosInstance
    },
    state: state,
    request(method, url, data) {
        console.log(url);
        console.log(defaultInstance.baseURL);
        return defaultInstance.request({
            url,
            data,
            method: method.toLowerCase()
        });
    },
    backGroundRequest(method, url, data) {
        return backGroundInstance.request({
            url,
            data,
            method: method.toLowerCase()
        });
    },
    get(url) {
        return this.request('get', url, {});
    },
    getBlob(url) {
        return defaultInstance.get(url, { responseType: 'blob', dataType: 'binary', });
    },
    post(url, data) {
        return this.request('post', url, data);
    },
    put(url, data) {
        return this.request('put', url, data);
    },
    delete(url, data = {}) {
        return this.request('delete', url, data);
    },
    async login(id, password) {
        await axios.get('/sanctum/csrf-cookie', {
            baseURL: ""
        })

        return this.post('/login', {
            login_id: id,
            password: password,
        })
    },
    async logout() {
        return this.get('/logout')
    },
    init: () => { console.log("http init") },
    pushCommunicate,
    shiftCommunicate
};
