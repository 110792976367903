<template>
    <page-container>
        <template slot="navigation" />

        <template slot="header">
            <v-toolbar dense color="secondary">
                <v-btn icon @click.stop="closeCallback">
                    <v-icon>close</v-icon>
                </v-btn>
                <v-toolbar-title v-if="!!storageData.isInitialized">
                    <span>{{ storageData.album.name + '- タグ管理' }}</span>
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items @click="storageData.tagEditDialog = true">
                    <icon-with-text 
                        text="タグ追加" 
                        :show-text="showText"
                        icon
                        style="ma-0"
                        icon-name="add_box"
                    />
                </v-toolbar-items>
            </v-toolbar>
        </template>

        <template slot="top">
            <v-container class="ma-0 pa-0">
                <v-row dense>
                    <v-col md="3">
                        <v-text-field
                            v-model="storageData.tagName"
                            label="タグ名（部分一致）"
                            clearable
                            return-object                       
                            dense
                            @change="searchTags"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </template>
        <template slot="middle">
            <v-data-table
                :headers="headers"
                :items="storageData.tags"
                :items-per-page="20"
                :footer-props="{
                    'items-per-page-options': [10, 20, 30, 40, 50, -1]
                }"
                dense
                tile
            >
                <template v-slot:item.chip="{ item }">
                    <v-chip
                        :color="item.back_color_code"
                        :text-color="item.fore_color_code"
                        x-small
                    >
                        {{ item.name }}
                    </v-chip>
                </template>               
                <template v-slot:item.edit="{ item }">
                    <v-btn 
                        tile 
                        x-small 
                        color="accent"
                        @click.stop="editTag(item)"
                    >
                        編集
                    </v-btn>
                </template>
            </v-data-table>
        </template>
        
        <template slot="bottom">
            <v-dialog v-model="storageData.tagEditDialog" persistent max-width="400">
                <v-form ref="tagForm" lazy-validation>
                    <v-card class="pa-3">
                        <v-container class="">
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        v-model="storageData.tag.name"
                                        :rules="rules.nameRules"
                                        label="タグ名【必須】"
                                        counter="10"
                                        required
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        v-model="storageData.tag.backColorCode"
                                        label="背景色"
                                        required
                                        disabled
                                    />
                                </v-col>
                                <v-col>
                                    <el-color-picker v-model="storageData.tag.backColorCode" />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        v-model="storageData.tag.foreColorCode"
                                        label="文字色"
                                        required
                                        disabled
                                    />
                                </v-col>
                                <v-col>
                                    <el-color-picker v-model="storageData.tag.foreColorCode" />
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container class="px-3">
                            <v-row justify="space-between">
                                <cancel-dialog-button width="100" @click="closeTagEditDialog">
                                    キャンセル
                                </cancel-dialog-button>
                                <proceed-dialog-button width="100" @click="registerTag">
                                    登録
                                </proceed-dialog-button>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-form>
            </v-dialog>
        </template>
    </page-container>
</template>

<script>
import event from '../../utils/event';
import http from '../../services/http';
import urlUtil from '../../utils/url';
import leaveConform from '../../mixins/leave-confirm-mixin';
import monet from 'monet';

export default {
    components: {
        'icon-with-text': require('../Icons/IconWithText.vue').default,
    },
    mixins: [leaveConform],
    props: {
        albumId: {
            default: 0,
        },
        closeCallback: {
            default: undefined,
        },
    },
    data () {
        return {
            viewID: '',
            httpState: http.state,
            storageData: {
                isInitialized: false,
                modeUpdate: false,
                tagEditDialog: false,
                album: undefined,
                tags: [],
                tagName: '',
                tag: {
                    id : 0,
                    albumId : 0,
                    name : '',
                    backColorCode : '#4FADF0',
                    foreColorCode : '#FFFFFF',
                    updatedAt : undefined,
                },
            },
            headers: [
                {
                    text: 'タグ名',
                    value: 'name', 
                },
                { 
                    text: '背景色', 
                    value: 'back_color_code',
                    sortable: false, 
                    width: 100,
                },
                {
                    text: '文字色', 
                    value: 'fore_color_code',
                    sortable: false, 
                    width: 100, 
                },
                { 
                    text: '表示例', 
                    value: 'chip',
                    sortable: false,                    
                },
                { 
                    text: '編集', 
                    value: 'edit', 
                    align: 'center', 
                    sortable: false, 
                    width: 64, 
                },
            ],
            rules: {
                nameRules:[
                    v => !!v || '入力してください。',
                    v => !!v && v.length <= 10 || '10文字以内で入力してください。',
                ], 
            },
            showText: true,
        };
    },
    mounted() {
        event
            .on('saveStorage', () => {
                if (this.storageData.isInitialized) {
                    this.saveStorageData();
                }
            });

        if (!this.restoreStorageData()) {
            this.initialize()
                .then(res => {
                    this.storageData.isInitialized = true;
                    this.confirmLeaveReset(['storageData']);
                })
                .catch(error => {
                    if(error === 'unauthorized') return;
                    this.showError('初期化処理に失敗しました。', '初期化エラー', error);
                });
        }
    },
    beforeDestroy() {
        event.off('saveStorage');
    },
    methods: {
        initialize () {
            return Promise.all([
                this.fetchAlbum(),
                this.fetchTags(),
            ])
                .then(res => this.storageData.isInitialized = true);
        },
        fetchAlbum () {
            return http.get('albums/' + this.albumId).then(res => this.storageData.album = res.data);
        },
        fetchTags () {
            return http
                .get((url => {
                    url = urlUtil.addQueryParamIfDefined(url, 'album_id', this.albumId);
                    
                    return monet.Maybe.fromNull(this.storageData.tagName)
                        .map(name => urlUtil.addQueryParamIfDefined(url, 'name', name))
                        .orSome(url);

                })('tags'))
                .then(res => this.storageData.tags = res.data);
        },
        searchTags () {
            this.fetchTags()
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, '検索に失敗しました。');
                });
        },
        initStorageData() {
            this.storageData.tagDialog = false;
            this.storageData.modeUpdate = false;

            this.storageData.tag.id = 0;
            this.storageData.tag.albumId = this.albumId;
            this.storageData.tag.name = '';
            this.storageData.tag.backColorCode = '#4FADF0';
            this.storageData.tag.foreColorCode = '#FFFFFF';
            this.storageData.tag.updatedAt = undefined;
        },
        editTag (tag) {
            http
                .get('tags/' + tag.id + '/edit')
                .then(res => {
                    this.storageData.tag.id = res.data.id;
                    this.storageData.tag.albumId = res.data.album_id;
                    this.storageData.tag.name = res.data.name;
                    this.storageData.tag.backColorCode = res.data.back_color_code;
                    this.storageData.tag.foreColorCode = res.data.fore_color_code;
                    this.storageData.tag.updatedAt = res.data.updated_at;

                    this.confirmLeaveReset(['storageData', 'tag']);
                    
                    this.storageData.modeUpdate = true;
                    this.storageData.tagEditDialog = true;
                })
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, 'データの取得に失敗しました。');
                });
        },
        registerTag () {
            if (!this.$refs.tagForm.validate()) return; // 入力チェックに違反したら以降の処理をしない。

            (this.storageData.modeUpdate ? this.updateTag : this.createTag)
                .apply()
                .then(() => {
                    this.confirmLeaveReset(null);
                    this.searchTags();
                    this.closeTagEditDialog();
                    event.emit('updatedTags');
                })
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, '登録に失敗しました。');
                });
        },
        createTag () {
            return http
                .post('tags/store', {
                    album_id: this.albumId,
                    name: this.storageData.tag.name,
                    back_color_code: this.storageData.tag.backColorCode,
                    fore_color_code: this.storageData.tag.foreColorCode,
                });
        },
        updateTag () {
            return http
                .put('tags/' + this.storageData.tag.id, {
                    album_id: this.storageData.tag.albumId,
                    name: this.storageData.tag.name,
                    back_color_code: this.storageData.tag.backColorCode,
                    fore_color_code: this.storageData.tag.foreColorCode,
                    updated_at: this.storageData.tag.updatedAt,
                });
        },
        async closeTagEditDialog () {
            if (await this.confirmLeave() === false) return;

            this.storageData.tagEditDialog = false;
            this.$refs.tagForm.reset();

            setTimeout(() => {
                this.initStorageData();
                this.confirmLeaveReset(['storageData', 'tag']);
            }, 10);
        },
    },
};
</script>