import VueRouter from 'vue-router';
import Vue from 'vue';
import http from './services/http';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        { path: '/inhouse/', component: require('./components/Albums/Albums.vue').default },
        { path: '/inhouse/albums', component: require('./components/Albums/Albums.vue').default },
        { path: '/inhouse/albums/album', component: require('./components/Albums/Album.vue').default, meta: { requiresCloudFrontCookie: true } },
        { path: '/inhouse/customers', component: require('./components/Customers/Customers.vue').default },
        { path: '/inhouse/settings', component: require('./components/Settings/Setting.vue').default },
        { path: '/inhouse/faces', component: require('./components/Faces/Faces.vue').default },
        { path: '/inhouse/prototypes/sandbox', component: require('./components/Prototypes/Sandbox.vue').default },

        { path: '*', component: require('./components/Albums/Albums.vue').default },
    ],
    scrollBehavior(to, from, savedPosition) {
        return savedPosition ? savedPosition : { x: 0, y: 0 };
    },
});

const getSignedCookie = (albumId) => {
    return http.get('/albums/' + albumId + '/signed_cookie');
};

router.beforeEach((to, from, next) => {
    http.get('/me')
        .catch((e) => {
            location.href = '/login.html';
            return;
        });

    const requiresCloudFrontCookie = to.matched.some(record => record.meta.requiresCloudFrontCookie);

    console.log(to.params);

    if (requiresCloudFrontCookie
        // && to.params.album_id
    ) {
        getSignedCookie(to.query.album_id);
    }
    next();

});


export default router;
