<template>
    <dialog-button v-bind="$attrs" color="accent" @click="$emit('click')">
        <slot />
    </dialog-button>
</template>

<script>
export default {
    components:{
        'dialog-button': require('./DialogButton.vue').default,
    },
};
</script>