<template>
    <div>
        <component :is="'style'">
            .arrow::before {
            position:absolute;
            content:"";
            width:0;
            height:0 ;
            border:{{ height }} solid transparent;
            border-left:{{ height }} solid {{ color }};
            left:{{ width }};
            top:{{ arrowHeadTop }};
            }
        </component>
        <div class="arrow" :style="arrowStyle" />
    </div>
</template>

<script>
export default {
    props:{
        height: {
            type: String,
            default: '30px',
        },
        width: {
            type: String,
            default: '80px',
        },
        top: {
            type: String,
            default:'40px',
        },
        color: {
            type: String,
            default:'#5bc0de',
        }
    },
    computed: {
        arrowStyle: function() {
            return {
                display:'inline-block',
                height: this.height,
                width: this.width,
                backgroundColor: this.color,
                position:'relative',
                top: this.top,
            };
        },
        arrowHeadTop: function() {
            return -Math.ceil(parseInt(this.height) / 2)  + 'px';
        }
    },
};
</script>

<style scoped>
/* .arrow {
  display:inline-block;
  height:40px;
  width:80px;
  background-color:#5bc0de;
  position:relative;
  top:40px;
} */

/* .arrow:before {
  position:absolute;
  content:"";
  width:0;
  height:0;
  border:60px solid transparent;
  border-left:60px solid #5bc0de;
  left:80px;
  top:-37px;
} */
</style>