export default {
    methods:{
        toAuthenticableClassName (status) {
            return status 
                ? 'flexible-bg-color align-center-middle' 
                : 'flexible-bg-color align-center-middle disauthenticable';
        },
        toRetirementClassName (status) {
            return status 
                ? 'flexible-bg-color align-center-middle retirement' 
                : 'flexible-bg-color align-center-middle';
        },
        toAllowAnydayInputClassName (status) {
            return status 
                ? 'flexible-bg-color align-center-middle allow-anyday-input' 
                : 'flexible-bg-color align-center-middle';
        },
        toWhetherWasOrderedAtMonthClassName (status) {
            return status 
                ? 'flexible-bg-color align-center-middle orderd-at-currnt-month' 
                : 'flexible-bg-color align-center-middle';
        },
        toWhetherPostingAtMonthClassName (status) {
            return status 
                ? 'flexible-bg-color align-center-middle orderd-at-currnt-month' 
                : 'flexible-bg-color align-center-middle';
        },
    }
};
