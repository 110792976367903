<template>
    <page-container>
        <template slot="fill-contnts">
            <shootings
                v-if="storageData.isInitialized && storageData.activeComponent == 'shootingsVue'"
                ref="shootingsVue"
                :album-id="id"
                :select-callback="applyUpdateShooting"
                :create-callback="createShooting"
                :edit-callback="editShooting"
                :only-unsubmited.sync="storageData.onlyUnsubmited"
            />
            <shooting-photos
                v-if="storageData.isInitialized && storageData.activeComponent == 'shootingPhotosVue'"
                ref="shootingPhotosVue"
                :album-id="id"
                :shooting-id="storageData.activeShootingId"
                @change-active-component="changeActiveComponent"
            />
        </template>
        <template slot="top" />
        
        <template slot="middle" />
        
        <template slot="bottom">
            <!-- タグ管理ダイアログ -->
            <v-dialog 
                v-model="storageData.tagsDialog" 
                persistent
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <v-card :color="$root.getTheme().background">
                    <tags
                        ref="tagsVue"
                        :album-id="id"
                        :close-callback="closeTagsDialog"
                    />
                </v-card>
            </v-dialog>

            <!-- 顔マスタ管理ダイアログ -->
            <v-dialog 
                v-model="storageData.facesDialog" 
                persistent
                fullscreen
                hide-overlay                
                transition="dialog-bottom-transition"
            >
                <v-card :color="$root.getTheme().background">
                    <faces
                        ref="facesVue"
                        :album-id="id"
                        :close-callback="closeFacesDialog"
                    />
                </v-card>
            </v-dialog>

            <!-- 撮影行事登録ダイアログ -->
            <v-dialog v-model="storageData.shootingDialog" persistent max-width="400">
                <v-form ref="shootingForm" lazy-validation>
                    <v-card class="pa-3">
                        <v-container>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        v-model="storageData.shooting.name"
                                        :rules="rules.shootingRules.nameRules"
                                        label="撮影行事名【必須】"
                                        counter="30"
                                        required
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        v-model="storageData.shooting.expectPhotoAmount"
                                        label="予定枚数"
                                        :rules="rules.pageSpreadRules.expectPhotoAmountRules"
                                        return-object
                                        type="number"
                                        min="1"
                                        max="999"
                                        clearable
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col md="6">
                                    <v-menu
                                        v-model="storageData.shootingDatePicker"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290"
                                        min-width="290"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                slot="activator"
                                                :value="formatDate(storageData.shooting.date)"
                                                label="撮影日"
                                                append-icon="event"
                                                readonly
                                                v-on="on"
                                            />
                                        </template>
                                        <v-date-picker
                                            v-model="storageData.shooting.date"
                                            locale="ja-jp"
                                            no-title
                                            :day-format="date => new Date(date).getDate()"
                                            @input="storageData.shootingDatePicker = false"
                                        />
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        v-model="storageData.shooting.photographerName"
                                        :rules="rules.shootingRules.photographerNameRules"
                                        label="撮影者名"
                                        counter="30"
                                    />
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container class="px-3">
                            <v-row justify="space-between">
                                <cancel-dialog-button width="100" @click="closeShootingDialog">
                                    キャンセル
                                </cancel-dialog-button>
                                <proceed-dialog-button width="100" @click="registerShooting">
                                    登録
                                </proceed-dialog-button>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-form>
            </v-dialog>
        </template>
    </page-container>
</template>

<script>
import event from '../../utils/event';
import http from '../../services/http';
import urlUtil from '../../utils/url';
import leaveConform from '../../mixins/leave-confirm-mixin';
import monet from 'monet';

export default {
    mixins: [leaveConform],
    data () {
        return {
            viewID: '',
            drawer: false,
            id: 0, 
            storageData: {
                isInitialized: false,
                tagsDialog: false,
                facesDialog: false,
                // アルバム
                activeComponent: 'shootingsVue',
                // 撮影行事
                shootingDialog: false,
                shootingModeUpdate: false,
                activeShootingId: 0,
                shooting: {
                    id: 0,
                    name: '',
                    date: undefined,
                    photographerName: '',
                    updatedAt: undefined,
                },
                shootingDatePicker: false,
                // ページ構成
                pageSpreadDialog: false,
                pageSpreadModeUpdate: false,
                activePageSpreadId: 0,
                pageSpread: {
                    id: 0,
                    name: '',
                    officialName: '',
                    expectPhotoAmount: undefined,
                    note: '',
                    updatedAt: undefined,
                },
                onlyUnsubmited: false,
            },
            rules: {
                shootingRules: {
                    nameRules: [
                        v => !!v || '入力してください。',
                        v => !!v && v.length <= 30 || '30文字以内で入力してください。',
                    ],
                    photographerNameRules: [
                        v => !v || !!v && v.length <= 30 || '30文字以内で入力してください。',
                    ],
                },
                pageSpreadRules: {
                    nameRules: [
                        v => !!v || '入力してください。',
                        v => !!v && v.length <= 30 || '30文字以内で入力してください。',
                    ],
                    expectPhotoAmountRules: [
                        v => !v || v && Math.round(v) == v || '整数値を入力してください。',
                        v => !v || v && (v >= 1 && v <= 999) || '1～999の範囲で入力してください。',
                    ],
                },
            },
            httpState: http.state,
            tags: [],
            shootings: [],
            pageSpreads: [],
            photoDirectories: [],
            notCreatedFaceMasterButtonBadge: {
                value: true,
                content: '!未作成',
                color: '#1e1e1e',
            },
            testVal: false,
        };
    },
    computed: {
        faceMasterButtonBadge: function () {
            // WIP 顔マスタ作成済みかどうかで切り替えたい
            return true ? {value: false} : this.notCreatedFaceMasterButtonBadge;
        },
        sexRatio() {
            return this.album.sex_ratio ? {
                manRatio: Math.round(this.album.sex_ratio * 100) + '%',
                womanRatio: Math.round((1 - this.album.sex_ratio) * 100) + '%'  
            } : false;
        },
    },
    components:{
        'tags': require('../Tags/Tags.vue').default,
        'faces': require('../Faces/Faces.vue').default,
        'shootings': require('../Shootings/Shootings.vue').default,
        'shooting-photos': require('../Shootings/ShootingPhotos.vue').default,
    },
    watch: {
        'storageData.onlyUnsubmited'() {
            Promise
                .all([
                    this.fetchShootings(),
                    this.fetchPageSpreads(),
                ])
                .then(() => this.makeShootings())
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError('初期化処理に失敗しました。', '初期化エラー', error);
                });
        },
    },
    created () {
        this.$root.init();

        event
            .on('saveStorage', () => {
                if (this.storageData.isInitialized == true) {
                    this.saveStorageData();
                }
            });
        event
            .on('orderedToOpenShooting', (shootingId) => {
                if (this.storageData.isInitialized == true) {
                    this.applyUpdateShooting(shootingId);
                }
            });
        event
            .on('orderedToOpenShootings', () => {
                if (this.storageData.isInitialized == true) {
                    this.changeActiveComponent('shootingsVue');
                }
            });
    },
    mounted() {
        this.id = this.$route.query.album_id;

        this.$root.setHeaderActions([
            {
                name: 'タグ管理',
                consumer: this.openTagsDialog,
                icon: 'local_offer',
            },
            {
                name: '顔マスタ管理',
                consumer: this.openFacesDialog,
                icon: 'face',
                badgeAttrs: this.faceMasterButtonBadge,
            },
        ]); // ヘッダアクションを設定する

        this.restoreStorageData();

        Promise
            .all([
                this.fetchAlbum(),
                this.fetchShootings(),
                this.fetchPageSpreads(),
                this.fetchTags(),
            ])
            .then(() => {
                this.makeShootings();
                this.storageData.isInitialized = true;
                event.emit('albumSelected', this.album);
            })
            .catch(error => {
                if (error === 'unauthorized') return;
                this.showError('初期化処理に失敗しました。', '初期化エラー', error);
            });
    },
    beforeDestroy() {
        event.off('saveStorage');
        event.off('orderedToOpenShooting');
        event.off('orderedToOpenShootings');
        event.emit('albumDisselected');
    },
    methods: {
        changeActiveComponent(component_name) {
            this.storageData.activeComponent = component_name;
        },
        fetchAlbum () {
            return http.get('albums/' + this.id)
                .then(res => {
                    this.album = res.data;

                    this.$root.setBreadcrumbs([
                        {
                            text: 'アルバム一覧',
                            disabled: false,
                            href: '/inhouse/albums',
                        },
                        this.sexRatio ? {
                            texts: [
                                {
                                    type: 'text',
                                    value: this.album.name + '（アルバム男女比： ',
                                },
                                {
                                    type:'iconSexRatio',
                                    value: {
                                        male: true,
                                        color: false,
                                    }
                                },
                                {
                                    type: 'text',
                                    value: this.sexRatio.manRatio,
                                },
                                {
                                    type:'iconSexRatio',
                                    value: {
                                        male: true,
                                        color: false,
                                    }
                                },
                                {
                                    type: 'text',
                                    value: this.sexRatio.womanRatio + ')',
                                },
                            ],
                            disabled: false,
                            href: '/inhouse/albums/album?album_id=' + this.id,
                        } 
                            : {
                                text: this.album.name,
                                disabled: false,
                                href: '/inhouse/albums/album?album_id=' + this.id,
                            },
                    ]); // ヘッダ部のパンくずリストを設定する。
                });
        },
        fetchShootings () {
            return http.get((url => {
                url = urlUtil.addQueryParamIfDefined(url, 'album_id', this.id);
                console.log(url);
                
                return url;
            })('shootings'))
                .then(res => this.shootings = res.data);
        },
        fetchPageSpreads () {
            return http.get(
                (url => {
                    url = urlUtil.addQueryParamIfDefined(url, 'album_id', this.id);
                    url = urlUtil.addQueryParamIfDefined(url, 'only_unsubmited', this.storageData.onlyUnsubmited);
                    return url;
                })('page_spreads')
            )
                .then(res => this.pageSpreads = res.data);
        },
        fetchTags () {
            return http.get('tags?album_id=' + this.id).then(res => this.tags = res.data);
        },
        getFacesCount () {
            return http
                .get(urlUtil.addQueryParamIfDefined('faces', 'album_id', this.id))
                .then(res => res.data.length)
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, '顔マスタ件数の読取に失敗しました。');
                });
        },
        makeShootings () {
            this.shootings = [
                {
                    id: 0,
                    name: '撮影行事',
                    icon: 'camera_alt',
                    children: this.shootings
                        .map(s => {
                            return {
                                id: s.id,
                                name: s.name,
                            };
                        }),
                },
            ];
        },
        applyUpdateShooting (shootingId) {
            this.storageData.activeShootingId = shootingId;
            this.storageData.activeComponent = 'shootingPhotosVue';

            monet.Maybe.fromNull(this.$refs.shootingPhotosVue)
                .forEach(vue => this.$nextTick(() => vue.initialize()));
        },
        initStorageData () {
        },
        // タグ管理系
        openTagsDialog () {
            this.storageData.tagsDialog = true;

            this.$nextTick(() => {
                this.$refs.tagsVue.initialize()
                    .catch(error => {
                        this.closeTagsDialog();

                        if (error === 'unauthorized') return;
                        this.showError('タグ一覧の初期化に失敗しました。', '初期化エラー', error);
                    });
            }); 
        },
        closeTagsDialog () {
            this.storageData.tagsDialog = false;
        },
        // 顔マスタ系
        openFacesDialog () {
            this.storageData.facesDialog = true;

            this.$nextTick(() => {
                this.$refs.facesVue.initialize()
                    .catch(error => {
                        this.closeFacesDialog();

                        if (error === 'unauthorized') return;
                        this.showError('顔マスタ一覧の初期化に失敗しました。', '初期化エラー', error);
                    });
            }); 
        },
        closeFacesDialog () {
            this.storageData.facesDialog = false;

            this.fetchAlbum()
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError('アルバム情報の取得に失敗しました。', 'アルバム情報取得エラー', error);
                });
        },
        // 撮影行事系
        createShooting () {
            this.initShootingStorageData();
            this.storageData.shootingDialog = true;
        },
        initShootingStorageData() {
            this.storageData.shootingDialog = false;
            this.storageData.shootingModeUpdate = false;

            this.storageData.shooting.id = 0;
            this.storageData.shooting.name = '';
            this.storageData.shooting.date = undefined;
            this.storageData.shooting.photographerName = '';
            this.storageData.shooting.updatedAt = undefined;
        },
        storeShooting () {
            return http
                .post('shootings/store', {
                    album_id: this.id,
                    name: this.storageData.shooting.name,
                    date: this.$moment(this.storageData.shooting.date).format('YYYY-MM-DD'),
                    photographer_name: this.storageData.shooting.photographerName,
                    expect_photo_amount: this.storageData.shooting.expectPhotoAmount,
                });
        },
        updateShooting () {
            return http
                .put('shootings/' + this.storageData.shooting.id, {
                    name: this.storageData.shooting.name,
                    date: this.$moment(this.storageData.shooting.date).format('YYYY-MM-DD'),
                    photographer_name: this.storageData.shooting.photographerName,
                    updated_at: this.storageData.shooting.updatedAt,
                    expect_photo_amount: this.storageData.shooting.expectPhotoAmount,
                });
        },
        editShooting (shootingId) {
            http
                .get('shootings/' + shootingId + '/edit')
                .then(res => {
                    this.storageData.shooting.id = res.data.id;
                    this.storageData.shooting.name = res.data.name;
                    this.storageData.shooting.date = res.data.date;
                    this.storageData.shooting.photographerName = res.data.photographer_name;
                    this.storageData.shooting.updatedAt = res.data.updated_at;
                    this.storageData.shooting.expectPhotoAmount = res.data.expect_photo_amount;

                    this.confirmLeaveReset(['storageData', 'shooting']);
                    
                    this.storageData.shootingModeUpdate = true;
                    this.storageData.shootingDialog = true;
                })
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, '撮影行事取得エラー');
                });
        },
        registerShooting () {
            if (!this.$refs.shootingForm.validate()) return; // 入力チェックに違反したら以降の処理をしない。

            (this.storageData.shootingModeUpdate ? this.updateShooting : this.storeShooting)
                .apply()
                .then(() => {
                    this.confirmLeaveReset(null);
                    this.fetchShootings().then(res => this.makeShootings());                    
                    this.closeShootingDialog();

                    this.$refs.shootingsVue.initialize();
                })
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, '撮影行事登録エラー');
                });
        },
        async closeShootingDialog () {
            if (await this.confirmLeave() === false) return;

            this.storageData.shootingDialog = false;
            this.$refs.shootingForm.reset();

            setTimeout(() => {
                this.initShootingStorageData();
                this.confirmLeaveReset(['storageData', 'shooting']);
            }, 10);
        },
        // ページ構成系
        createPageSpread () {
            this.initPageSpreadStorageData();
            this.storageData.pageSpreadDialog = true;
        },
        initPageSpreadStorageData() {
            this.storageData.pageSpreadDialog = false;
            this.storageData.pageSpreadModeUpdate = false;

            this.storageData.pageSpread.id = 0;
            this.storageData.pageSpread.name = '';
            this.storageData.pageSpread.officialName = '';
            this.storageData.pageSpread.expect_photo_amount = 0;
            this.storageData.pageSpread.updatedAt = undefined;
        },
        storePageSpread () {
            return http
                .post('page_spreads/store', {
                    album_id: this.id,
                    name: this.storageData.pageSpread.name,
                    official_name: this.storageData.pageSpread.officialName,
                    expect_photo_amount: this.storageData.pageSpread.expectPhotoAmount,
                });
        },
        updatePageSpread () {
            return http
                .put('page_spreads/' + this.storageData.pageSpread.id, {
                    name: this.storageData.pageSpread.name,
                    official_name: this.storageData.pageSpread.officialName,
                    expect_photo_amount: this.storageData.pageSpread.expectPhotoAmount,
                    updated_at: this.storageData.pageSpread.updatedAt,
                });
        },
        editPageSpread (pageSpreadId) {
            http
                .get('page_spreads/' + pageSpreadId + '/edit')
                .then(res => {
                    this.storageData.pageSpread.id = res.data.id;
                    this.storageData.pageSpread.name = res.data.name;
                    this.storageData.pageSpread.officialName = res.data.official_name;
                    this.storageData.pageSpread.expectPhotoAmount = res.data.expect_photo_amount;
                    this.storageData.pageSpread.updatedAt = res.data.updated_at;

                    this.confirmLeaveReset(['storageData', 'pageSpread']);
                    
                    this.storageData.pageSpreadModeUpdate = true;
                    this.storageData.pageSpreadDialog = true;
                })
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, 'ページ構成取得エラー');
                });
        },
        registerPageSpread () {
            if (!this.$refs.pageSpreadForm.validate()) return; // 入力チェックに違反したら以降の処理をしない。

            (this.storageData.pageSpreadModeUpdate ? this.updatePageSpread : this.storePageSpread)
                .apply()
                .then(() => {
                    this.confirmLeaveReset(null);
                    this.fetchPageSpreads().then(res => this.makeShootings());                   
                    this.closePageSpreadDialog();

                    this.$refs.pageSpreadsVue.initialize();
                })
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, 'ページ構成登録エラー');
                });
        },
        async closePageSpreadDialog () {
            if (await this.confirmLeave() === false) return;

            this.storageData.pageSpreadDialog = false;
            this.$refs.pageSpreadForm.reset();

            setTimeout(() => {
                this.initPageSpreadStorageData();
                this.confirmLeaveReset(['storageData', 'pageSpread']);
            }, 10);
        },
    },
};
</script>