<template>
    <page-container>
        <template slot="top">
            <v-dialog 
                persistent 
                v-model="storageData.frequencyDialog"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <v-card :color="$root.getTheme().background">
                    <frequency
                        ref="frequencyVue"
                        :albumId=3
                        :customerId=1
                        :closeCallback="closeFrequencyDialog"
                    />
                </v-card>
            </v-dialog>
        </template>
        <template slot="middle">
            <v-container grid-list-md text-xs-left>
                <v-layout row wrap>
                    <v-flex md3>
                        <v-text-field
                            v-model="storageData.editedItem.albumName"
                            label="ALBUM NAME"
                            required
                        />
                    </v-flex>
                    <v-flex md3>
                        <v-text-field
                            v-model="storageData.editedItem.albumSuffix"
                            label="ALBUM SUFFIX"
                            required
                        />
                    </v-flex>
                    <v-flex md3>
                        <v-text-field
                            v-model="storageData.editedItem.shootingName"
                            label="SHOOTING NAME"
                            required
                        />
                    </v-flex>
                    <v-btn 
                        class="mx-1" 
                        @click="storePhotoMany"
                    >
                        写真ファイル取込
                    </v-btn>
                </v-layout>
                <v-layout row wrap>
                    <v-text-field
                        v-model="storageData.editedItem.photoPath"
                        label="PHOTO PATH"
                        required
                    />
                    <v-btn class="mx-1" @click="readLabel">
                        ラベル読取
                    </v-btn>
                </v-layout>
                <v-layout row wrap>
                    <v-btn class="mx-1" @click="pythonTestFunc">
                        pythonテスト
                    </v-btn>
                </v-layout>
                <v-layout row wrap>
                    <v-btn class="mx-1" @click="pythonSimSearch">
                        類似検索
                    </v-btn>
                    <v-btn class="mx-1" @click="deleteAlbum">
                        アルバム削除
                    </v-btn>
                    <v-btn class="mx-1" @click="deletePhotoFace">
                        検出顔削除
                    </v-btn>
                </v-layout>
                <v-layout row wrap>
                    <v-btn class="mx-1" @click="addManualy">
                        手動追加
                    </v-btn>
                </v-layout>
                <v-layout row wrap>
                    <v-btn class="mx-1" @click="frequency">
                        出現頻度集計
                    </v-btn>
                    <v-btn class="mx-1" @click="openFrequencyDialog">
                        出現頻度ダイアログ
                    </v-btn>
                    <v-btn class="mx-1" @click="swap_candidate">
                        入れ替え候補取得
                    </v-btn>
                </v-layout>
                <v-layout row wrap>
                    <v-flex md2>
                        <v-text-field
                            v-model="storageData.color1"
                            label="COLOR1"
                            required
                            disabled
                        />
                    </v-flex>
                    <v-flex md1>
                        <el-color-picker v-model="storageData.color1" />
                    </v-flex>
                    <v-flex md2>
                        <v-text-field
                            v-model="storageData.color2"
                            label="COLOR2"
                            required
                            disabled
                        />
                    </v-flex>
                    <v-flex md1>
                        <el-color-picker v-model="storageData.color2" />
                    </v-flex>
                </v-layout>
                <v-layout row wrap>
                    <v-btn class="mx-1" @click="findBestCombination1">
                        おすすめ検出１
                    </v-btn>
                    <v-btn class="mx-1" @click="findBestCombination2">
                        おすすめ検出２
                    </v-btn>
                    <v-btn class="mx-1" @click="findBestCombination3">
                        おすすめ検出３
                    </v-btn>
                </v-layout>
            </v-container>
        </template>
        <template slot="bottom" />
    </page-container>
</template>

<script>
import event from '../../utils/event';
import http from '../../services/http';    
import urlUtil from '../../utils/url';
import userStore from '../../stores/user-store';
// import monet from 'monet';

import leaveConform from '../../mixins/leave-confirm-mixin';
import master from '../../mixins/master-mixin';

export default {
    mixins: [leaveConform, master],
    data () {
        return {
            viewID: 'SANDBOX-01',
            userState: userStore.state,
            storageData: {
                isInitialized: false,
                date: undefined,
                level: undefined,
                userArea: '',
                name: '',
                localName: '',
                month: undefined,
                includeDisabled: false,
                includeRetired: false,
                frequencyDialog: false,
                dialog: false,
                modeUpdate: false,
                editedItem: {
                    dirPath: 'C:\\Users\\Tanaka\\Desktop\\files\\from-dir',
                    photoPath: 'C:\\Users\\Tanaka\\Desktop\\ViewNXラベル機能連携\\test\\DSC_0005.JPG',
                    albumName: 'い-75高',
                    albumSuffix: '75高',
                    shootingName: '180712_75高-部活-稲葉',
                    label: '',
                },

                color1: '#0f68b1',
                color2: '#ef4e5b',
            },
            password: '',
            httpState: http.state,
            valid: false,
            users: [],
            userAreas: [],
            months: [],
            isAllSelected: false,
            levels : [ 
                { value: 101, name: '管理者' },
                { value: 51, name: '担当者' },
            ],
            retirementDatePicker: false,
        };
    },
    mounted () {
        console.log('sandbox mounted');

        this.$root.setBreadcrumbs([
            {
                text: 'SANDBOX',
                disabled: true,
                to: '',
            },
        ]);

        this.$root.setHeaderActions([]);
        this.$root.setTitle('SANDBOX');

        this.restoreStorageData();
    },
    created () {
        event.on('saveStorage', () => {
            if (this.storageData.isInitialized == true) {
                this.saveStorageData();
            }
        });
    },
    components:{
        frequency: require('../Frequency/Frequency.vue').default,
    },
    beforeDestroy() {
        event.off('saveStorage');
    },
    methods: {
        // copyFiles(e) {
        //     http
        //         .post('prototypes/shooting/store1', {})
        //         .then(res => {
        //             this.showInfo('写真ファイルの取込が完了しました。', '写真取込');
        //         })
        //         .catch(error => {
        //             if (error === 'unauthorized') return;
        //             this.showError(error, '写真ファイルの取込に失敗しました。');
        //         });
        // },
        storePhotoMany (e) {
            http
                .post('prototypes/photos/storeMany', {
                    album_name: this.storageData.editedItem.albumName,
                    album_suffix: this.storageData.editedItem.albumSuffix,
                    shooting_name: this.storageData.editedItem.shootingName,
                })
                .then(() => {
                    this.showInfo('写真ファイルの取込が完了しました。', '写真ファイル取込');
                })
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, '写真ファイルの取込に失敗しました。');
                });

            e.target.value = '';
        },
        storePhotos (e) {
            var path = e.target.files[0];
            if (path == null) {
                return;
            }

            console.log(path);

            var data = new FormData();
            data.append('csv', path);

            http
                .post('prototypes/photos/storeMany', data)
                .then(() => {
                    this.showInfo('写真ファイルの取込が完了しました。', '写真取込');
                    this.search();
                })
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, '写真ファイルの取込に失敗しました。');
                });

            e.target.value = '';
        },
        readLabel () {
            http
                .get('labels?photo_path=' + this.storageData.editedItem.photoPath)
                .then(res => {
                    alert(res.data.label);
                })
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, 'ラベル読取に失敗しました。');
                });
        },
        pythonTestFunc () {
            http
                .get('photoface')
                .then(res => {
                    alert(res.data);
                })
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, 'ラベル読取に失敗しました。');
                });
        },
        pythonSimSearch () {
            http
                .get('photoface/sim_search')
                .then(res => {
                    alert(res.data);
                })
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, 'ラベル読取に失敗しました。');
                });
        },
        addManualy () {
            http
                .get('photoface/add_manualy')
                .then(res => {
                    alert(res.data);
                })
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, 'ラベル読取に失敗しました。');
                });
        },
        frequency () {
            var url = 'photo_faces/frequency'
            url = urlUtil.addQueryParamIfDefined(url, 'album_id', 3);
            var ids =  [15, 16, 17, 19];
            ids.forEach(id => {
                url = urlUtil.addQueryParamIfDefined(url, 'photo_id_list[]',id);
            });
            

            http
                .get(url)
                .then(res => {
                    alert(res.data);
                })
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, 'ラベル読取に失敗しました。');
                });
        },
        swap_candidate () {
            var data = new FormData();
            data.append('album_id', 1);
            data.append('swap_purpose', "add");

            var face_id_list = [131, 130, 115];
            face_id_list.forEach((id, i) => {
                data.append('face_id_list' + '[]', id);
            })

            data.append('photo_directory_id', 1)
            data.append('candidate_num', 5)
            
            http
                .post('photos/swap_candidate', data)
                .then(res => {
                    alert(res.data);
                })
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, 'ラベル読取に失敗しました。');
                });
        },
        openFrequencyDialog () {
            this.storageData.frequencyDialog = true;

            this.$nextTick(() => {
                this.$refs.frequencyVue.initialize()
                    .catch(error => {
                        this.closeFrequencyDialog();

                        if (error === 'unauthorized') return;
                        this.showError('人物頻度一覧の初期化に失敗しました。', '初期化エラー');
                    });
            }); 
        },
        deleteAlbum() {
            var id = 1;

            http
                .delete('albums/' + id)
                .then((res) => alert(res))
        },
        deletePhotoFace() {
            var id = 8;
            http
                .delete(urlUtil.addQueryParamIfDefined('photo_faces/', 'photo_id', id))
                .then((res) => alert(res))
        },
        closeFrequencyDialog () {
            this.storageData.frequencyDialog = false;
        },
        findBestCombination1 () {
            http
                .get('prototypes/photos/find_best_combination1')
                .then(res => {
                    //alert(res.data);
                })
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, 'おすすめ検出に失敗しました。');
                });            
        },
        findBestCombination2 () {
            http
                .get('prototypes/photos/find_best_combination2')
                .then(res => {
                    //alert(res.data);
                })
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, 'おすすめ検出に失敗しました。');
                });            
        },
        findBestCombination3 () {
            http
                .get('prototypes/photos/find_best_combination3')
                .then(res => {
                    //alert(res.data);
                })
                .catch(error => {
                    this.showError(error, 'おすすめ検出に失敗しました。');
                });
        },
    },
};
</script>