<template>
    <div v-if="computedShowText" id="icon-with-text">
        <v-container class="with-text" @click="onClick">
            <v-row style="justify-content: center;">
                <div>
                    <v-badge v-bind="badgeAttrs">
                        <v-btn 
                            v-bind="$attrs"
                            x-small
                        >
                            <v-icon>{{ iconName }}</v-icon>
                        </v-btn>
                    </v-badge>
                </div>
            </v-row>
            <v-row style="justify-content: center;">
                <span :style="{ width: textWidth, opacity: opacity }">
                    {{ text }}
                </span>
            </v-row>
        </v-container>
    </div>
    <div v-else>
        <v-tooltip bottom>
            <template #activator="{ on }">
                <v-btn 
                    v-bind="$attrs"
                    @click="$emit('click')"
                    v-on="on"
                >
                    <v-icon> {{ iconName }}</v-icon>
                </v-btn>
            </template>
            <span>{{ text }}</span>
        </v-tooltip>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        text:{
            default: '',
            type: String,
        },
        showText:{
            default: true,
            type: Boolean,
        },
        iconName:{
            default:'',
            type: String,
        },
        badge: {
            default: false,
            type:Boolean,
        },
        badgeAttrs: {
            default: () => {
                return {
                    value: false,
                };
            },
            type: Object,
        },
    },
    data() {
        return {
        };
    },
    computed: {
        computedShowText() {
            return this.showText;
        },
        textWidth() {
            return this.text.length < 5 ? '48px'
                : String(12 * this.text.length) + 'px';
        },
        opacity() {
            return this.$attrs.disabled ? '.3' : '1'; 
        },
    },
    methods: {
        onClick() {
            if (!this.$attrs.disabled) {
                this.$emit('click');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    div #icon-with-text
    {
        height: 48px;
        padding-right: 2px;
        padding-left: 2px;
        position: relative;
    }
    .with-text
    {
        margin-top: -6px;
        cursor : pointer;
        &::before{
            border-radius: inherit;
            bottom: 0;
            color: inherit;
            content: "";
            left: 0;
            opacity: 0;
            pointer-events: none;
            position: absolute;
            right: 0;
            top: 0;
            transition: opacity .2s cubic-bezier(.4,0,.6,1);
            background-color: currentColor;
        }
        &:hover {
            &::before{
                opacity:.16;
            }
        }
    }
    div #icon-with-text v-btn
    {
        height: 32px;
        width: 32px;
    }
    span
    {
        text-align: center;
    }
</style>