<template>
    <page-container>
        <template slot="navigation" />

        <template slot="header">
            <v-toolbar dense color="secondary">
                <v-btn icon @click.stop="closeCallback">
                    <v-icon>close</v-icon>
                </v-btn>
                <v-toolbar-title v-if="!!storageData.isInitialized">
                    <span>{{ storageData.album.name + "- 人物頻度" }}</span>
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items
                    @click.stop="
                        () => {
                            outputFrequencyDoc();
                        }
                    "
                >
                    <icon-with-text
                        text="人物頻度報告書出力"
                        :show-text="showText"
                        icon
                        icon-name="print"
                    />
                </v-toolbar-items>
            </v-toolbar>
            <!-- 検索項目 -->
            <v-toolbar fixed dense :color="$root.getTheme().quaternary">
                <v-select
                    v-model="storageData.selectedShooting"
                    hide-details
                    label="撮影行事名"
                    placeholder="アルバム全体"
                    :items="storageData.shootings"
                    item-text="name"
                    return-object
                    dense
                    outlined
                    clearable
                    @change="fetchFrequency()"
                />
                <v-text-field
                    v-model="storageData.page_no_form"
                    hide-details
                    clearable
                    dense
                    outlined
                    label="ページ番号"
                    @change="fetchFrequency()"
                    @keyup.enter="enterKeyUp()"
                />
                <v-text-field
                    v-model="storageData.student_no_form"
                    hide-details
                    clearable
                    dense
                    outlined
                    label="出席番号"
                    @change="fetchFrequency()"
                />
                <v-select
                    v-model="storageData.sex_selector"
                    hide-details
                    clearable
                    dense
                    outlined
                    :items="['男性', '女性']"
                    label="性別"
                    @change="fetchFrequency()"
                />
                <v-text-field
                    v-model="storageData.note_form"
                    class="shrink"
                    hide-details
                    clearable
                    dense
                    outlined
                    label="個人メモ"
                    @change="fetchFrequency()"
                />

                <v-spacer />
            </v-toolbar>
        </template>

        <template slot="top" />

        <template slot="middle" />

        <template slot="bottom">
            <v-tabs v-model="selectedTab">
                <v-tab> 全写真 </v-tab>
                <v-tab :style="{ width: '10em' }">
                    選定中の写真
                </v-tab>
                <v-tabs-items v-model="selectedTab">
                    <v-tab-item v-for="tab in 2" :key="tab">
                        <v-container fluid>
                            <v-row>
                                <v-col cols="10">
                                    <span>対象写真: {{ storageData.photos_num }}枚</span>
                                    <v-icon small class="mr-1">
                                        mdi-emoticon-excited-outline
                                    </v-icon>
                                    <span v-if="!isNaN(meanLaughingRate)">
                                        {{ meanLaughingRate }}%</span>
                                    <!-- 人物頻度グラフ -->
                                    <div class="container">
                                        <chart
                                            v-if="loaded"
                                            :styles="myStyles"
                                            :chart-data="shapedData"
                                            :options="options"
                                            @graphBarClicked="setGraphBarSelected"
                                        />
                                    </div>
                                </v-col>
                                <v-col cols="2">
                                    <!-- 選択中の頻度の生徒を一覧表示 -->
                                    <v-container
                                        style="max-height: 75vh"
                                        class="overflow-y-auto quaternary"
                                    >
                                        <v-card-text v-if="showingFaces.length == 0" class="px-1">
                                            <v-icon>error</v-icon>
                                            未選択
                                        </v-card-text>
                                        <span v-if="storageData.clickedLabel != undefined">人物内訳 (検出枚数{{ storageData.clickedLabel }}枚)</span>
                                        <v-row v-for="face in showingFaces" :key="face.no">
                                            <v-container class="">
                                                <v-row dense>
                                                    <v-col cols="6" align="center">
                                                        <v-tooltip bottom>
                                                            <template #activator="{ on }">
                                                                <v-container class="ma-0 pa-0">
                                                                    <v-row class="ma-0 pa-0">
                                                                        <v-col cols="12" class="ma-0 pa-0">
                                                                            <span>{{ face.no }}</span>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row class="ma-0 pa-0">
                                                                        <v-col cols="12" class="ma-0 pa-0">
                                                                            <v-img
                                                                                :src="face.face_image_path"
                                                                                v-on="on"
                                                                            />
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row class="ma-0 pa-0">
                                                                        <v-col cols="12" class="ma-0 pa-0">
                                                                            <span>{{ face.student_no }}</span>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-container>
                                                            </template>
                                                            <span>顔マスタ番号：{{ face.no }}<br /></span>
                                                            <span v-if="!!face.student_no">出席番号：{{ face.student_no }}<br /></span>
                                                            <span v-if="!!face.sex">性別：{{ face.sex }}<br /></span>
                                                            <span v-if="!!face.note">個人メモ：{{ face.note }}<br /></span>
                                                        </v-tooltip>
                                                    </v-col>
                                                    <v-col cols="6" align="center">
                                                        <span
                                                            v-if="face.frequency_of_whole_album != undefined"
                                                        >
                                                            全体:{{ face.frequency_of_whole_album }}枚<br />
                                                        </span>
                                                        <span v-if="storageData.clickedLabel != 0">
                                                            <v-icon small class="mr-1">mdi-emoticon-excited-outline</v-icon>
                                                            {{
                                                                (face.mean_laughing_rate * 100).toFixed(1) + "%"
                                                            }}
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-row>
                                    </v-container>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                </v-tabs-items>
                <v-tabs />
            </v-tabs>
        </template>
    </page-container>
</template>

<script>
import Chart from './Chart.vue';
import event from '../../utils/event';
import http from '../../services/http';
import urlUtil from '../../utils/url';
import leaveConform from '../../mixins/leave-confirm-mixin';

export default {
    name: 'Frequency',
    components: {
        Chart,
        'icon-with-text': require('../Icons/IconWithText.vue').default,
    },
    mixins: [leaveConform],
    props: {
        albumId: {
            type: [String, Number],
            default: 0,
        },
        // customerId: {
        //     type: [String, Number],
        //     default: 0,
        // },
        closeCallback: {
            type: Function,
            default: undefined,
        },
    },
    data() {
        return {
            loaded: false,
            options: null,
            backgroundColors: [],
            barBackgroundColor: '#f44336',
            hoverdBarBackgroundColor: '#d41111',
            clickedBarBackgroundColor: '#d41111',
            clickedHoverdBarBackgroundColor: '#aa1111',
            showText: true,
            selectedTab: 0,
            storageData: {
                isInitialized: false,
                customerType: undefined,
                onlyUncompleted: true,
                faces: [],
                photos_num: 0,
                facebases: [],
                album: {
                    id: 0,
                    customerId: 0,
                    schoolYear: 2020, // TODO 現在年とする
                    name: '',
                    submitedDirectoryName: '',
                    submitedDataDirectoryName: '',
                    isCompleted: 0,
                    updatedAt: undefined,
                },
                frequency: [],
                modeUpdate: false,
                selectedFaces: [],
                pageSpreads: [],
                selectedPageSpread: undefined,
                shootings: [],
                selectedShooting: undefined,
                page_no_form: undefined,
                sex_selector: undefined,
                student_no_form: undefined,
                note_form: undefined,
                clickedLabel: undefined,
            },
        };
    },
    computed: {
        myStyles() {
            return {
                height: '70vh',
                position: 'relative',
            };
        },
        meanLaughingRate() {
            const laughing_rate_array = this.storageData.frequency.map(
                (o) => o.mean_laughing_rate
            );
            const frequency_array = this.storageData.frequency.map(
                (o) => o.student_frequency
            );

            const zip = (array1, array2) =>
                array1.map((_, i) => [array1[i], array2[i]]);

            var sum = 0;
            var denom = 0;
            zip(laughing_rate_array, frequency_array).forEach(
                ([laughing_rate, frequency]) => {
                    if (laughing_rate != null) {
                        sum += parseFloat(laughing_rate) * frequency;
                        denom += frequency;
                    }
                }
            );
            return ((sum / denom) * 100).toFixed(1);
        },
        showingFaces() {
            var self = this;
            if (self.storageData.clickedLabel == undefined) {
                return [];
            }

            return this.storageData.frequency.filter(function (f) {
                return f.student_frequency == self.storageData.clickedLabel;
            });
        },
        shapedData() {
            const max_freq = Math.max.apply(
                null,
                this.storageData.frequency.map(function (o) {
                    return o.student_frequency;
                })
            );
            // グラフの横軸 0~最大頻度の配列
            const labels = [...Array(max_freq + 1).keys()];
            // グラフの値を格納する配列
            const data = Array(max_freq + 1);
            data.fill(0);

            this.storageData.frequency.forEach((element) => {
                data[element.student_frequency]++;
            });

            // 選択状態のグラフの色を変える
            const backgroundColorArray = Array(max_freq + 1);
            backgroundColorArray.fill(this.barBackgroundColor);
            const hoverdBackgroundColorArray = Array(max_freq + 1);
            hoverdBackgroundColorArray.fill(this.hoverdBarBackgroundColor);

            if (this.storageData.clickedLabel != undefined) {
                backgroundColorArray[this.storageData.clickedLabel] =
          this.clickedBarBackgroundColor;
                hoverdBackgroundColorArray[this.storageData.clickedLabel] =
          this.clickedHoverdBarBackgroundColor;
            }

            return {
                labels: labels,
                datasets: [
                    {
                        backgroundColor: backgroundColorArray,
                        hoverBackgroundColor: hoverdBackgroundColorArray,
                        label: '人数',
                        data: data,
                    },
                ],
            };
        },
    },
    watch: {
        selectedTab: function () {
            this.fetchFrequency();
        },
    },
    mounted() {
        event.on('saveStorage', () => {
            if (this.storageData.isInitialized) {
                this.saveStorageData();
            }
        });

        if (!this.restoreStorageData()) {
            this.initialize()
                .then((res) => {
                    this.storageData.isInitialized = true;
                    this.confirmLeaveReset(['storageData']);
                })
                .catch((error) => {
                    if (error === 'unauthorized') return;
                    this.showError('初期化処理に失敗しました。', '初期化エラー', error);
                });
        }
    },
    methods: {
        initialize() {
            return Promise.all([
                console.log('initialize'),
                this.fetchAlbum(),
                this.fetchShootings(),
                // this.fetchFrequency(),
            ]).then((res) => (this.storageData.isInitialized = true));
        },
        setGraphBarSelected(label) {
            if (this.storageData.clickedLabel != label) {
                this.storageData.clickedLabel = label;
            } else {
                this.storageData.clickedLabel = undefined;
            }
        },
        fetchAlbum() {
            return http
                .get('albums/' + this.albumId)
                .then((res) => (this.storageData.album = res.data));
        },
        fetchPhotosNum() {
            var url = urlUtil.addQueryParamIfDefined(
                'photos/count',
                'album_id',
                this.albumId
            );
            var shooting_id = !this.storageData.selectedShooting
                ? undefined
                : this.storageData.selectedShooting.id;
            url = urlUtil.addQueryParamIfDefined(url, 'shooting_id', shooting_id);
            url = urlUtil.addQueryParamIfDefined(
                url,
                'only_selected',
                !(this.selectedTab == 0)
            );

            return http
                .get(url)
                .then((res) => (this.storageData.photos_num = res.data))
                .catch((error) => {
                    if (error === 'unauthorized') return;
                    this.showError('初期化処理に失敗しました。', '初期化エラー', error);
                });
        },
        fetchShootings() {
            var url = urlUtil.addQueryParamIfDefined(
                'shootings',
                'album_id',
                this.albumId
            );

            return http
                .get(url)
                .then((res) => (this.storageData.shootings = res.data))
                .catch((error) => {
                    if (error === 'unauthorized') return;
                    this.showError('初期化処理に失敗しました。', '初期化エラー', error);
                });
        },
        outputFrequencyDoc() {
            console.log('output frequency doc');

            var url = urlUtil.addQueryParamIfDefined(
                '/report.html',
                'album_id',
                this.albumId
            );
            // url = urlUtil.addQueryParamIfDefined(url, 'customer_id', this.customerId);
            url = urlUtil.addQueryParamIfDefined(
                url,
                'page_no',
                this.storageData.page_no_form
            );
            if (this.storageData.selectedShooting) {
                url = urlUtil.addQueryParamIfDefined(
                    url,
                    'shooting_id',
                    this.storageData.selectedShooting.id
                );
            }
            url = urlUtil.addQueryParamIfDefined(
                url,
                'only_selected',
                !(this.selectedTab == 0)
            );

            window.open(url, '人物頻度報告書');
        },
        async fetchFrequency() {
            this.fetchPhotosNum();

            console.log('fetch frequency');
            this.loaded = false;
            this.storageData.clickedLabel = undefined;

            var url = urlUtil.addQueryParamIfDefined(
                'photo_faces/shooting_frequency',
                'album_id',
                this.albumId
            );
            var shooting_id = !this.storageData.selectedShooting
                ? undefined
                : this.storageData.selectedShooting.id;

            await http
                .post(url, {
                    shooting_id: shooting_id,
                    page_no: this.storageData.page_no_form,
                    student_no: this.storageData.student_no_form,
                    sex: this.storageData.sex_selector,
                    note: this.storageData.note_form,
                    only_selected: !(this.selectedTab == 0),
                })
                .then((res) => (this.storageData.frequency = res.data))
                .catch((error) => {
                    if (error === 'unauthorized') return;
                    this.showError('初期化処理に失敗しました。', '初期化エラー', error);
                });

            this.loaded = true;
        },
        enterKeyUp(event) {
            console.log(event.target.tagName);
        },
        setShooting(shootingId) {
            this.storageData.selectedShooting = this.storageData.shootings.find(
                (s) => s.id == shootingId
            );
            this.fetchFrequency();
        },
        setActiveTab(index) {
            this.selectedTab = index;
        },
    },
};
</script>