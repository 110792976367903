<template>
    <v-dialog v-model="display" max-width="50vw" scrollable>
        <v-card :color="$root.getTheme().background">
            <v-card-title class="text-md-subtitle-1">
                {{ storageData.shooting.name }} - 自動選定
            </v-card-title>
            <v-card-text>
                <v-form ref="autoSelectForm" lazy-validation>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="3">
                                <v-text-field
                                    v-model="storageData.shooting.expect_photo_amount"
                                    :rules="rules.expectPhotoAmountRules"
                                    label="予定枚数"
                                    type="number"
                                    min="1"
                                    max="999"
                                    dense
                                    suffix="枚"
                                />                        
                            </v-col>
                            <v-spacer />
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-card>
                                    <v-card-title class="text-md-subtitle-2">
                                        選定対象条件
                                    </v-card-title>
                                    <v-card-text>
                                        <v-text-field
                                            v-model="storageData.optimizeSetting.min_necessary_person_num"
                                            cols="5"
                                            type="number"
                                            min="1"
                                            max="99"
                                            dense
                                            suffix="人以上が映っている写真のみを選定対象とする"
                                            width="/"
                                        />
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-card>
                                    <v-card-title class="text-md-subtitle-2">
                                        写真１枚の評価
                                    </v-card-title>
                                    <v-card-text>
                                        <v-container fluid>
                                            <v-row>
                                                <v-col>
                                                    <v-switch 
                                                        v-model="storageData.optimizeSetting.smile_priority"
                                                        label="笑顔優先" 
                                                        hide-details
                                                        dense
                                                        @change="singleEvaluationItemChanged"
                                                    />
                                                </v-col>
                                                <v-col>
                                                    <v-switch 
                                                        v-model="storageData.optimizeSetting.peace_ng" 
                                                        label="ピース禁止"
                                                        hide-details
                                                        dense
                                                        @change="singleEvaluationItemChanged"
                                                    />
                                                </v-col>
                                                <v-col>
                                                    <v-switch 
                                                        v-model="storageData.optimizeSetting.looking_cam_ng" 
                                                        label="カメラ目線禁止" 
                                                        hide-details
                                                        dense
                                                        @change="singleEvaluationItemChanged"
                                                    />
                                                </v-col>
                                                <v-col>
                                                    <v-switch 
                                                        v-model="storageData.optimizeSetting.mouth_opening_ng" 
                                                        label="口開き禁止" 
                                                        hide-details
                                                        dense
                                                        @change="singleEvaluationItemChanged"
                                                    />
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-container fluid>
                                                    <v-row>
                                                        <v-col cols="9">
                                                            各項目の優先度 ※合計が100になるように設定してください。
                                                        </v-col>
                                                        <v-col cols="3" class="priority-summery-label">
                                                            <span :style="{ color: weightSingleSumNumberColor }">
                                                                合計: {{ weightSingleSum }}
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="3" class="slider-subitem-label">
                                                            笑顔優先
                                                        </v-col>
                                                        <v-col cols="9">
                                                            <v-slider
                                                                v-model="storageData.optimizeSetting.weight_smile"
                                                                :max="100"
                                                                class="align-center"
                                                                hide-details
                                                                dense
                                                                :disabled="!storageData.optimizeSetting.smile_priority"
                                                            >
                                                                <template v-slot:append>
                                                                    <v-text-field
                                                                        v-model="storageData.optimizeSetting.weight_smile"
                                                                        class="mt-0 pt-0"
                                                                        type="number"
                                                                        min="0"
                                                                        max="100"
                                                                        style="width: 60px"
                                                                        hide-details
                                                                        dense
                                                                        :disabled="!storageData.optimizeSetting.smile_priority"
                                                                    />
                                                                </template>
                                                            </v-slider>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="3" class="slider-subitem-label">
                                                            ピース禁止
                                                        </v-col>
                                                        <v-col cols="9">
                                                            <v-slider
                                                                v-model="storageData.optimizeSetting.weight_peace_ng"
                                                                :max="100"
                                                                class="align-center"
                                                                hide-details
                                                                dense
                                                                :disabled="!storageData.optimizeSetting.peace_ng"
                                                            >
                                                                <template v-slot:append>
                                                                    <v-text-field
                                                                        v-model="storageData.optimizeSetting.weight_peace_ng"
                                                                        class="mt-0 pt-0"
                                                                        type="number"
                                                                        min="0"
                                                                        max="100"
                                                                        style="width: 60px"
                                                                        hide-details
                                                                        dense
                                                                        :disabled="!storageData.optimizeSetting.peace_ng"
                                                                    />
                                                                </template>
                                                            </v-slider>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="3" class="slider-subitem-label">
                                                            カメラ目線禁止
                                                        </v-col>
                                                        <v-col cols="9">
                                                            <v-slider
                                                                v-model="storageData.optimizeSetting.weight_looking_cam_ng"
                                                                :max="100"
                                                                class="align-center"
                                                                hide-details
                                                                dense
                                                                :disabled="!storageData.optimizeSetting.looking_cam_ng"
                                                            >
                                                                <template v-slot:append>
                                                                    <v-text-field
                                                                        v-model="storageData.optimizeSetting.weight_looking_cam_ng"
                                                                        class="mt-0 pt-0"
                                                                        type="number"
                                                                        min="0"
                                                                        max="100"
                                                                        style="width: 60px"
                                                                        hide-details
                                                                        dense
                                                                        :disabled="!storageData.optimizeSetting.looking_cam_ng"
                                                                    />
                                                                </template>
                                                            </v-slider>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="3" class="slider-subitem-label">
                                                            口開き禁止
                                                        </v-col>
                                                        <v-col cols="9">
                                                            <v-slider
                                                                v-model="storageData.optimizeSetting.weight_mouth_opening_ng"
                                                                :max="100"
                                                                class="align-center"
                                                                hide-details
                                                                dense
                                                                :disabled="!storageData.optimizeSetting.mouth_opening_ng"
                                                            >
                                                                <template v-slot:append>
                                                                    <v-text-field
                                                                        v-model="storageData.optimizeSetting.weight_mouth_opening_ng"
                                                                        class="mt-0 pt-0"
                                                                        type="number"
                                                                        min="0"
                                                                        max="100"
                                                                        style="width: 60px"
                                                                        hide-details
                                                                        dense
                                                                        :disabled="!storageData.optimizeSetting.mouth_opening_ng"
                                                                    />
                                                                </template>
                                                            </v-slider>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-card>
                                    <v-card-title class="text-md-subtitle-2">
                                        全体の評価
                                    </v-card-title>
                                    <v-card-text>
                                        <v-container fluid>
                                            <v-row>
                                                <v-col cols="3" class="numeric-item-label">
                                                    全体評価の試行回数
                                                </v-col>
                                                <v-col cols="3">
                                                    <v-text-field
                                                        v-model="storageData.optimizeSetting.trials_count"
                                                        :rules="rules.trialsCountRules"
                                                        type="number"
                                                        min="1"
                                                        max="99999"
                                                        dense
                                                        suffix="回"
                                                    />                        
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col>
                                                    <v-switch 
                                                        v-model="storageData.optimizeSetting.individual_equal" 
                                                        label="個人均等" 
                                                        hide-details
                                                        dense
                                                        @change="ividualEqualSwitchChanged"
                                                    />
                                                </v-col>
                                                <v-col>
                                                    <v-switch 
                                                        v-model="storageData.optimizeSetting.gender_equal" 
                                                        label="男女均等" 
                                                        hide-details
                                                        dense
                                                        @change="genderEqualSwitchChanged"
                                                    />
                                                </v-col>
                                                <v-col>
                                                    <v-switch 
                                                        v-model="storageData.optimizeSetting.scene_equal" 
                                                        label="シーン均等" 
                                                        hide-details
                                                        dense
                                                        @change="sceneEqualSwitchChanged"
                                                    />
                                                </v-col>
                                                <v-col>
                                                    <v-switch 
                                                        v-model="storageData.optimizeSetting.folder_equal" 
                                                        label="サブフォルダ均等" 
                                                        hide-details
                                                        dense
                                                        @change="folderEqualSwitchChanged"
                                                    />
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="3" class="numeric-item-label">
                                                    個人均等の許容差
                                                </v-col>
                                                <v-col cols="3">
                                                    <v-text-field
                                                        v-model="storageData.optimizeSetting.max_diff_individual"
                                                        :rules="rules.tolerancePhotoAmountRules"
                                                        type="number"
                                                        min="0"
                                                        max="999"
                                                        dense
                                                        suffix="枚"
                                                        :disabled="!storageData.optimizeSetting.individual_equal"
                                                    />
                                                </v-col>
                                                <v-col cols="3" class="numeric-item-label">
                                                    男女均等の許容差
                                                </v-col>
                                                <v-col cols="3">
                                                    <v-text-field
                                                        v-model="storageData.optimizeSetting.max_diff_gender"
                                                        :rules="rules.tolerancePhotoAmountRules"
                                                        type="number"
                                                        min="0"
                                                        max="999"
                                                        dense
                                                        suffix="人"
                                                        :disabled="!storageData.optimizeSetting.gender_equal"
                                                    />
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="3" class="numeric-item-label">
                                                    シーン均等の許容差
                                                </v-col>
                                                <v-col cols="3">
                                                    <v-text-field
                                                        v-model="storageData.optimizeSetting.max_diff_scene"
                                                        :rules="rules.tolerancePhotoAmountRules"
                                                        type="number"
                                                        min="0"
                                                        max="999"
                                                        dense
                                                        suffix="枚"
                                                        :disabled="!storageData.optimizeSetting.scene_equal"
                                                    />
                                                </v-col>
                                                <v-col cols="3" class="numeric-item-label">
                                                    サブフォルダ均等の許容差
                                                </v-col>
                                                <v-col cols="3">
                                                    <v-text-field
                                                        v-model="storageData.optimizeSetting.max_diff_folder"
                                                        :rules="rules.tolerancePhotoAmountRules"
                                                        type="number"
                                                        min="0"
                                                        max="999"
                                                        dense
                                                        suffix="枚"
                                                        :disabled="!storageData.optimizeSetting.folder_equal"
                                                    />
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-container fluid>
                                                    <v-row>
                                                        <v-col cols="9">
                                                            各項目の優先度  ※合計が100になるように設定してください。
                                                        </v-col>
                                                        <v-col cols="3" class="priority-summery-label">
                                                            <span :style="{ color: weightSumNumberColor }">
                                                                合計: {{ weightSum }}
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="3" class="slider-subitem-label">
                                                            予定枚数と一致
                                                        </v-col>
                                                        <v-col cols="9">
                                                            <v-slider
                                                                v-model="storageData.optimizeSetting.weight_selected_num"
                                                                :max="100"
                                                                class="align-center"
                                                                hide-details
                                                                dense
                                                            >
                                                                <template v-slot:append>
                                                                    <v-text-field
                                                                        v-model="storageData.optimizeSetting.weight_selected_num"
                                                                        class="mt-0 pt-0"
                                                                        type="number"
                                                                        min="0"
                                                                        max="100"
                                                                        style="width: 60px"
                                                                        hide-details
                                                                        dense
                                                                    />
                                                                </template>
                                                            </v-slider>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="3" class="slider-subitem-label">
                                                            個人均等
                                                        </v-col>
                                                        <v-col cols="9">
                                                            <v-slider
                                                                v-model="storageData.optimizeSetting.weight_individual"
                                                                :max="100"
                                                                class="align-center"
                                                                hide-details
                                                                dense
                                                                :disabled="!storageData.optimizeSetting.individual_equal"
                                                            >
                                                                <template v-slot:append>
                                                                    <v-text-field
                                                                        v-model="storageData.optimizeSetting.weight_individual"
                                                                        class="mt-0 pt-0"
                                                                        type="number"
                                                                        min="0"
                                                                        max="100"
                                                                        style="width: 60px"
                                                                        hide-details
                                                                        dense
                                                                        :disabled="!storageData.optimizeSetting.individual_equal"
                                                                    />
                                                                </template>
                                                            </v-slider>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="3" class="slider-subitem-label">
                                                            男女均等
                                                        </v-col>
                                                        <v-col cols="9">
                                                            <v-slider
                                                                v-model="storageData.optimizeSetting.weight_gender"
                                                                :max="100"
                                                                class="align-center"
                                                                hide-details
                                                                dense
                                                                :disabled="!storageData.optimizeSetting.gender_equal"
                                                            >
                                                                <template v-slot:append>
                                                                    <v-text-field
                                                                        v-model="storageData.optimizeSetting.weight_gender"
                                                                        class="mt-0 pt-0"
                                                                        type="number"
                                                                        min="0"
                                                                        max="100"
                                                                        style="width: 60px"
                                                                        hide-details
                                                                        dense
                                                                        :disabled="!storageData.optimizeSetting.gender_equal"
                                                                    />
                                                                </template>
                                                            </v-slider>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="3" class="slider-subitem-label">
                                                            シーン均等
                                                        </v-col>
                                                        <v-col cols="9">
                                                            <v-slider
                                                                v-model="storageData.optimizeSetting.weight_scene"
                                                                :max="100"
                                                                class="align-center"
                                                                hide-details
                                                                dense
                                                                :disabled="!storageData.optimizeSetting.scene_equal"
                                                            >
                                                                <template v-slot:append>
                                                                    <v-text-field
                                                                        v-model="storageData.optimizeSetting.weight_scene"
                                                                        class="mt-0 pt-0"
                                                                        type="number"
                                                                        min="0"
                                                                        max="100"
                                                                        style="width: 60px"
                                                                        hide-details
                                                                        dense
                                                                        :disabled="!storageData.optimizeSetting.scene_equal"
                                                                    />
                                                                </template>
                                                            </v-slider>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="3" class="slider-subitem-label">
                                                            サブフォルダ均等
                                                        </v-col>
                                                        <v-col cols="9">
                                                            <v-slider
                                                                v-model="storageData.optimizeSetting.weight_folder"
                                                                :max="100"
                                                                class="align-center"
                                                                hide-details
                                                                dense
                                                                :disabled="!storageData.optimizeSetting.folder_equal"
                                                            >
                                                                <template v-slot:append>
                                                                    <v-text-field
                                                                        v-model="storageData.optimizeSetting.weight_folder"
                                                                        class="mt-0 pt-0"
                                                                        type="number"
                                                                        min="0"
                                                                        max="100"
                                                                        style="width: 60px"
                                                                        hide-details
                                                                        dense
                                                                        :disabled="!storageData.optimizeSetting.folder_equal"
                                                                    />
                                                                </template>
                                                            </v-slider>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="3" class="slider-subitem-label">
                                                            写真１枚ごとの評価
                                                        </v-col>
                                                        <v-col cols="9">
                                                            <v-slider
                                                                v-model="storageData.optimizeSetting.weight_point_sum"
                                                                :max="100"
                                                                class="align-center"
                                                                hide-details
                                                                dense
                                                            >
                                                                <template v-slot:append>
                                                                    <v-text-field
                                                                        v-model="storageData.optimizeSetting.weight_point_sum"
                                                                        class="mt-0 pt-0"
                                                                        type="number"
                                                                        min="0"
                                                                        max="100"
                                                                        style="width: 60px"
                                                                        hide-details
                                                                        dense
                                                                    />
                                                                </template>
                                                            </v-slider>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-container class="px-3">
                    <v-row justify="space-between">
                        <cancel-dialog-button 
                            width="150" 
                            @click="closeAutoSelectDialog"
                        >
                            キャンセル
                        </cancel-dialog-button>
                        <proceed-dialog-button
                            width="150" 
                            @click="autoSelectPhoto"
                        >
                            上記設定で自動選定
                        </proceed-dialog-button>
                    </v-row>
                </v-container>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import event from '../../utils/event';
import http from '../../services/http';
import monet from 'monet';
import { awaitJobDone } from '../../services/awaitJobDone';

export default {
    props: {
        albumId: {
            type: [Number, String],
            default: 0,
        },
        shootingId: {
            type: [Number, String],
            default: 0,
        },
        completedCallback: {
            type: Function,
            default: () => {},
        },
    },
    data() {
        return {
            display: false,
            select_num: 20,  // 指定枚数
            storageData: {
                isInitialized: false,
                optimizeSetting: {
                    trials_count: 100,

                    smile_priority: true,                    
                    peace_ng: true,
                    looking_cam_ng: true,
                    mouth_opening_ng: true,

                    // 単体評価式の重み ※合計100を配分
                    weight_smile: 25,  // 笑顔優先
                    weight_peace_ng: 25, // ピース禁止
                    weight_looking_cam_ng: 25,  // カメラ目線禁止
                    weight_mouth_opening_ng: 25,  // 口開き禁止

                    individual_equal: true,
                    gender_equal: true,
                    scene_equal: true,
                    folder_equal: true,

                    max_diff_individual: 0,
                    max_diff_gender: 0,
                    max_diff_scene: 0,
                    max_diff_folder: 0,
                    
                    // 組み合わせ評価式の重み ※合計100を配分
                    weight_selected_num: 40,  // 選択枚数
                    weight_individual: 40, // 個人均等
                    weight_scene: 5,  // シーン均等
                    weight_gender: 5,  // 男女均等
                    weight_folder: 5, // サブフォルダ均等
                    weight_point_sum: 5, // 単体評価合計

                    min_necessary_person_num: 1, // 最低限写真に映っている必要がある人数
                },
                shooting: undefined,
            },
            shootings: [],
            rules:{
                shootingRules: [
                    v => !!v || '選択してください。',
                ],
                trialsCountRules: [
                    v => !!v || '入力してください。',
                    v => !v || v && Math.round(v) == v || '整数値を入力してください。',
                    v => !v || v && (v >= 1 && v <= 99999) || '1～99999の範囲で入力してください。',
                ],
                expectPhotoAmountRules: [
                    v => !!v || '入力してください。',
                    v => !v || v && Math.round(v) == v || '整数値を入力してください。',
                    v => !v || v && (v >= 1 && v <= 999) || '1～999の範囲で入力してください。',
                ],
                tolerancePhotoAmountRules: [
                    v => !!v || v === 0 || '入力してください。',
                    v => !v || v && Math.round(v) == v || '整数値を入力してください。',
                    v => !v || v && (v >= 0 && v <= 999) || '0～999の範囲で入力してください。',
                ],
            },
        };
    },
    computed: {
        weightSum() {
            return this.storageData.optimizeSetting.weight_selected_num 
                + this.storageData.optimizeSetting.weight_individual 
                + this.storageData.optimizeSetting.weight_scene 
                + this.storageData.optimizeSetting.weight_gender 
                + this.storageData.optimizeSetting.weight_folder
                + this.storageData.optimizeSetting.weight_point_sum;
        },
        weightSingleSum() {
            return this.storageData.optimizeSetting.weight_smile 
                + this.storageData.optimizeSetting.weight_peace_ng 
                + this.storageData.optimizeSetting.weight_looking_cam_ng
                + this.storageData.optimizeSetting.weight_mouth_opening_ng;
        },
        weightSumNumberColor() {
            return this.tosSumNumberColor(this.weightSum);
        },
        weightSingleSumNumberColor() {
            return this.tosSumNumberColor(this.weightSingleSum);
        },
    },
    mounted() {
        event
            .on('saveStorage', () => {
                if (this.storageData.isInitialized) {
                    this.saveStorageData();
                }
            });
    },
    beforeDestroy() {
        event.off('saveStorage');
    },
    methods: {
        initialize() {
            return Promise
                .all([
                    this.fetchShooting(),
                    this.fetchOptimizeSetting()
                ])
                .then(() => this.storageData.isInitialized = true)
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError('初期化処理に失敗しました。', '初期化エラー', error);
                });
        },
        fetchShooting () {
            return http.get('shootings/' + this.shootingId).then(res => this.storageData.shooting = res.data);
        },
        fetchOptimizeSetting() {
            return http
                .get('shootings/edit_optimize_setting/' + this.shootingId)
                .then(res => this.storageData.optimizeSetting = res.data)
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, '自動選定設定取得エラー');
                });
        },
        autoSelectPhoto() {
            if (!this.$refs.autoSelectForm.validate()) return; // 入力チェックに違反したら以降の処理をしない。

            (this.weightSum != 100 || this.weightSingleSum != 100
                ? monet.Fail('各項目の優先度の合計が100になるように入力してください。')
                : monet.Success())
                .cata(
                    error => this.showError(error, '自動選定エラー'),
                    () => Promise
                        .all([
                            this.updateOptimizeSetting(),
                            this.updateShootingExpectPhotoAmount(),
                        ])
                        .then(() => http
                            .post('shootings/auto_select_photos/' + this.shootingId)
                            .then(res => {
                                const jobId = res.data.job_id;

                                awaitJobDone(jobId, true).then((jobResult) => {
                                    this.showInfo('自動選定が完了しました。', '自動選定');
                                    this.completedCallback(jobResult);
                                }).catch(error => {
                                    this.showError(error, '自動選定エラー');
                                });
                            })
                        )
                        .catch(error => {
                            if (error === 'unauthorized') return;
                            this.showError(error, '自動選定エラー');
                        })
                );            
        },
        updateOptimizeSetting() {
            return http
                .put('shootings/update_optimize_setting/' + this.shootingId, this.storageData.optimizeSetting)
                .then(() => {})
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, '自動選定設定更新エラー');
                });
        },
        updateShootingExpectPhotoAmount() {
            return http
                .put('shootings/' + this.shootingId, this.storageData.shooting)
                .then(() => {})
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, 'ページ構成更新エラー');
                });
        },
        singleEvaluationItemChanged () { // 写真1枚の評価項目（3項目）の切替え。
            const setting = this.storageData.optimizeSetting;
            const ite = [
                { flag: setting.smile_priority, setter: n => setting.weight_smile = n, }, // 笑顔優先
                { flag: setting.peace_ng, setter: n => setting.weight_peace_ng = n, }, // ピース禁止
                { flag: setting.looking_cam_ng, setter: n => setting.weight_looking_cam_ng = n, }, // カメラ目線禁止
                { flag: setting.mouth_opening_ng, setter: n => setting.weight_mouth_opening_ng = n, }, // 口開き禁止
            ];

            ite.filter(x => !x.flag).forEach(x => x.setter(0)); // 無効なものは 0 にする。

            const enables = ite.filter(x => !!x.flag);

            switch (enables.length) {
                case 4: // 各25
                case 2: // 各50
                case 1: // 各100
                    enables.forEach(x => x.setter(100 / enables.length));
                    return;
                case 3: // 34 or 33
                    enables.forEach((x, ix) => x.setter(ix === 0 ? 34 : 33));
                    return;
                case 0: // 全25
                    ite.forEach(x => x.setter(25));
                    return;
                default:
                    return;
            }
        },
        combinationEvaluationItemChanged () { // 全体評価項目（3項目）の切替え。
            const setting = this.storageData.optimizeSetting;
            const ite = [
                { flag: true, setter: n => setting.weight_selected_num = n, default: 40, }, // 枚数一致
                { flag: setting.individual_equal, setter: n => setting.weight_individual = n, default: 40, }, // 個人均等
                { flag: setting.gender_equal, setter: n => setting.weight_gender = n, default: 5, }, // 男女均等
                { flag: setting.scene_equal, setter: n => setting.weight_scene = n, default: 5, }, // シーン均等
                { flag: setting.folder_equal, setter: n => setting.weight_folder = n, default: 5, }, // サブフォルダ均等
                { flag: true, setter: n => setting.weight_point_sum = n, default: 5, }, // 単体評価合計
            ];

            ite.filter(x => !x.flag).forEach(x => x.setter(0)); // 無効なものは 0 にする。

            const enables = ite.filter(x => !!x.flag);

            switch (enables.length) {
                case 6: // 各デフォルト
                    enables.forEach(x => x.setter(x.default));
                    return;
                case 5: // 各20
                case 4: // 各25
                case 2: // 各50
                    enables.forEach(x => x.setter(100 / enables.length));
                    return;
                case 3: // 34 or 33
                    enables.forEach((x, ix) => x.setter(ix === 0 ? 34 : 33));
                    return;
                default:
                    return;
            }
        },
        ividualEqualSwitchChanged () {
            this.storageData.optimizeSetting.max_diff_individual = this.storageData.optimizeSetting.individual_equal ? 0 : 999;
            this.combinationEvaluationItemChanged();
        },
        genderEqualSwitchChanged () {
            this.storageData.optimizeSetting.max_diff_gender = this.storageData.optimizeSetting.gender_equal ? 0 : 999;
            this.combinationEvaluationItemChanged();
        },
        sceneEqualSwitchChanged () {
            this.storageData.optimizeSetting.max_diff_scene = this.storageData.optimizeSetting.scene_equal ? 0 : 999;
            this.combinationEvaluationItemChanged();
        },
        folderEqualSwitchChanged () {
            this.storageData.optimizeSetting.max_diff_folder = this.storageData.optimizeSetting.folder_equal ? 0 : 999;
            this.combinationEvaluationItemChanged();
        },
        closeAutoSelectDialog() {
            this.display = false;
        },
        tosSumNumberColor(num) {
            return num === 100 
                ? '#FFFFFF'
                : num > 100 
                    ? '#FF5462'
                    : '#1495FF';
        },
    }
};
</script>
<style>
.numeric-item-label {
    line-height: 48px;
}
.slider-subitem-label {
    padding-left: 32px;
    line-height: 34px;
}
.priority-summery-label {
    text-align: right;
    font-weight: bold;
}    
</style>