
import Vue from 'vue';
import VuejsDialog from 'vuejs-dialog';

import 'vuejs-dialog/dist/vuejs-dialog.min.css';

Vue.use(VuejsDialog, {
    html: true,
    okText: 'OK',
    cancelText: 'キャンセル',
});

import InfoDialog from './InfoDialog';
import ErrorDialog from './ErrorDialog';
import ConfirmDialog from './ConfirmDialog';

Vue.dialog.registerComponent('my-info-dialog', InfoDialog);
Vue.dialog.registerComponent('my-error-dialog', ErrorDialog);
Vue.dialog.registerComponent('my-confirm-dialog', ConfirmDialog);

export default {
    methods: {
        showConfirm(body, title = undefined) {
            const message = { title: title, body: body, };

            return this.$dialog.alert(message, {
                view: 'my-confirm-dialog',
                animation: 'zoom',
            });
        },
        showInfo(body, title = 'エラー') {
            const message = { title: title, body: body, };

            return this.$dialog.alert(message, {
                view: 'my-info-dialog',
                animation: 'zoom',
            });
        },
        showError(body, title = undefined, errorDetail = undefined) {
            if (errorDetail) {
                body = body + '\n----------------------------------\n' + errorDetail;
            }
            const message = { title: title, body: body, };

            return this.$dialog.alert(message, {
                view: 'my-error-dialog',
                animation: 'zoom',
            });
        },
    }
};