<template>
    <span @click="onClick">
        <Icon 
            :color="!color ? '' : male ? 'royalblue' : 'crimson' " 
            :icon="male ? 'mdi:face-man-profile' : 'mdi:face-woman-profile'" 
            :style="style"
            v-bind="$attrs"
        />
    </span>
</template>

<script>
import { Icon } from '@iconify/vue2';

export default ({
    components: {
        Icon,
    },
    props: {
        male: {
            type: Boolean,
            default: true,
        },
        color: {
            type: Boolean,
            default: true,
        },
        isClickable: {
            type: Boolean,
            default: false,
        },
    },
    data: function() {
        return {

        };
    },
    computed: {
        style: function() {
            return { 
                verticalAlign:'middle',
                cursor: this.isClickable ? 'pointer' : '',
            };
        },
    },
    methods:{
        onClick() {
            this.$emit('click');
        },
    },
});
</script>
