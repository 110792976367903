<template>
    <v-lazy
        v-model="lazy"
        :options="{ rootMargin: '250px', threshold: 0.5 }"
        min-height="160"
    >
        <v-card 
            class="photo-face-card"
        >
            <v-container class="ma-0 pa-0">
                <v-row dense class="ma-0 pa-0"> 
                    <v-col cols="12" class="ma-0 pa-0 text-center">
                        {{ face.no }}
                    </v-col>
                    <v-tooltip v-if="editIcon" bottom>
                        <template #activator="{ on }">
                            <v-btn icon x-small :style="{position: 'absolute', right: '0'}" v-on="on" @click="$emit('edit-icon-clicked')">
                                <v-icon>mdi-account-edit</v-icon>
                            </v-btn>
                        </template>
                        <span>顔マスタを修正する</span>
                    </v-tooltip>
                </v-row>
                <v-row class="ma-0 pa-0">
                    <v-col v-if="!rerendering" cols="12" class="ma-0 pa-0 flex-center">
                        <v-tooltip bottom>
                            <template #activator="{ on }">
                                <v-img
                                    :src="imageSrc"
                                    contain
                                    :width="80"
                                    :height="80"
                                    :class="isHighlighting ? 'highlighting' : ''"
                                    @click="clickFaceImage"
                                    v-on="on"
                                />
                            </template>
                            <v-container fluid min-width="200">
                                <v-row v-if="!!face.face_image_path && face.face_image_path != imageSrc">
                                    <v-col cols="4" class="ma-0 pa-1">
                                        <v-img                                        
                                            :src="face.face_image_path"
                                            contain
                                            :max-width="faceWidth"
                                            :max-height="faceHeight"
                                        />
                                    </v-col>
                                    <v-col cols="8" class="ma-0 pa-1">
                                        <span>顔マスタ番号：{{ face.no }}<br /></span>
                                        <span v-if="!!face.student_no">出席番号：{{ face.student_no }}<br /></span>
                                        <span v-if="!!face.sex">性別：{{ face.sex }}<br /></span>
                                        <span v-if="!!face.note">個人メモ：{{ face.note }}<br /></span>
                                    </v-col>
                                </v-row>
                                <v-row v-else>
                                    <v-col cols="12" class="ma-0 pa-0">
                                        <span>顔マスタ番号：{{ face.no }}<br /></span>
                                        <span v-if="!!face.student_no">出席番号：{{ face.student_no }}<br /></span>
                                        <span v-if="!!face.sex">性別：{{ face.sex }}<br /></span>
                                        <span v-if="!!face.note">個人メモ：{{ face.note }}<br /></span>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-tooltip>
                    </v-col>
                </v-row>
                <v-row v-if="!!face.student_no" dense class="ma-0 pa-0"> 
                    <v-col cols="12" class="ma-0 pa-0 text-center">
                        {{ face.student_no }}
                    </v-col>
                </v-row>
                <v-row v-if="!!laughingRate" dense class="ma-0 pa-0"> 
                    <v-col cols="12" class="ma-0 pa-0 text-center">
                        <v-icon small class="mr-1">
                            mdi-emoticon-excited-outline
                        </v-icon>{{ (laughingRate * 100).toFixed() + '%' }}
                    </v-col>
                </v-row>
                <v-row v-if="!isNaN(frequencyNum)" dense class="ma-0 pa-0"> 
                    <v-col cols="12" class="ma-0 pa-0 text-center">
                        <span :style="{ color: frequencyNum === 0 ? '#d41111' : undefined, }">
                            {{ frequencyNum }}枚
                        </span>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-lazy>
</template>

<script>
import monet from 'monet';

export default {
    props: {
        id: {
            default: 0,
        }, // face_id or photo_face_id.
        face: {
            type: Object,
            default: undefined,
        },
        imageSrc: {
            type: String,
            default: '',
        },
        laughingRate: {
            type: [String, Number],
            default: undefined,
        },
        isHighlighting: {
            type: Boolean,
            default: false,
        },
        frequencyNum: {
            type: Number,
            default: undefined,
        },
        clickCallback: {
            type: Function,
            default: x => {},
        },
        faceWidth: {
            type: Number,
            default: 80,
        },
        faceHeight: {
            type: Number,
            default: 80,
        },
        editIcon: {
            type: Boolean,
            default: false,
        }
    },
    data () {
        return {
            lazy: false,
            rerendering: false,
        };
    },
    methods: {
        clickFaceImage () {
            this.clickCallback(this.id);
            this.rerender(); // 強制的に再描画
        },
        rerender () {
            this.rerendering = true;
            this.$nextTick(() => this.rerendering = false);
        },
    },
};
</script>

<style>
div.v-image.highlighting {
    border: solid 2px #FFFFFF;
}
</style>
