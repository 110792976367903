<template>
    <v-container ref="selectionWrapper" fluid class="pa-0 ma-0">
        <canvas id="canvas" ref="select" :width="adjustedWidth"
                :height="adjustedHeight" @click="selectFace"
        />
    </v-container>
</template>

<script>
import leaveConform from '../../mixins/leave-confirm-mixin';

export default {
    mixins: [leaveConform],
    props: {
        faces: Array,
        selectedFaceIds: Array,
        src: {
            default: undefined,
        },
        canvasHeight: {
            default: 0,
        }
    },
    data () {
        return {
            containerWidth: 0,
            aspectRatio: 2 / 3,
            ctx: null,
            imageObj: undefined,
            selectionMode: false,
            scale_fit: undefined,
            x_fit: undefined,
            y_fit: undefined,
            startPosition: {
                x: null,
                y: null
            },
            selectedPosition: {
                top_left: {
                    x: null,
                    y: null
                },
                bottom_right: {
                    x: null,
                    y: null
                }
            }
        };
    },
    computed: {
        computedSrc: function () {
            return this.containerWidth == 0 ? '' : this.src;
        },
        adjustedWidth: function() {
            return this.containerWidth;
        },
        adjustedHeight: function () {
            return this.canvasHeight ? this.canvasHeight : this.containerWidth * this.aspectRatio;
        },
        // 検出顔と画像の変更を同時にwatchするためのプロパティ
        srcAndFaceToWatch: function() {
            return [this.computedSrc, this.faces];
        },
        adjustedSelectedPosition: function() {
            if (this.selectedPosition.top_left.x == null ||
            this.selectedPosition.top_left.y == null || 
            this.selectedPosition.bottom_right.x == null ||
            this.selectedPosition.bottom_right.y == null) {
                return null;
            }
            return {
                top_left: {
                    x: (this.selectedPosition.top_left.x - this.x_fit) / this.scale_fit,
                    y: (this.selectedPosition.top_left.y - this.y_fit) / this.scale_fit,
                },
                bottom_right: {
                    x: (this.selectedPosition.bottom_right.x - this.x_fit) / this.scale_fit,
                    y: (this.selectedPosition.bottom_right.y - this.y_fit) / this.scale_fit,
                }
            };
        }
    },
    watch: {
        selectedFaceIds: function() {
            this.draw();
        },
        srcAndFaceToWatch: function(val, oldVal) {
            // 画像が変更された場合は再読み込みを行う
            if (!(val[0] === oldVal[0])) {
                this.initSelection();
                this.ctx.clearRect(0, 0, this.$refs.select.width, this.$refs.select.height);

                if (!val) {
                    this.imageObj = undefined;
                    return;
                }
                // 画像ロード中の場合は中断する
                if (this.imageObj && !this.imageObj.complete) {
                    this.imageObj.src = '';
                }

                this.imageObj = new Image();
                this.imageObj.src = this.computedSrc;  // 画像のURLを指定

                this.imageObj.onload = () => {
                    this.aspectRatio = this.imageObj.height / this.imageObj.width;
                    this.draw();
                };
            } else {
                // 画像は変わらず検出顔のみ変化した場合
                this.draw();
            }
        },
    },
    mounted() {
        this.ctx = this.$refs.select.getContext('2d');
        this.observeWrapper();
    },
    beforeDestroy() {
        this.initDisplay();
    },
    methods: {
        startSelect(e) {
            if (this.isOnImage(e)) {
                this.selectionMode = true;
                this.startPosition.x = e.clientX - this.$refs.select.getBoundingClientRect().left;
                this.startPosition.y = e.clientY - this.$refs.select.getBoundingClientRect().top;
            }
        },
        drawRect(e) {
            if (this.selectionMode && this.isOnImage(e)) {
                this.draw();

                var x1 = this.startPosition.x;
                var y1 = this.startPosition.y;
                var x2 = e.clientX - this.$refs.select.getBoundingClientRect().left;
                var y2 = e.clientY - this.$refs.select.getBoundingClientRect().top;
                
                this.selectedPosition.top_left.x = x1 < x2 ? x1 : x2;
                this.selectedPosition.top_left.y = y1 < y2 ? y1 : y2;
                this.selectedPosition.bottom_right.x = x1 > x2 ? x1 : x2;
                this.selectedPosition.bottom_right.y = y1 > y2 ? y1 : y2;

                this.ctx.beginPath();
                this.ctx.rect(
                    x1,
                    y1,
                    x2 - x1,
                    y2 - y1,
                );
                this.ctx.closePath();
                this.ctx.strokeStyle = '#f00';
                this.ctx.stroke();
            }
        },
        stopSelect(e) {
            this.ctx.fillStyle = '#fff';

            if (this.isOnImage(e)) {
                var x1 = this.startPosition.x;
                var y1 = this.startPosition.y;
                var x2 = e.clientX - this.$refs.select.getBoundingClientRect().left;
                var y2 = e.clientY - this.$refs.select.getBoundingClientRect().top;
                
                this.selectedPosition.top_left.x = x1 < x2 ? x1 : x2;
                this.selectedPosition.top_left.y = y1 < y2 ? y1 : y2;
                this.selectedPosition.bottom_right.x = x1 > x2 ? x1 : x2;
                this.selectedPosition.bottom_right.y = y1 > y2 ? y1 : y2;

                // クリック時には矩形選択をリセット
                if ((x1 == x2) && (y1 == y2)) {
                    this.initSelection();
                    this.draw();
                }
            }
            this.selectionMode = false;
            this.startPosition.x = null;
            this.startPosition.y = null;
        },
        selectFace(e) {
            var clicked_x = e.clientX - this.$refs.select.getBoundingClientRect().left;
            var clicked_y = e.clientY - this.$refs.select.getBoundingClientRect().top;

            const clickedFaces = [];
            const distances = [];
            if (this.faces) {
                this.faces.forEach(face => {
                    if (this.isOnFace(clicked_x, clicked_y, face)) {
                        distances.push(this.calcDistanceFromFace(clicked_x, clicked_y, face));
                        clickedFaces.push(face);
                    }
                });
            }

            if (clickedFaces.length == 0) {
                return;
            } else {
                this.$emit('faceRectClicked', clickedFaces[distances.indexOf(Math.min.apply(null, distances))].id);
            } 
        },
        calcDistanceFromFace(x, y, face) {
            const x_center = ((face['coor_left'] * this.scale_fit + this.x_fit) + (face['coor_right'] * this.scale_fit + this.x_fit)) / 2;
            const y_center = ((face['coor_top'] * this.scale_fit + this.y_fit) + (face['coor_bottom'] * this.scale_fit + this.y_fit)) / 2;

            return Math.sqrt(Math.abs(x_center - x) * Math.abs(y_center - y));
        },
        isOnFace(x, y, face) {
            return (face['coor_left'] * this.scale_fit + this.x_fit) <= x 
            && x <= (face['coor_right'] * this.scale_fit + this.x_fit)
            && (face['coor_top'] * this.scale_fit + this.y_fit) <= y
            && y <= (face['coor_bottom'] * this.scale_fit + this.y_fit);
        },
        isOnImage(e) {
            var clicked_x = e.clientX - this.$refs.select.getBoundingClientRect().left;
            var clicked_y = e.clientY - this.$refs.select.getBoundingClientRect().top;
            
            return this.x_fit <= clicked_x 
            && clicked_x <= (this.x_fit + (this.imageObj.width * this.scale_fit))
            && this.y_fit <= clicked_y
            && clicked_y <= (this.y_fit + (this.imageObj.height * this.scale_fit));
        },
        drawHighlightingFaceRect() {
            if (this.faces) {
                this.faces.forEach(f => {
                    var width = (f['coor_right'] * this.scale_fit + this.x_fit) - (f['coor_left'] * this.scale_fit + this.x_fit);
                    var height = (f['coor_bottom'] * this.scale_fit + this.y_fit) - (f['coor_top'] * this.scale_fit + this.y_fit);
                    this.ctx.beginPath();
                    this.ctx.rect(
                        f['coor_left'] * this.scale_fit + this.x_fit,
                        f['coor_top'] * this.scale_fit + this.y_fit,
                        width,
                        height,
                    );
                    this.ctx.closePath();
                    this.ctx.strokeStyle = this.selectedFaceIds.includes(f['id']) ? '#0f0' : '#00f';
                    this.ctx.stroke();

                    const font_size = 15;
                    this.ctx.fillStyle = this.ctx.strokeStyle;
                    this.ctx.fillRect(
                        f['coor_left'] * this.scale_fit + this.x_fit,
                        f['coor_bottom'] * this.scale_fit + this.y_fit,
                        width,
                        Math.ceil(font_size * 0.8),
                    );

                    this.ctx.font = font_size + 'px \'ＭＳ ゴシック\'';
                    this.ctx.fillStyle = '#000';
                    this.ctx.fillText(f['no'], (f['coor_left'] * this.scale_fit + this.x_fit),
                        (f['coor_bottom'] * this.scale_fit + this.y_fit + Math.ceil(font_size * 0.8)));
                });
            }
        },
        draw() {
            if (this.imageObj) {
                this.scaleToFit(this.imageObj)
                    .then(() => {
                        this.drawHighlightingFaceRect();
                    });
            }
        },
        async scaleToFit(img) {
            // get the scale
            this.scale_fit = Math.min(this.adjustedWidth / img.width, this.adjustedHeight / img.height);
            // get the top left position of the image
            this.x_fit = (this.adjustedWidth / 2) - (img.width / 2) * this.scale_fit;
            this.y_fit = (this.adjustedHeight / 2) - (img.height / 2) * this.scale_fit;
            this.ctx.drawImage(img, this.x_fit, this.y_fit, img.width * this.scale_fit, img.height * this.scale_fit);

            return true;
        },
        observeWrapper() {
            const resizeObserver = new ResizeObserver(entries => {
                for (const entry of entries) {
                    this.containerWidth = entry.contentRect.right - entry.contentRect.left;
                    this.draw();
                }
            });

            resizeObserver.observe(this.$refs.selectionWrapper);
        },
        initDisplay() {
            this.containerWidth = 0;
            this.initSelection();
        },
        initSelection() {
            this.selectedPosition.top_left.x = null;
            this.selectedPosition.top_left.y = null;
            this.selectedPosition.bottom_right.x = null;
            this.selectedPosition.bottom_right.y = null;
        },
    },
};

</script>

<style lang="scss" scoped>
    #canvas-wrapper 
    {
        width: 100% !important;
        height: 100% !important;
    }
</style>