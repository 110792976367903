import http from './http.js';


const awaitJobDone = async (jobId, showSpinner = false, intervalMs = 3000) => {
    if (showSpinner) {
        http.pushCommunicate();
    }
    let done = false;
    let result;
    let result_error;

    while (!done) {
        await http
            .backGroundRequest('get', '/jobs/' + jobId)
            .then(async (res) => {
                if (res.data.status == 'success') {
                    result = res.data.result;
                    done = true;
                } else if (res.data.status == 'processing') {
                    await new Promise((resolve) =>
                        setTimeout(resolve, intervalMs)
                    );
                } else if (res.data.status == 'failed') {
                    throw res.data.result.exception;
                }
            })
            .catch((error) => {
                console.log(error);
                result_error = error;
                done = true;
            });
    }

    if (showSpinner) {
        http.shiftCommunicate();
    }

    if (result_error) {
        throw result_error;
    } else {
        return result;
    }
};

export { awaitJobDone };