<template>
    <dialog-button v-bind="$attrs" :color="$root.getTheme().background" :style="{ border: '0.1em solid #868686'}" @click="$emit('click')">
        <slot />
    </dialog-button>
</template>

<script>
export default {
    components:{
        'dialog-button': require('./DialogButton.vue').default,
    },
};
</script>