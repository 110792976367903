<template>
    <v-lazy
        v-model="lazy"
        :options="{ rootMargin: '250px', threshold: 0.5 }"
        min-height="160"
    >
        <v-card class="photo-thumbnail-card" 
                :style="{'background-color': backColor}"
                :min-width="size.width" 
                :max-width="size.width"
                :class="photo.is_highlighted ? 'highlighted' : ''"
        >
            <v-container class="ma-0 pa-0">
                <v-row dense justify="space-between"> 
                    <v-col cols="10" class="ml-0 pl-0 d-flex">
                        <v-checkbox
                            v-model="_emitter_isSelected"
                            dense 
                            hide-details 
                            class="ma-0 ml-1 pa-0" 
                        />
                        <v-btn v-if="photo.is_selected_for_page_spread" small outlined rounded color="red"
                               @click="() => {_emitter_is_selected_for_page_spread=false}"
                        >
                            選定中
                        </v-btn>
                        <v-btn v-else small outlined rounded
                               @click="() => {_emitter_is_selected_for_page_spread=true}"
                        >
                            選定する
                        </v-btn>
                        <span v-if="!multipleTags" class="ml-2">
                            <v-chip 
                                v-for="pt in photo.photo_tags" 
                                :key="pt.id"
                                :color="pt.tag.back_color_code"
                                :text-color="pt.tag.fore_color_code"
                                small
                                class="mr-1"
                                :style=" {verticalAlign : '-webkit-baseline-middle'} "
                            >
                                {{ pt.tag.name }}
                            </v-chip>
                        </span>
                    </v-col>
                    <v-col cols="2" class="mr-0 pr-0 text-right">
                        <v-icon v-if="!!photo.label_icon" :color="'#' + photo.label.color_code" class="mr-1"
                                @click="$emit('label-changed')"
                        >
                            {{ photo.label_icon }}
                        </v-icon>
                        <v-icon v-else :color="'#' + photo.label.color_code" class="mr-1">
                            mdi-loading
                        </v-icon>
                    </v-col>
                </v-row>
                <v-row v-if="multipleTags" dense justify="space-between">
                    <v-col class="ml-2 pl-0">
                        <span>
                            <v-chip 
                                v-for="pt in photo.photo_tags" 
                                :key="pt.id"
                                :color="pt.tag.back_color_code"
                                :text-color="pt.tag.fore_color_code"
                                small
                                class="mr-1"
                                :style=" {verticalAlign : '-webkit-baseline-middle'} "
                            >
                                {{ pt.tag.name.slice(0, 1) + '.' }}
                            </v-chip>
                        </span>
                    </v-col>
                </v-row>
                <v-row class="ma-0 pa-0">
                    <v-col cols="12" class="ma-0 pa-0">
                        <v-img 
                            :src="thumbnailFilePathWithQueryParam"
                            contain
                            :max-width="size.width"
                            :max-height="size.height"
                            :class="photo.is_highlighted ? 'highlighted' : ''"
                            @click="clickCallback(photo.id)"
                        />
                    </v-col>
                </v-row>
                <v-row v-if="!!photo.page_no" dense> 
                    <v-col cols="2">
                        {{ 'P' + photo.page_no }}
                    </v-col>
                    <v-col cols="10" class="photo-name">
                        {{ photo.name }}
                    </v-col>
                </v-row>
                <v-row v-else dense style="text-align: center;"> 
                    <v-col cols="12" class="photo-name">
                        {{ photo.name }}
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-lazy>
</template>

<script>
import monet from 'monet';
import http from '../../services/http';

export default {
    props: {
        photo: {
            default: undefined,
        },
        size: {
            default: () => {
                return { width: 240, height: 160 };
            },
        },
        withRectangle: {
            default: false,
        },
        isSelected: {
            default: false,
            type: Boolean,
        },
        clickCallback: {
            default: x => {},
            type: Function,
        },
    },
    data () {
        return {
            lazy: false,
        };
    },
    computed: {
        thumbnailFilePathWithQueryParam () {
            return this.withRectangle 
                ? monet.Maybe.fromFalsy(this.photo.visualized_file_path)
                    .map(v => v += '?' + Math.random()).orSome(this.photo.thumbnail_file_path)
                : this.photo.thumbnail_file_path;
        },
        backColor () {
            return this.isSelected ? '#228b22' : this.$root.getTheme().background;
        },
        multipleTags() {
            return this.photo.photo_tags.length > 1;
        },
        _emitter_isSelected:{
            get: function () {
                return this.isSelected;
            },
            set: function (value) {
                this.$emit('photo-selected', value);
            },
        },
        _emitter_is_selected_for_page_spread: {
            get: function () {
                return this.photo.is_selected_for_page_spread;
            },
            set: function (value) {
                this.$emit('selection-status-changed-for-page-spread', value);
            },
        },
    },
    methods: {
        // not using
        updateSelectionStatus (status) {
            http
                .backGroundRequest('put', 'photos/update_selection_status', {
                    photo_ids: [this.photo.id],
                    status: status,
                })
                .then(
                    this.$emit('selection-status-updated')
                )
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, '選定状態更新エラー');
                });
        },
    },
};

</script>

<style>
.photo-thumbnail-card div.photo-name {
    text-align: center;
}

/* .photo-thumbnail-card div.v-image.highlighted {
    border: solid 2px #FFFFFF;
} */

.photo-thumbnail-card.v-card.highlighted {
    border: solid 2px #FFFFFF;
}
</style>
