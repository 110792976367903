<template>
    <v-dialog v-model="display" width="85vw" persisitent scrollable @click:outside.stop="closeDialog">
        <v-card class="pa-3">
            <v-container fluid>
                <!-- 写真選択条件 -->
                <v-form ref="selection" lazy-validation>
                    <v-row dense justify="start">
                        <v-col md="4">
                            <v-select v-model="selectedShooting" label="撮影行事" hide-details clearable dense outlined
                                :items="storageData.shootings" item-text="name" item-value="id" return-object />
                        </v-col>
                        <v-col md="4">
                            <v-select v-model="selectedPhoto" label="写真ファイル名" hide-details clearable dense outlined
                                :items="storageData.photos" item-text="name" item-value="id" return-object />
                        </v-col>
                        <v-col md="2">
                            <v-switch v-model="isOnlyFacebaseCandidate" class="mt-0" label="顔マスタに使用した写真のみ" />
                        </v-col>
                        <v-col md="2">
                            <v-btn-toggle v-model="selectedMode" mandatory>
                                <v-btn> 削除モード </v-btn>
                            </v-btn-toggle>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
            <v-card-text style="height: 70vh">
                <v-container>
                    <v-row justify="space-between" class="pa-3">
                        <!-- 写真 -->
                        <v-col id="container" :cols="8" class="pa-0">
                            <drawing-rect-canvas v-if="selectedPhoto" ref="drawingRectCanvasVue"
                                :src="selectedPhoto.file_path" :faces="facesToBeDrawn" :face-rect-color="colors.unselected"
                                :canvas-height="canvasHeight" @stop-selecting="drawSelectedFace"
                                @face-rect-clicked="selectExistingFace" />
                        </v-col>
                        <v-spacer />
                        <!-- 補足情報 -->
                        <v-col v-if="selectedMode == 0" cols="3">
                            <v-container class="ma-0 pa-0">
                                <v-form ref="form" lazy-validation>
                                    <v-row>
                                        <v-text-field v-model="storageData.forms.student_no" label="出席番号" clearable dense
                                            outlined counter="10" :rules="rules.studentNoRules" />
                                    </v-row>
                                    <v-row>
                                        <v-select v-model="storageData.forms.sex" clearable dense outlined
                                            :items="['男性', '女性']" label="性別" />
                                    </v-row>
                                    <v-row>
                                        <v-textarea v-model="storageData.forms.note" label="個人メモ" clearable dense outlined
                                            rows="3" />
                                    </v-row>
                                </v-form>
                            </v-container>
                            <v-container v-if="selectedPhoto">
                                <v-row class="mb-2">
                                    プレビュー
                                </v-row>
                                <v-row justify="center">
                                    <canvas ref="selectedFaceCanvas" :width="selectedFaceCanvasSize"
                                        :height="selectedFaceCanvasSize" />
                                </v-row>
                            </v-container>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-container>
                    <v-row dense justify="space-between">
                        <cancel-dialog-button width="100" @click="closeDialog">
                            キャンセル
                        </cancel-dialog-button>
                        <v-spacer />
                        <div>
                            <v-badge bordered :value="selectedFaces.length" :content="selectedFaces.length" color="blue"
                                overlap>
                                <proceed-dialog-button :disabled="selectedFaces.length == 0" width="100"
                                    @click="deleteFaces">
                                    削除
                                </proceed-dialog-button>
                            </v-badge>
                        </div>
                    </v-row>
                </v-container>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import event from '../../utils/event';
import http from '../../services/http';
import urlUtil from '../../utils/url';
import leaveConform from '../../mixins/leave-confirm-mixin';
import monet from 'monet';

export default {
    components: {
        drawingRectCanvas: require('../Faces/DrawingRectCanvas.vue').default,
    },
    mixins: [leaveConform],
    props: {
        albumId: {
            type: [String, Number],
            default: 0,
        },
        shootingId: {
            type: [String, Number],
            default: 0,
        },
        photoId: {
            type: [String, Number],
            default: 0,
        },
        closeCallback: {
            type: Function,
            default: undefined,
        },
    },
    data() {
        return {
            viewID: '',
            storageData: {
                isInitialized: false,
                shootings: [],
                photos: [],

                forms: {
                    student_no: undefined,
                    sex: undefined,
                    note: undefined,
                },
                modeUpdate: false,
            },
            isOnlyFacebaseCandidate: true,
            selectedShooting: undefined,
            selectedPhoto: undefined,
            httpState: http.state,
            albums: [],
            display: false,
            rules: {
                studentNoRules: [
                    (v) =>
                        (v ? v.length <= 10 : true) || '10文字以内で入力してください。',
                ],
            },
            selectedFaceCanvasSize: 300,
            faceLandmarkPoints: [
                // [0.3419, 0.4616],
                // [0.6565, 0.4598],
                // [0.5002, 0.6405],
                // [0.3710, 0.8247],
                // [0.6315, 0.8233]
            ], // 顔特徴点の座標目安用 使用しない
            colors: {
                unselected: '#00f',
                selected: '#0f0',
                selecting: '#f00',
            },
            selectedMode: 1,
        };
    },
    computed: {
        facesStackLength: function () {
            return monet.Maybe.fromNull(this.selectedPhoto).fold(0)((p) => {
                return monet.Maybe.fromNull(p.facesStack).fold(0)((fs) => fs.length);
            });
        },
        selectedFaces: function () {
            return this.selectedPhoto
                ? this.selectedPhoto.photo_faces.filter((pf) => {
                    return pf.rect_color == this.colors.selected;
                })
                : [];
        },
        facesToBeDrawn: function () {
            if (!this.selectedPhoto) {
                return [];
            }
            const face_ids = this.selectedPhoto.faces.map((f) => {
                return f.id;
            });
            const faces = this.selectedPhoto.photo_faces.filter((pf) => {
                return face_ids.includes(pf.face.id);
            });
            return this.selectedPhoto.facesStack
                ? faces.concat(this.selectedPhoto.facesStack)
                : faces;
        },
        adjustedFaceLandmarkPoints: function () {
            // canvasのサイズで座標を補正
            return this.faceLandmarkPoints.map((l) =>
                l.map((lp) => lp * this.selectedFaceCanvasSize)
            );
        },
        canvasHeight: function () {
            return window.innerHeight * 0.65;
        },
    },
    watch: {
        selectedShooting: function (val) {
            this.selectedPhoto = undefined;
            if (val) {
                this.fetchPhotos(val);
            }
        },
        selectedPhoto: function (val, oldVal) {
            if (!val || (oldVal && val.id != oldVal.id)) {
                this.initSelectedFaceCanvas();
                oldVal.facesStack = [];
            }
        },
        isOnlyFacebaseCandidate: function (val) {
            // 顔マスタに使用する写真のみに変更した場合(絞り込みをかける場合)のみ写真表示をリセット
            if (val) {
                this.selectedPhoto = undefined;
            }
            if (this.selectedShooting) {
                this.fetchPhotos(this.selectedShooting);
            }
        },
        selectedMode: function (val) {
            if (val == 0) {
                // 追加モードへ切り替え時
                this.selectedPhoto.photo_faces = this.selectedPhoto.photo_faces.map(
                    (pf) => {
                        pf.rect_color = this.colors.unselected;
                        return pf;
                    }
                );
            } else {
                // 削除モードへ切り替え時
                this.$set(this.selectedPhoto, 'facesStack', []);
            }

            this.$refs.drawingRectCanvasVue.draw();
        },
    },
    mounted() {
        event.on('saveStorage', () => {
            if (this.storageData.isInitialized) {
                this.saveStorageData();
            }
        });
    },
    beforeDestroy() {
        event.off('saveStorage');
    },
    methods: {
        async closeDialog() {
            if ((await this.confirmLeave()) === false) return;

            this.display = false;
            this.$refs.form.reset();
            this.$refs.selection.reset();
            this.$emit('closed');
        },
        initialize() {
            return this.fetchShootings()
                .then(() => {
                    this.fetchShootingForFaceMaster().then(() => {
                        if (this.shootingId) {
                            this.selectShooting(this.shootingId);
                            this.fetchPhotos(this.selectedShooting).then(() => {
                                this.selectPhoto(this.photoId);
                            });
                        }
                    });
                })
                .then(() => {
                    this.storageData.isInitialized = true;
                    this.confirmLeaveReset(['storageData', 'forms']);
                })
                .catch((error) => {
                    if (error === 'unauthorized') return;
                    this.showError('初期化処理に失敗しました。m' + error, '初期化エラー');
                });
        },
        selectShooting(shootingId) {
            this.selectedShooting = this.storageData.shootings.filter((shooting) => {
                return shooting.id == shootingId;
            })[0];
        },
        selectPhoto(photoId) {
            this.selectedPhoto = this.storageData.photos.filter((photo) => {
                return photo.id == photoId;
            })[0];
        },
        drawSelectedFace() {
            if (this.selectedMode == 1) {
                return;
            }
            if (!this.$refs.drawingRectCanvasVue.adjustedSelectedPosition) {
                this.initSelectedFaceCanvas();
                return;
            }

            const top_left = Object.assign(
                {},
                this.$refs.drawingRectCanvasVue.adjustedSelectedPosition.top_left
            );
            const bottom_right = Object.assign(
                {},
                this.$refs.drawingRectCanvasVue.adjustedSelectedPosition.bottom_right
            );

            const ctx = this.$refs.selectedFaceCanvas.getContext('2d');
            const img = this.$refs.drawingRectCanvasVue.imageObj;
            ctx.drawImage(
                img,
                top_left.x,
                top_left.y,
                bottom_right.x - top_left.x,
                bottom_right.y - top_left.y,
                0,
                0,
                this.selectedFaceCanvasSize,
                this.selectedFaceCanvasSize
            );

            ctx.strokeStyle = this.colors.selecting;
            ctx.fillStyle = this.colors.selecting; // 線は赤色
            ctx.lineWidth = 3;
            ctx.beginPath();
            ctx.strokeRect(
                0,
                0,
                this.selectedFaceCanvasSize,
                this.selectedFaceCanvasSize
            );
            ctx.closePath();

            // 顔特徴点の描画 * 非実行
            this.adjustedFaceLandmarkPoints.forEach((landmark) => {
                /* コンテキスト設定 */
                ctx.strokeStyle = '#333'; // 塗りつぶしは暗めの色
                ctx.fillStyle = this.colors.selecting; // 線は赤色
                ctx.lineWidth = 5; // 線の幅は5px

                // 顔特徴点の描画 * 非実行
                ctx.beginPath(); // パスの初期化
                ctx.arc(landmark[0], landmark[1], 5, 0, 2 * Math.PI); // (100, 50)の位置に半径30pxの円
                ctx.closePath(); // パスを閉じる
                ctx.fill(); // 軌跡の範囲を塗りつぶす
            });
        },
        initSelectedFaceCanvas() {
            if (!this.$refs.selectedFaceCanvas) {
                return;
            }
            const ctx = this.$refs.selectedFaceCanvas.getContext('2d');
            ctx.clearRect(
                0,
                0,
                this.selectedFaceCanvasSize,
                this.selectedFaceCanvasSize
            );
        },
        selectExistingFace(id) {
            if (this.selectedMode == 0) {
                return;
            }
            this.selectedPhoto.photo_faces = this.selectedPhoto.photo_faces.map(
                (pf) => {
                    if (pf.id == id) {
                        pf.rect_color =
                            pf.rect_color == this.colors.selected
                                ? this.colors.unselected
                                : this.colors.selected;
                    }
                    return pf;
                }
            );
            this.$refs.drawingRectCanvasVue.initSelection();
            this.$refs.drawingRectCanvasVue.draw();
        },
        fetchShootings() {
            var url = urlUtil.addQueryParamIfDefined(
                'shootings',
                'album_id',
                this.albumId
            );

            return http
                .get(url)
                .then((res) => {
                    this.storageData.shootings = res.data;
                })
                .catch((error) => {
                    if (error === 'unauthorized') return;
                    this.showError(error, '撮影行事一覧の取得に失敗しました');
                });
        },
        fetchShootingForFaceMaster() {
            return http
                .get('shootings/for_face_master/' + this.albumId)
                .then((res) => {
                    res.data.name = '顔マスタ一括作成基写真';
                    this.storageData.shootings.push(res.data);
                })
                .catch((error) => {
                    if (error === 'unauthorized') return;
                    this.showError(error, '撮影行事一覧の取得に失敗しました');
                });
        },
        fetchPhotos(shooting) {
            if (!shooting) {
                this.storageData.photos = [];
            }
            var url = urlUtil.addQueryParamIfDefined(
                'photos',
                'shooting_id',
                shooting.id
            );
            url = urlUtil.addQueryParamIfDefined(url, 'album_id', this.albumId);
            if (this.isOnlyFacebaseCandidate) {
                url = urlUtil.addQueryParamIfDefined(
                    url,
                    'is_only_facebase_candidate',
                    true
                );
            }

            return http
                .get(url)
                .then((res) => {
                    this.storageData.photos = res.data.map((photo) => {
                        photo.photo_faces = photo.photo_faces.map((pf) => {
                            pf.rect_color = this.colors.unselected;
                            return pf;
                        });
                        return photo;
                    });
                })
                .catch((error) => {
                    if (error === 'unauthorized') return;
                    this.showError(error, '写真取得エラー');
                });
        },
        deleteFaces() {
            if (this.selectedFaces.length == 0) {
                alert('削除対象が選択されていません');
                return;
            }

            var url = 'faces';
            url = urlUtil.addQueryParamIfDefined(url, 'album_id', this.albumId);
            const faceIds = this.selectedFaces.map((pf) => pf.face.id);
            faceIds.forEach(
                (f) => (url = urlUtil.addQueryParamIfDefined(url, 'face_ids[]', f))
            );

            http
                .delete(url)
                .then(() => {
                    this.fetchPhotos(this.selectedShooting).then(() => {
                        this.resetSelectedPhoto();
                    });

                    this.$emit('doneSuccessfully');
                    event.emit('updatedFaces');
                })
                .catch((error) => {
                    if (error === 'unauthorized') return;
                    this.showError(error, '顔マスタ削除に失敗しました');
                });
        },
        resetSelectedPhoto() {
            const id = this.selectedPhoto.id;
            this.selectedPhoto = undefined;
            this.selectedPhoto = this.storageData.photos.filter((photo) => {
                return photo.id == id;
            })[0];
        },
    },
};
</script>

<style lang="scss" scoped>
#canvas {
    background: dimgray;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
}
</style>