<template>
    <v-card
        color="#FFFFFF"
        light
        tile
        @click="selectCallback(shooting.id)"
    >
        <v-scroll-y-transition>
            <v-container class="ma-0 pa-0">
                <v-row class="ma-0 pa-0">
                    <v-col cols="12" class="text-center">
                        <span>{{ shooting.name }}</span>                        
                    </v-col>
                    <v-col cols="12">
                        <ul style="list-style: none;">
                            <li>撮影日：{{ formatDate(shooting.date) }}</li>
                            <li>撮影者：{{ shooting.photographer_name }}</li>
                        </ul>
                    </v-col>
                </v-row>
                <v-row
                    class="ma-0 pa-0" 
                    dense :style="{ background: $root.getTheme().quinary }"
                >
                    <v-col cols="2" class="text-center">
                        <v-tooltip bottom>
                            <template #activator="{ on }">
                                <v-btn 
                                    slot="activator" 
                                    icon
                                    small
                                    v-on="on"
                                    @click.stop="confirmCallback"
                                >
                                    <v-icon>mdi-chart-box</v-icon>
                                </v-btn>
                            </template>
                            <span>人物頻度確認</span>
                        </v-tooltip>
                    </v-col>
                    <v-spacer />
                    <v-col cols="2" class="text-center">
                        <v-tooltip bottom>
                            <template #activator="{ on }">
                                <v-btn 
                                    slot="activator" 
                                    icon
                                    small
                                    v-on="on"
                                    @click.stop="deleteCallback"
                                >
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span>削除</span>
                        </v-tooltip>
                    </v-col>           
                    <v-col cols="2" class="text-center">
                        <v-tooltip bottom>
                            <template #activator="{ on }">
                                <v-btn 
                                    slot="activator" 
                                    icon
                                    small
                                    v-on="on"
                                    @click.stop="editCallback"
                                >
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </template>
                            <span>編集</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-container>
        </v-scroll-y-transition>
    </v-card>
</template>

<script>
import monet from 'monet';

export default {
    mixins: [],
    props: {
        shooting: {
            default: undefined,
            type: Object
        },
        selectCallback: {
            default: () => {},
            type: Function,
        },
        editCallback: {
            default: () => {},
            type: Function,
        },
        deleteCallback: {
            default: () => {},
            type: Function,
        },
        confirmCallback: {
            default: () => {},
            type: Function,
        }
    },
    methods: {
        toShootingSubmitStatuStyle () {
            return this.toStatusStyle(monet.Maybe.fromNull(this.shooting)
                .bind(s => s.is_submited 
                    ? monet.Some(s.photo_directory.status)
                    : monet.None())
                .toEither());
        },
        toStatusStyle (either) {
            return either
                .cata(
                    left => {
                        return {
                            background: this.$root.getTheme().quinary,
                            color: '#000000',
                        };
                    },
                    right => {
                        return {
                            background: this.$root.getTheme().accent,
                            color: '#FFFFFF',
                        };
                    }
                );
        }
    }
};
</script>