export default {
    bus: null,
    init(instance) {

        if (!this.bus) {
            this.bus = instance;
        }

        return this;
    },
    emit(name, ...args) {
        this.bus.$emit(name, ...args);
        return this;
    },
    on(event, callback) {
        this.bus.$on(event, callback);
        return this;
    },
    off(event) {
        this.bus.$off(event);
        return this;
    },
};
