<template>
    <v-card class="person-frequency-card" :style="{'background-color': $root.getTheme().background}">
        <v-container>
            <!-- 顔画像表示部 -->
            <v-row class="ma-0 pa-0">
                <v-col v-if="!rerendering" class="text-center pa-1">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-img
                                v-if="!rerendering"
                                :src="frequency.face_image_path"
                                contain
                                height="80"
                                max-width="80"
                                :class="isHighlighting ? 'highlighting' : ''"
                                @click="clickFaceImage"
                                v-on="on"
                            />
                        </template>
                        <span>顔マスタ番号：{{ frequency.no }}<br /></span>
                        <span v-if="!!frequency.student_no">出席番号：{{ frequency.student_no }}<br /></span>
                        <span v-if="!!frequency.sex">性別：{{ frequency.sex }}<br /></span>
                        <span v-if="!!frequency.note">個人メモ：{{ frequency.note }}<br /></span>
                    </v-tooltip>
                    {{ frequency.no }}
                </v-col>
            </v-row>
            <!-- アルバム全体頻度表示部 -->
            <v-row class="pa-1" justify="center">
                <v-col class="ma-0 pa-0 flex-center">
                    <v-progress-linear
                        v-model="frequency.album_frequency_rate"
                        color="secondary"
                        height="12"
                        style="pointer-events: none"
                    >
                        <strong>{{ frequency.frequency_of_whole_album + '枚' }}</strong>
                    </v-progress-linear>
                </v-col>
            </v-row>
            <!-- 撮影行事内頻度表示部 -->
            <v-row class="pa-1" justify="center">
                <v-col class="ma-0 pa-0 flex-center">
                    <v-progress-linear
                        v-model="frequency.frequency_rate"
                        color="accent"
                        height="12"
                        style="pointer-events: none"
                    >
                        <strong>{{ frequency.student_frequency + '枚' }}</strong>
                    </v-progress-linear>
                </v-col>
            </v-row>
            <!-- 笑顔率表示部 -->
            <v-row class="pa-0">
                <v-col class="text-center ma-0 pa-0">
                    <v-icon small class="mr-1">
                        mdi-emoticon-excited-outline
                    </v-icon>{{ (frequency.mean_laughing_rate * 100).toFixed(1) + '%' }}
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
export default {
    props: {
        frequency: {
            type: Object,
            default: () => {},
        },  
        isHighlighting: {
            type: Boolean,
            default: false,
        },
        clickCallback: {
            type: Function,
            default: () => {},
        },
    },
    data () {
        return {
            rerendering: false,
        };
    },
    methods: {
        clickFaceImage () {
            this.clickCallback(this.frequency.face_id);
            this.rerender(); // 強制的に再描画
        },
        rerender () {
            this.rerendering = true;
            this.$nextTick(() => this.rerendering = false);
        },
    },
};
</script>

<style>
div.v-image.highlighting {
    border: solid 2px #FFFFFF;
}
</style>
