
export default {
    methods: {

        saveStorageData () {
            this.setData(this.viewID, this.storageData);
        },

        restoreStorageData () {
            var data = this.restoreData(this.viewID);

            if (data) {
                this.storageData = data;
                return true;
            }

            return false;
        },

        setData (viewID, data) {

            var saveData = {
                viewID: viewID,
                data: data
            }

            const key = 'fms-data-' + viewID;
            localStorage.setItem(key, JSON.stringify(saveData))
        },

        restoreData(viewID) {
            try {
                const key = 'fms-data-' + viewID;
                var json = localStorage.getItem(key);

                if (!json) {
                    return null;
                }

                localStorage.removeItem(key);

                var saveData = JSON.parse(json);
                return saveData.data;

            } catch(error) {
                // システムの動作には影響しないため、例外処理はせず、nullを返す。
                return null;
            }
        },

        clearStorageData () {
            for (var key in localStorage) {
                if (key.startsWith('fms-data-')) {
                    localStorage.removeItem(key);
                }
            }
        }
    }
}