export default {
    data() {
        return {
            number_format: new Intl.NumberFormat({ maximumSignificantDigits: 3 }),
        };
    },
    methods: {
        // 日付 YYYY/MM/DD
        formatDatetime(value) {
            return value ? value.replace(/-/g, '/') : '';
        },
        cellFormatDatetime(r, c, value) {
            return this.formatDatetime(value);
        },
        // 日付 YYYY/MM/DD
        formatDate(value) {
            this.$moment.locale('ja');
            return value
                ? this.$moment(this.formatDatetime(value.slice(0, 10))).format('YYYY/MM/DD')
                : '';
        },
        cellFormatDate(r, c, value) {
            return this.formatDate(value);
        },
        // 日付 MM/DD (ddd)
        formatShortDate(value) {
            this.$moment.locale('ja');
            return value
                ? this.$moment(this.formatDatetime(value.slice(0, 10))).format('MM/DD (ddd)')
                : '';
        },
        cellFormatShortDate(r, c, value) {
            return this.formatShortDate(value);
        },
        // 日付 MM/DD
        formatShortDateWithoutWeekday(value) {
            this.$moment.locale('ja');
            return value
                ? this.$moment(this.formatDatetime(value.slice(0, 10))).format('MM/DD')
                : '';
        },
        cellFormatShortDateWithoutWeekday(r, c, value) {
            return this.formatShortDateWithoutWeekday(value);
        },
        // 年度 YYYY 年度
        formatShoolYear(value) {
            this.$moment.locale('ja');
            return value
                ? value + '年度'
                : '';
        },
        cellFormatShoolYear(r, c, value) {
            return this.formatShoolYear(value);
        },
        // 数値
        formatNumber(value) {
            return value != null && value !== '' ? this.number_format.format(value | 0) : '';
        },
        cellFormatNumber(r, c, value) {
            return this.formatNumber(value);
        },
        // 数値（数値以外は変換しない）
        formatNumberHoweverIgnoreNaN(value) {
            return isNaN(value) ? value : this.formatNumber(value);
        },
        cellFormatNumberHoweverIgnoreNaN(r, c, value) {
            return this.formatNumberHoweverIgnoreNaN(value);
        },
        // 百分率
        formatPercentation(value) {
            return value != null && value !== '' ? value.toFixed(2) + '%' : '';
        },
        cellFormatPercentation(r, c, value) {
            return this.formatPercentation(value);
        },
        // 日本円
        formatJpy(value) {
            return value != null && value !== '' ? this.formatNumber(value) + ' 円' : '';
        },
        cellFormatJpy(r, c, value) {
            return this.formatJpy(value);
        },
        // アルバムの制作状態
        formatCompletedStatus(value) {
            return value == null ? '' : value ? '完了' : '未完了';
        },
        cellFormatCompletedStatus(r, c, value) {
            return this.formatCompletedStatus(value);
        },
        // 撮影行事の選定完了状態
        formatShootingSubmitStatus(flag) {
            return flag == 1 ? '選定完了' : '選定未完了';
        },
        // 撮影行事の選定完了状態
        formatPageSpreadSubmitStatus(flag) {
            return flag == 1 ? '出稿済' : '未出稿';
        },
        // 男女比
        formatSexRatio(ratio, defaultText) {
            return ratio != null
                ? ratio.toFixed(2) + ' : ' + (1 - ratio).toFixed(2)
                : defaultText;
        },
        // アルバムの男女比
        formatSexRatioInAlbum(ratio) {
            return this.formatSexRatio(ratio, '未定義');
        },
        // ページ構成の男女比
        formatSexRatioInPageSpread(ratio) {
            return this.formatSexRatio(ratio, '未検出');
        },
        // 撮影行事の男女比
        formatSexRatioInShooting(ratio) {
            return this.formatSexRatio(ratio, '未検出');
        },
    }
};
